import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, NavLink } from "react-router-dom";
import welcomeCover from "../images/welcomeCover.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};

function WelcomeSettings() {

  return (
    <>
      <div className="settings-form">
        <div>
          <Slider {...sliderSettings}>
            <div>
              <div className="modal-content">
                <div className="modal-text">
                  <div className="modal-cover">
                    <img src={welcomeCover} />
                  </div>

                  <h3 className="modal-title">Pásale a lo barrido</h3>
                  <p>
                    Bienvenido a tu tienda online, actualiza y responde tus mensajes y comienza a vender.
                </p>
                  <br />
                </div>
              </div>
            </div>
            <div>
              <div className="modal-content">
                <div className="modal-text">
                  <div className="modal-cover">
                    <img src={welcomeCover} />
                  </div>

                  <h3 className="modal-title">Bienvenidx</h3>
                  <p>
                    Aliquam quis eros eget velit ornare posuere. Aenean mauris neque,
                    scelerisque vitae dictum dictum, scelerisque ac odio.
               </p>
                  <br />
                </div>
              </div>
            </div>
            <div>
              <div className="modal-content">
                <div className="modal-text">
                  <div className="modal-cover">
                    <img src={welcomeCover} />
                  </div>

                  <h3 className="modal-title">Bienvenidx</h3>
                  <p>
                    Aliquam quis eros eget velit ornare posuere. Aenean mauris neque,
                    scelerisque vitae dictum dictum, scelerisque ac odio.
            </p>
                  <br />
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <div className="settings-form__rowLast settings-form__rowLast-modalWelcome">
        <a className="button">
          Configurar Perfil
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 14L15 10L11 6" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15 10L5 10" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </a>
      </div>
    </>
  );
}

export default WelcomeSettings;