import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as Scroll from 'react-scroll';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import venderThumb from "../images/vender-1.svg";
import { useForm } from "react-hook-form";
import profileThumb from "../images/profileThumb.png";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import { loginUser, getTipos, registerUser } from "./API Functions";
import Fade from "react-reveal/Fade";
import $ from 'jquery';

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import useMightyMouse from "react-hook-mighty-mouse";

function openNav() {
  const navigationWindow = document.getElementById("myNav");
  const navigationWindowBox = document.getElementById("myBox");
  navigationWindow.style.width = "100%";
  navigationWindowBox.style.opacity = "1";
}

function closeNav() {
  const navigationWindow = document.getElementById("myNav");
  const navigationWindowBox = document.getElementById("myBox");
  navigationWindow.style.width = "0%";
  navigationWindowBox.style.opacity = "0";
}

function Julio() {
  const [showText, setShowText] = useState(false);

  const [headerStyle, setHeaderStyle] = useState({
    transition: "all 300ms ease-in",
  });

  const [headerStyle2, setHeaderStyle2] = useState({
    transition: "all 600ms ease-in",
  });

  const [Tipos, setTipos] = useState([]);

  const [Loading, setLoading] = useState(false);
  const [tipo_id, setTipoID] = useState("");
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [nombre_negocio, setNombreNegocio] = useState("");

  const [nombreError, setNombreError] = useState("");
  const [postEmailError, setPostEmailError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nombre_negocioError, setNombre_negocioError] = useState("");
  const [tipo_idError, setTipoIdError] = useState("");

  const [tiredness, setTiredness] = useState(0);

  // const {
  //   positionRelative: { angle: angleLeftEye }
  // } = useMightyMouse(true, "left-eye", { x: 45, y: 45 });

  // const {
  //   positionRelative: { angle: angleRightEye }
  // } = useMightyMouse(true, "right-eye", { x: 45, y: 45 });

  const {
    selectedElement: {
      position: { angle: angleLeftEye },
    },
  } = useMightyMouse(true, 'left-eye', { x: 20, y: 20 });
  const {
    selectedElement: {
      position: { angle: angleRightEye },
    },
  } = useMightyMouse(true, 'right-eye', { x: 20, y: 20 });

  const styleLeftEye = {
    transform: `rotate(${angleLeftEye}deg)`,
    transition: `all ${tiredness}s ease`
  };
  const styleRightEye = {
    transform: `rotate(${angleRightEye}deg)`,
    transition: `all ${tiredness}s ease`
  };

  useEffect(() => {
    getTipos().then((res) => {
      if (res) {
        setTipos(res);
      }
    });
  }, []);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isVisible = currPos.y > prevPos.y;

      const shouldBeStyle = {
        opacity: isVisible ? "0" : "1",
        transform: `scale(${isVisible ? "0" : "1"})`,
        transition: `all 200ms ${isVisible ? "ease-in" : "ease-out"} .5s`,
      };

      if (JSON.stringify(shouldBeStyle) === JSON.stringify(headerStyle)) return;

      setHeaderStyle(shouldBeStyle);
    },
    [headerStyle]
  );

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isVisible = currPos.y > prevPos.y;

      const shouldBeStyle = {
        opacity: isVisible ? "0" : "1",
        transform: `scale(${isVisible ? "0" : "1"})`,
        transition: `all 200ms ${isVisible ? "ease-in" : "ease-out"} 1s`,
      };

      if (JSON.stringify(shouldBeStyle) === JSON.stringify(headerStyle)) return;

      setHeaderStyle2(shouldBeStyle);
    },
    [headerStyle2]
  );

  function scrollTo() {
    scroller.scrollTo('myScrollToElement', {
      delay: 100,
      duration: 1000,
      smooth: 'easeOutQuad'
    });
  }

  function validateFields() {
    let nombreError = "";
    let emailError = "";
    let nombre_negocioError = "";
    let tipoIdError = "";

    if (nombre == "") {
      nombreError = "El campo no puede ser vacio";
    }
    if (email == "") {
      emailError = "El campo no puede ser vacio";
    }
    if (nombre_negocio == "") {
      nombre_negocioError = "El campo no puede ser vacio";
    }
    if (tipo_id == "") {
      tipoIdError = "Selecciona una opcion"
    }

    if (
      nombreError ||
      emailError ||
      nombre_negocioError ||
      tipoIdError
    ) {
      setNombreError(nombreError);
      setEmailError(emailError);
      setNombre_negocioError(nombre_negocioError);
      setTipoIdError(tipoIdError);
      return false;
    } else {
      setNombreError(nombreError);
      setEmailError(emailError);
      setNombre_negocioError(nombre_negocioError);
      setTipoIdError(tipoIdError);
      return true;
    }
  }

  function handleChange(id) {
    setTipoID(id);
  }

  const history = useHistory();

  function handleThanks() {
    history.push("/gracias");
  }

  function handleSubmit() {
    setLoading(true);
    const isValid = validateFields();
    const data = {
      nombre: nombre,
      correo: email,
      apellidos: nombre,
      nombre_negocio: nombre_negocio,
      whatsapp: "",
      envio_id: 2,
      url_negocio: "",
      tipo_id: tipo_id,
      password: "",
      role_name: "user",
      landing: true
    }
    if (isValid) {
      registerUser(data).then((res) => {
        if (res) {
          handleThanks()
        } else {
          setPostEmailError("Este correo ya existe");
        }
      });
    }
    setTimeout(() => {
      setLoading(false);
      setPostEmailError("");
    }, 2000);
  }

  return (
    <div>
      <div className="customLand" id="customLand">
        <Fade duration={1000} delay={1200}>
          <a onClick={openNav} className="button button-alpha customLand-button">
            Beneficios
          </a>
        </Fade>
        <Fade>
          <div className="customLand-intro">
            <Fade cascade duration={3000} delay={5500}>
              <h1 className="customLand-title">
                <Fade duration={3000} delay={100}>Vende </Fade>
                <Fade duration={3000} delay={200}>directamente </Fade>
                <Fade duration={3000} delay={300}>a </Fade>
                <Fade duration={3000} delay={400}>tus </Fade>
                <Fade duration={3000} delay={500}>clientes </Fade>
                <Fade duration={3000} delay={600}>sin </Fade>
                <Fade duration={3000} delay={700}>pagar </Fade>
                <Fade duration={3000} delay={800}>comisiones </Fade>
              </h1>
            </Fade>
            <Fade duration={3000} delay={900}>
              <p className="customLand-subtitle">
                Okki es una plataforma que conecta a dueños de negocios, restaurantes o
                prestadores de servicios directamente con las personas que buscan lo que
                tu vendes.
              </p>
            </Fade>

            <Fade duration={3000} delay={1000}>
              <a onClick={scrollTo}>
                <div
                  className="customLand-buttonApply rotating"
                  onMouseEnter={() => setShowText(!showText)}
                  onMouseLeave={() => setShowText(!showText)}
                >
                  {showText ? (
                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 22L20 30L28 22"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20 30L20 10"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  ) : (
                      <p>¡Aplicar!</p>
                    )}
                </div>
              </a>
            </Fade>
          </div>
        </Fade>
        <div className="customLand-cover">
          <svg style={{ ...headerStyle }}
            className="customLand-bubbles" width="162" height="167" viewBox="0 0 162 167" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M84.2742 40.7868C84.2742 40.7868 83.5329 29.1729 80.197 29.1729C76.8611 29.1729 77.3553 43.2578 77.3553 43.2578C77.3553 43.2578 69.2009 33.1265 61.7877 41.8987C60.5522 43.3813 60.7993 45.7288 62.4055 46.8408C64.2588 48.0763 67.5947 49.0647 73.7723 47.7057C73.7723 47.7057 65.6178 50.6709 62.8997 58.2076C62.4055 59.4431 63.3939 60.9258 64.8765 60.8022C67.9653 60.5551 73.5252 59.196 78.4673 53.0184C78.4673 53.0184 75.3785 59.6902 79.2086 63.6439C80.0734 64.5088 81.4325 64.6323 82.4209 64.1381C84.3978 63.1497 86.9924 60.5551 85.8804 54.501C85.8804 54.501 91.5638 58.7018 97.2472 57.4663C99.8418 56.9721 100.583 53.6362 98.4827 52.1536C97.0001 51.0416 94.529 50.0532 90.5754 49.6825C90.5754 49.6825 98.9769 49.8061 100.583 44.3698C100.954 42.8871 100.212 41.4045 98.7298 40.9103C97.0001 40.4161 93.9113 40.4161 89.4634 43.2578C89.4634 43.2578 96.1352 39.1806 96.8765 32.6323C97.0001 30.6555 95.1468 29.2964 93.2935 29.7906C90.6989 30.4084 86.8688 33.1265 84.2742 40.7868Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M83.1622 63.6439C83.4093 75.6284 81.6796 94.4083 73.6487 121.343" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M121.71 9.65162C85.0154 -6.90437 53.3861 0.632314 25.2162 29.42C11.749 43.0107 1 62.1613 1 88.2308C1 112.818 11.0077 127.644 23.9807 136.54L18.668 165.08L40.7838 144.694C60.7992 151.366 81.0618 149.266 81.0618 149.266C81.0618 149.266 161 144.076 161 69.3273C160.876 36.4624 142.344 18.918 121.71 9.65162Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <svg style={{ ...headerStyle2 }}
            className="customLand-bubbles2" width="71" height="75" viewBox="0 0 71 75" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34.1119 28.305C34.1119 19.7799 34.6061 13.2316 38.6833 13.2316C42.637 13.2316 42.1428 17.556 43.9961 25.7104C45.108 30.8996 49.1852 32.6293 52.0269 33.1235C53.6331 33.4942 54.8686 34.8533 54.8686 36.583V47.9498C54.8686 49.9266 53.2625 51.5328 51.2856 51.5328H48.691C48.1968 51.5328 47.5791 51.6563 47.0849 51.9034C45.4787 52.7683 40.5366 54.6216 31.8879 52.8919" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M34.112 26.2046C34.112 26.2046 23.1159 22.7451 17.9267 26.2046C12.6139 29.7876 18.9151 33.9884 21.0155 34.9768C22.7452 35.8417 32.5058 37.5714 32.8765 33.1235C33.2471 28.6756 22.7452 28.1814 22.7452 28.1814" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M31.888 34.9768C31.888 34.9768 33.4942 36.2123 33.4942 38.4363C33.4942 40.6602 31.0231 42.3899 26.4517 42.3899C21.8803 42.3899 18.6679 39.6718 17.9266 37.4478C17.1853 35.2239 19.1621 33.7413 19.1621 33.7413" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M31.641 47.7027C31.641 47.7027 33.0001 48.6911 33.0001 50.6679C33.0001 52.6447 30.8997 54.0038 26.9461 54.0038C22.9924 54.0038 20.3978 51.6563 19.78 49.803C19.1623 47.9498 20.892 46.7142 20.892 46.7142" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.1506 40.5366C20.1506 40.5366 15.9499 43.9961 21.6333 46.8378C28.058 50.0501 33.4943 48.1968 33.0001 44.7374C32.5059 41.7721 30.2819 41.8957 30.2819 41.8957" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M62.0347 56.9691C66.8533 51.0386 69.695 43.5019 69.695 35.3475C69.695 16.444 54.3745 1 35.3475 1C16.444 1 1 16.3205 1 35.3475C1 54.3745 16.3205 69.695 35.3475 69.695C42.1429 69.695 48.5676 67.7181 53.8803 64.2587L69.5714 73.1544L62.0347 56.9691Z" stroke="#081027" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          {/* <svg
            width="222"
            height="207"
            viewBox="0 0 222 207"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...headerStyle }}
            className="customLand-bubbles"
          >
            <path
              d="M185.112 160.305C185.112 151.78 185.606 145.232 189.683 145.232C193.637 145.232 193.143 149.556 194.996 157.71C196.108 162.9 200.185 164.629 203.027 165.124C204.633 165.494 205.869 166.853 205.869 168.583V179.95C205.869 181.927 204.262 183.533 202.286 183.533H199.691C199.197 183.533 198.579 183.656 198.085 183.903C196.479 184.768 191.537 186.622 182.888 184.892"
              stroke="#081027"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M185.112 158.205C185.112 158.205 174.116 154.745 168.927 158.205C163.614 161.788 169.915 165.988 172.015 166.977C173.745 167.842 183.506 169.571 183.876 165.124C184.247 160.676 173.745 160.181 173.745 160.181"
              stroke="#081027"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M182.888 166.977C182.888 166.977 184.494 168.212 184.494 170.436C184.494 172.66 182.023 174.39 177.452 174.39C172.88 174.39 169.668 171.672 168.927 169.448C168.185 167.224 170.162 165.741 170.162 165.741"
              stroke="#081027"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M182.641 179.703C182.641 179.703 184 180.691 184 182.668C184 184.645 181.9 186.004 177.946 186.004C173.992 186.004 171.398 183.656 170.78 181.803C170.162 179.95 171.892 178.714 171.892 178.714"
              stroke="#081027"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M171.151 172.537C171.151 172.537 166.95 175.996 172.633 178.838C179.058 182.05 184.494 180.197 184 176.737C183.506 173.772 181.282 173.896 181.282 173.896"
              stroke="#081027"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M213.035 188.969C217.853 183.039 220.695 175.502 220.695 167.347C220.695 148.444 205.375 133 186.347 133C167.444 133 152 148.32 152 167.347C152 186.375 167.32 201.695 186.347 201.695C193.143 201.695 199.568 199.718 204.88 196.259L220.571 205.154L213.035 188.969Z"
              stroke="#081027"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M84.2742 40.7868C84.2742 40.7868 83.5329 29.1729 80.197 29.1729C76.8611 29.1729 77.3553 43.2578 77.3553 43.2578C77.3553 43.2578 69.2009 33.1265 61.7877 41.8987C60.5522 43.3813 60.7993 45.7288 62.4055 46.8408C64.2588 48.0763 67.5947 49.0647 73.7723 47.7057C73.7723 47.7057 65.6178 50.6709 62.8997 58.2076C62.4055 59.4431 63.3939 60.9258 64.8765 60.8022C67.9653 60.5551 73.5252 59.196 78.4673 53.0184C78.4673 53.0184 75.3785 59.6902 79.2086 63.6439C80.0734 64.5088 81.4325 64.6323 82.4209 64.1381C84.3978 63.1497 86.9924 60.5551 85.8804 54.501C85.8804 54.501 91.5638 58.7018 97.2472 57.4663C99.8418 56.9721 100.583 53.6362 98.4827 52.1536C97.0001 51.0416 94.529 50.0532 90.5754 49.6825C90.5754 49.6825 98.9769 49.8061 100.583 44.3698C100.954 42.8871 100.212 41.4045 98.7298 40.9103C97.0001 40.4161 93.9113 40.4161 89.4634 43.2578C89.4634 43.2578 96.1352 39.1806 96.8765 32.6323C97.0001 30.6555 95.1468 29.2964 93.2935 29.7906C90.6989 30.4084 86.8688 33.1265 84.2742 40.7868Z"
              stroke="#081027"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M83.1622 63.6439C83.4093 75.6284 81.6796 94.4083 73.6487 121.343"
              stroke="#081027"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M121.71 9.65162C85.0154 -6.90437 53.3861 0.632314 25.2162 29.42C11.749 43.0107 1 62.1613 1 88.2308C1 112.818 11.0077 127.644 23.9807 136.54L18.668 165.08L40.7838 144.694C60.7992 151.366 81.0618 149.266 81.0618 149.266C81.0618 149.266 161 144.076 161 69.3273C160.876 36.4624 142.344 18.918 121.71 9.65162Z"
              stroke="#081027"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg> */}
          <Fade cascade duration={1000} delay={2200}>
            <div>
              <svg
                width="257"
                height="484"
                viewBox="0 0 257 484"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="customLand-guyMobile"
              >
                <path
                  d="M132.458 164.524C132.745 167.59 131.982 173.434 126.64 176.213C119.01 180.237 110.903 175.925 101.747 191.255C92.5902 206.585 83.8154 234.562 83.8154 234.562"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M151.343 161.553C151.343 161.553 148.482 172.284 154.872 174.68C161.263 177.075 175.76 182.249 185.775 189.914C194.55 196.716 220.111 220.286 227.169 230.442C233.941 240.215 197.602 262.731 180.434 268.863C178.24 269.63 177.286 272.216 178.431 274.229L178.526 274.324"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M129.883 173.53C129.883 173.53 141.805 177.362 152.965 173.338"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M200.749 231.688C200.749 231.688 179.003 216.837 172.326 208.98C172.04 208.597 171.373 208.885 171.373 209.364L172.422 251.713"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M176.237 278.157C177.286 280.648 180.052 282.756 182.914 286.972C185.775 291.187 182.627 290.804 182.627 290.804C180.148 289.654 172.422 284.48 168.13 275.857C163.933 267.234 165.745 260.144 169.465 254.97C173.185 249.796 203.42 229.963 203.134 230.155"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M182.818 286.972C182.818 286.972 187.492 289.942 187.11 286.397C186.729 283.522 183.2 279.115 180.72 277.007"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M184.726 281.51C184.726 281.51 187.778 283.331 188.827 282.373C189.781 281.414 189.495 279.498 182.914 273.845"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M108.328 274.803C108.328 274.803 126.068 277.774 142.092 277.678C158.115 277.582 168.035 275.857 168.035 275.857"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M183.2 267.809C183.2 267.809 183.486 269.821 185.107 271.546C186.633 273.27 189.113 275.187 189.209 276.815C189.304 278.444 186.919 277.678 186.919 277.678"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M196.553 199.016C196.553 199.016 196.553 206.777 183.2 217.891"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M89.729 217.795C89.729 217.795 94.8794 228.239 109.472 233.317"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M178.908 288.6C178.908 288.6 182.723 345.034 182.818 376.173C182.914 407.312 182.437 456.847 183.867 464.703C183.867 464.703 194.741 466.62 213.149 476.776C214.293 477.446 214.102 479.171 212.767 479.458C208.57 480.321 198.27 480.704 172.804 476.488C172.804 476.488 165.269 475.53 165.269 471.123L164.601 460.009C163.933 453.014 156.971 373.298 155.54 359.789C154.3 348.1 147.147 304.314 145.239 292.72C145.144 292.337 144.572 292.337 144.572 292.72C143.904 302.302 141.806 332.77 141.519 344.076C141.233 356.531 136.464 453.781 135.701 469.206C135.511 472.368 133.794 475.338 130.932 476.871C127.88 478.404 124.542 478.692 124.542 478.692C99.4576 482.524 88.9659 482.62 84.5785 482.141C83.1479 481.95 82.7664 480.129 84.0063 479.363C101.174 468.823 115.958 468.44 115.958 468.44C115.958 468.44 111.952 407.312 109.663 371.574C108.042 347.046 107.851 310.254 107.851 289.654C107.851 280.744 108.137 271.833 108.614 262.923C109.377 250.85 110.235 232.646 109.568 225.939C109.091 221.532 109.091 214.633 109.282 208.214"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M84.7693 236.766C84.7693 236.766 73.2285 212.525 68.1734 200.932C66.9335 198.154 66.2659 195.183 66.0751 192.117C65.9797 189.53 65.5982 186.369 64.549 184.548C62.9276 181.578 56.8234 174.775 53.1036 172.859C51.7684 172.188 50.1469 173.147 50.2423 174.68C50.2423 174.967 50.3377 175.35 50.4331 175.829"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M48.4302 197.962C48.4302 197.962 53.1991 207.735 57.3957 223.927C61.7831 240.694 71.2256 262.348 83.8155 264.552C96.4055 266.755 109.377 233.221 109.377 233.221"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M31.0713 185.698C31.0713 185.698 27.0654 190.393 28.8776 192.98C30.6898 195.567 35.1726 188.477 35.1726 188.477"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M34.6001 189.339C34.6001 189.339 31.3573 193.076 32.7879 195.088C34.2186 197.1 37.7476 191.543 37.7476 191.543"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M37.4615 192.022C37.4615 192.022 35.3632 196.237 37.1754 197.387C38.9876 198.537 41.7535 194.704 41.7535 194.704"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M41.2769 195.279C41.2769 195.279 39.1786 198.345 42.0399 200.166C44.329 201.603 46.5227 197.675 46.5227 197.675"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M28.9729 178.8L37.9385 170.081C39.083 169.027 40.7998 168.931 42.0397 169.793L61.6877 184.357C63.2137 185.506 63.4045 187.806 61.9738 189.147L53.0082 197.962C51.8637 199.016 50.1469 199.208 48.907 198.249L29.259 183.686C27.733 182.44 27.5422 180.141 28.9729 178.8Z"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M144.095 139.804C148.151 139.804 151.439 136.501 151.439 132.426C151.439 128.352 148.151 125.049 144.095 125.049C140.039 125.049 136.75 128.352 136.75 132.426C136.75 136.501 140.039 139.804 144.095 139.804Z"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M126.163 139.804C130.219 139.804 133.507 136.501 133.507 132.426C133.507 128.352 130.219 125.049 126.163 125.049C122.107 125.049 118.819 128.352 118.819 132.426C118.819 136.501 122.107 139.804 126.163 139.804Z"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M134.461 125.049C134.461 125.049 131.028 136.834 132.649 143.253C132.935 144.403 134.175 145.074 135.32 144.786C136.75 144.403 138.563 143.828 138.849 143.732"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M155.063 143.253C155.063 143.253 153.251 160.116 150.58 162.799C147.624 165.769 144.285 167.781 135.51 166.057C126.736 164.428 123.016 142.583 122.73 132.426C122.634 128.881 122.634 125.911 122.634 123.133"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M136.75 133.096C136.75 133.096 135.32 132.237 133.508 133.096"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M157.066 133.097C157.066 133.097 158.878 127.636 163.552 130.989C168.225 134.343 164.601 147.182 154.968 144.307"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M162.026 133.576C162.026 133.576 158.688 133.864 157.734 139.133"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M151.343 131.468L159.164 130.319"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M152.679 121.983C152.679 121.983 149.913 124.666 152.392 127.157C153.346 128.211 154.968 128.306 156.017 127.444"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M144.476 121.408C144.476 121.408 142.664 124.378 138.467 123.324C134.271 122.27 134.366 119.396 135.129 118.821C135.892 118.246 136.274 122.175 133.031 122.654C129.788 123.133 126.164 121.312 126.545 117.959C126.545 117.959 124.828 124.091 121.109 122.941C119.296 122.366 119.392 120.642 119.964 119.013C119.964 119.013 118.629 121.025 117.389 121.025C116.149 121.025 118.915 113.456 124.924 112.593"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M126.64 110.772C126.64 110.772 123.779 109.335 125.782 105.886C125.782 105.886 128.548 111.73 134.938 107.323C134.938 107.323 134.271 102.532 138.944 101.766C143.045 101.095 145.811 102.532 148.387 104.832"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M151.153 105.886C151.153 105.886 152.297 102.054 155.158 101C155.158 101 150.39 106.845 157.924 109.431C157.924 109.431 161.74 105.982 166.222 108.473C166.222 108.473 160.404 109.911 160.977 111.444C160.977 111.444 166.032 111.156 167.081 115.18C167.844 117.959 164.124 118.246 161.835 118.055C161.835 118.055 170.705 119.779 165.745 128.115"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M133.508 149.194C133.508 149.194 137.704 149.864 142.473 148.044"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M142.282 133.864C143.283 133.864 144.094 133.049 144.094 132.043C144.094 131.038 143.283 130.223 142.282 130.223C141.281 130.223 140.47 131.038 140.47 132.043C140.47 133.049 141.281 133.864 142.282 133.864Z"
                  fill="#081027"
                />
                <path
                  d="M128.834 133.864C129.835 133.864 130.646 133.049 130.646 132.043C130.646 131.038 129.835 130.223 128.834 130.223C127.833 130.223 127.022 131.038 127.022 132.043C127.022 133.049 127.833 133.864 128.834 133.864Z"
                  fill="#081027"
                />
                <path
                  d="M225.957 118.054C225.957 110.856 226.374 105.327 229.817 105.327C233.155 105.327 232.738 108.978 234.302 115.863C235.241 120.245 238.684 121.705 241.083 122.122C242.439 122.435 243.482 123.583 243.482 125.043V134.64C243.482 136.309 242.126 137.665 240.457 137.665H238.266C237.849 137.665 237.327 137.77 236.91 137.978C235.554 138.709 231.381 140.273 224.079 138.813"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M225.957 116.281C225.957 116.281 216.673 113.36 212.291 116.281C207.806 119.306 213.126 122.853 214.899 123.687C216.36 124.417 224.601 125.878 224.914 122.122C225.227 118.367 216.36 117.95 216.36 117.95"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M224.079 123.687C224.079 123.687 225.435 124.73 225.435 126.608C225.435 128.486 223.349 129.946 219.489 129.946C215.63 129.946 212.917 127.651 212.291 125.773C211.666 123.896 213.335 122.644 213.335 122.644"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M223.87 134.432C223.87 134.432 225.018 135.266 225.018 136.935C225.018 138.604 223.245 139.752 219.906 139.752C216.568 139.752 214.378 137.77 213.856 136.205C213.335 134.64 214.795 133.597 214.795 133.597"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M214.169 128.381C214.169 128.381 210.622 131.302 215.421 133.701C220.845 136.414 225.435 134.849 225.018 131.928C224.601 129.424 222.723 129.529 222.723 129.529"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M249.532 142.255C253.601 137.248 256 130.885 256 124C256 108.04 243.065 95 227 95C211.04 95 198 107.935 198 124C198 140.065 210.935 153 227 153C232.737 153 238.162 151.331 242.647 148.41L255.896 155.921L249.532 142.255Z"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M63.4555 30.8401C63.4555 30.8401 62.8996 22.1296 60.3976 22.1296C57.8957 22.1296 58.2663 32.6933 58.2663 32.6933C58.2663 32.6933 52.1505 25.0949 46.5907 31.674C45.664 32.786 45.8494 34.5466 47.054 35.3806C48.444 36.3072 50.9459 37.0486 55.5791 36.0293C55.5791 36.0293 49.4633 38.2532 47.4246 43.9057C47.054 44.8323 47.7953 45.9443 48.9073 45.8517C51.2239 45.6663 55.3938 44.647 59.1003 40.0138C59.1003 40.0138 56.7837 45.0177 59.6563 47.9829C60.305 48.6316 61.3243 48.7242 62.0656 48.3536C63.5482 47.6123 65.4941 45.6663 64.6602 41.1258C64.6602 41.1258 68.9227 44.2764 73.1853 43.3497C75.1312 42.9791 75.6872 40.4771 74.1119 39.3652C72.9999 38.5312 71.1467 37.7899 68.1814 37.5119C68.1814 37.5119 74.4826 37.6045 75.6872 33.5273C75.9652 32.4154 75.4092 31.3034 74.2972 30.9327C72.9999 30.5621 70.6833 30.5621 67.3474 32.6933C67.3474 32.6933 72.3513 29.6354 72.9073 24.7242C72.9999 23.2416 71.61 22.2223 70.22 22.593C68.2741 23.0563 65.4015 25.0949 63.4555 30.8401Z"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M62.6216 47.9829C62.8069 56.9713 61.5096 71.0563 55.4865 91.257"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M91.5328 7.48871C64.0116 -4.92828 40.2896 0.724235 19.1622 22.315C9.06178 32.508 1 46.871 1 66.4231C1 84.8632 8.50579 95.9829 18.2355 102.655L14.251 124.06L30.8378 108.771C45.8494 113.774 61.0463 112.199 61.0463 112.199C61.0463 112.199 121 108.307 121 52.2455C120.907 27.5968 107.008 14.4385 91.5328 7.48871Z"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

            </div>
          </Fade>

          <Fade cascade duration={1000} delay={2200}>
            <div>
              <div className="eyes">
                <div id="left-eye" className="eye" style={styleLeftEye}>
                  <div className="pupil" />
                </div>
                <div id="right-eye" className="eye" style={styleRightEye}>
                  <div className="pupil" />
                </div>
              </div>
              <svg
                width="412"
                height="513"
                viewBox="0 0 412 513"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="customLand-guy"
              >
                <path
                  d="M282.974 86.1215C283.358 90.23 282.335 98.0616 275.178 101.785C264.954 107.177 254.09 101.4 241.82 121.942C229.551 142.484 217.793 179.973 217.793 179.973"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M308.28 82.1415C308.28 82.1415 304.446 96.521 313.009 99.7307C321.572 102.94 340.999 109.873 354.418 120.144C366.177 129.26 400.429 160.844 409.887 174.453C418.961 187.548 370.267 217.72 347.261 225.936C344.322 226.964 343.044 230.43 344.577 233.126L344.705 233.255"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M279.523 98.19C279.523 98.19 295.499 103.326 310.453 97.9333"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M374.484 176.122C374.484 176.122 345.344 156.222 336.397 145.694C336.014 145.18 335.119 145.565 335.119 146.207L336.525 202.955"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M341.638 238.39C343.044 241.728 346.75 244.553 350.584 250.202C354.418 255.851 350.201 255.337 350.201 255.337C346.878 253.797 336.525 246.864 330.774 235.309C325.151 223.754 327.579 214.253 332.563 207.32C337.548 200.387 378.063 173.811 377.679 174.068"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M350.456 250.202C350.456 250.202 356.719 254.182 356.208 249.432C355.696 245.58 350.968 239.674 347.645 236.85"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M353.013 242.884C353.013 242.884 357.102 245.323 358.508 244.039C359.786 242.755 359.403 240.188 350.584 232.613"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M250.639 233.897C250.639 233.897 274.411 237.877 295.883 237.748C317.354 237.62 330.646 235.309 330.646 235.309"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M350.968 224.524C350.968 224.524 351.351 227.22 353.524 229.531C355.569 231.842 358.892 234.41 359.019 236.593C359.147 238.775 355.952 237.748 355.952 237.748"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M368.861 132.341C368.861 132.341 368.861 142.741 350.968 157.634"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M225.717 157.505C225.717 157.505 232.618 171.5 252.173 178.304"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M345.216 252.385C345.216 252.385 350.329 328.005 350.456 369.732C350.584 411.458 349.945 477.835 351.862 488.362C351.862 488.362 366.432 490.93 391.099 504.539C392.633 505.438 392.377 507.749 390.588 508.134C384.964 509.29 371.161 509.803 337.037 504.154C337.037 504.154 326.94 502.87 326.94 496.964L326.045 482.071C325.151 472.699 315.821 365.88 313.904 347.777C312.242 332.114 302.657 273.44 300.101 257.905C299.973 257.392 299.206 257.392 299.206 257.905C298.311 270.744 295.499 311.572 295.116 326.721C294.733 343.412 288.342 473.726 287.32 494.397C287.064 498.633 284.764 502.614 280.929 504.668C276.84 506.722 272.366 507.107 272.366 507.107C238.753 512.243 224.694 512.371 218.815 511.729C216.898 511.472 216.387 509.033 218.048 508.006C241.054 493.883 260.864 493.37 260.864 493.37C260.864 493.37 255.496 411.458 252.428 363.569C250.256 330.701 250 281.4 250 253.797C250 241.857 250.384 229.917 251.023 217.976C252.045 201.799 253.195 177.406 252.301 168.418C251.662 162.513 251.662 153.269 251.917 144.667"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M219.071 182.926C219.071 182.926 203.606 150.444 196.832 134.909C195.171 131.186 194.276 127.206 194.021 123.097C193.893 119.631 193.382 115.394 191.976 112.955C189.803 108.975 181.623 99.8591 176.639 97.2913C174.85 96.3926 172.677 97.6765 172.805 99.7307C172.805 100.116 172.933 100.629 173.06 101.271"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M170.376 130.929C170.376 130.929 176.767 144.025 182.39 165.722C188.269 188.19 200.922 217.206 217.793 220.159C234.663 223.112 252.045 178.176 252.045 178.176"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M147.116 114.495C147.116 114.495 141.748 120.786 144.176 124.253C146.604 127.719 152.611 118.219 152.611 118.219"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M151.844 119.374C151.844 119.374 147.499 124.381 149.416 127.077C151.333 129.774 156.062 122.327 156.062 122.327"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M155.678 122.969C155.678 122.969 152.867 128.618 155.295 130.159C157.723 131.699 161.43 126.564 161.43 126.564"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M160.791 127.334C160.791 127.334 157.979 131.443 161.813 133.882C164.881 135.808 167.82 130.544 167.82 130.544"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M144.304 105.251L156.318 93.568C157.851 92.1558 160.152 92.0274 161.813 93.1829L188.141 112.698C190.186 114.239 190.442 117.32 188.525 119.117L176.511 130.929C174.977 132.341 172.677 132.598 171.015 131.314L144.687 111.799C142.642 110.13 142.387 107.049 144.304 105.251Z"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M298.567 52.9973C304.002 52.9973 308.408 48.5712 308.408 43.1114C308.408 37.6516 304.002 33.2255 298.567 33.2255C293.132 33.2255 288.726 37.6516 288.726 43.1114C288.726 48.5712 293.132 52.9973 298.567 52.9973Z"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M274.539 52.9973C279.974 52.9973 284.38 48.5712 284.38 43.1114C284.38 37.6516 279.974 33.2255 274.539 33.2255C269.104 33.2255 264.698 37.6516 264.698 43.1114C264.698 48.5712 269.104 52.9973 274.539 52.9973Z"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M285.658 33.2255C285.658 33.2255 281.057 49.0173 283.23 57.6193C283.613 59.16 285.275 60.0587 286.808 59.6735C288.726 59.16 291.154 58.3896 291.537 58.2612"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M313.264 57.6193C313.264 57.6193 310.836 80.2156 307.258 83.8105C303.296 87.7906 298.822 90.4867 287.064 88.1757C275.306 85.9931 270.321 56.7206 269.938 43.1114C269.81 38.361 269.81 34.381 269.81 30.6577"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M288.726 44.008C288.726 44.008 286.808 42.8573 284.38 44.008"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M315.948 44.0101C315.948 44.0101 318.377 36.692 324.639 41.1856C330.902 45.6792 326.045 62.8832 313.137 59.0316"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M322.595 44.6521C322.595 44.6521 318.121 45.0372 316.843 52.0986"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M308.28 41.8275L318.76 40.2869"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M310.069 29.1171C310.069 29.1171 306.363 32.7119 309.686 36.05C310.964 37.4623 313.137 37.5907 314.543 36.4352"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M299.078 28.3467C299.078 28.3467 296.65 32.3268 291.026 30.9145C285.403 29.5022 285.531 25.6506 286.553 24.8802C287.576 24.1099 288.087 29.3738 283.741 30.0158C279.396 30.6577 274.539 28.2183 275.05 23.7248C275.05 23.7248 272.75 31.9416 267.765 30.4009C265.337 29.6306 265.465 27.3196 266.232 25.137C266.232 25.137 264.442 27.8332 262.781 27.8332C261.119 27.8332 264.826 17.6905 272.878 16.535"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M275.178 14.0947C275.178 14.0947 271.344 12.1689 274.028 7.54687C274.028 7.54687 277.734 15.3786 286.297 9.4727C286.297 9.4727 285.403 3.05328 291.665 2.02617C297.161 1.12745 300.867 3.05328 304.318 6.1346"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M308.024 7.54781C308.024 7.54781 309.558 2.41227 313.392 1C313.392 1 307.002 8.83169 317.099 12.2982C317.099 12.2982 322.211 7.6762 328.218 11.0143C328.218 11.0143 320.422 12.9401 321.189 14.9943C321.189 14.9943 327.962 14.6092 329.368 20.0015C330.391 23.7248 325.406 24.1099 322.339 23.8531C322.339 23.8531 334.225 26.1641 327.579 37.3339"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M284.38 65.5794C284.38 65.5794 290.004 66.4781 296.394 64.0387"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                {/* <div className="eyes">
                  <div id="left-eye" className="eye" style={styleLeftEye}>
                    <div className="pupil" />
                  </div>
                  <div id="right-eye" className="eye" style={styleRightEye}>
                    <div className="pupil" />
                  </div>
                </div> */}
                {/* <path

                  d="M296.138 45.0372C297.479 45.0372 298.567 43.9451 298.567 42.5978C298.567 41.2506 297.479 40.1585 296.138 40.1585C294.797 40.1585 293.71 41.2506 293.71 42.5978C293.71 43.9451 294.797 45.0372 296.138 45.0372Z"
                  fill="#081027"
                />
                <path

                  d="M278.118 45.0372C279.459 45.0372 280.546 43.9451 280.546 42.5978C280.546 41.2506 279.459 40.1585 278.118 40.1585C276.777 40.1585 275.689 41.2506 275.689 42.5978C275.689 43.9451 276.777 45.0372 278.118 45.0372Z"
                  fill="#081027"
                /> */}
                <path
                  d="M1.12793 383.512V395.566C1.12793 408.134 39.6067 418.265 87.0638 418.265C134.521 418.265 173 408.134 173 395.566V383.512"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M152.35 410.186V474.947C152.35 478.794 149.272 482 145.295 482C141.448 482 138.241 478.922 138.241 474.947V413.648"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21.7786 410.186V474.947C21.7786 478.794 24.8569 482 28.833 482C32.6809 482 35.8875 478.922 35.8875 474.947V413.648"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M48.5854 415.828V449.427C48.5854 453.274 51.6637 456.48 55.6399 456.48C59.4877 456.48 62.6943 453.403 62.6943 449.427V417.367"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M98.7361 418.008V449.299C98.7361 453.146 101.814 456.352 105.791 456.352C109.638 456.352 112.845 453.274 112.845 449.299V417.239"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M119.515 397.233L80.1382 386.205V379.152L119.515 390.052V397.233Z"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M173 377.997L119.514 397.233V390.052L173 370.816V377.997Z"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M133.623 359.787L173 370.688"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M80.1382 386.205V379.023L133.624 359.787"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M170.819 378.639C172.23 380.177 172.872 381.845 172.872 383.64C172.872 396.079 134.393 406.082 86.9358 406.082C39.4787 406.082 1 395.951 1 383.64C1 376.074 15.1089 369.534 36.657 365.43"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M68.5942 361.711C74.6226 361.326 80.7792 361.198 87.064 361.198C99.762 361.198 111.947 361.967 122.849 363.249C122.978 363.249 122.978 363.249 123.106 363.249C123.362 363.249 123.491 363.249 123.747 363.378"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M53.0745 337.473C58.3873 337.473 62.6942 335.923 62.6942 334.011C62.6942 332.099 58.3873 330.549 53.0745 330.549C47.7617 330.549 43.4548 332.099 43.4548 334.011C43.4548 335.923 47.7617 337.473 53.0745 337.473Z"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M62.6942 334.01L69.7487 373.124C69.7487 376.458 62.3094 379.023 53.0745 379.023C43.8396 379.023 36.4004 376.33 36.4004 373.124L43.4548 334.01"
                  stroke="#081027"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </Fade>
        </div>

        <Element name="myScrollToElement">
          <div className="customLand-form" id="registro">
            <h5>
              Aplica ahora y obtén beneficios únicos para los primeros 1,000 negocios
              en ser parte de Okki
      </h5>
            <br />
            <div>
              <label>Nombre completo</label>
              <div className="fieldError">{nombreError}</div>
              <input
                type="text"
                class="input registerInput"
                placeholder="Nombre completo"
                onChange={(e) => setNombre(e.target.value)}
              />
            </div>
            <div>
              <div className="fieldError">{emailError}</div>
              <div className="fieldError">{postEmailError}</div>
              <label>Correo electrónico</label>
              <div className="fieldError"></div>
              <div className="fieldError"></div>
              <input
                type="email"
                class="input registerInput"
                placeholder="Correo electrónico"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <div className="fieldError">{tipo_idError}</div>
              <div>
                {Tipos.map((tipo, index) => (
                  <label class="radioContainer">
                    {tipo.detalle == "Contratar"
                      ? "Doy un servicio"
                      : tipo.detalle == "Comer"
                        ? "Tengo un restaurante"
                        : tipo.detalle == "Comprar"
                          ? "Vendo un producto"
                          : null}
                    <input
                      type="radio"
                      name="category"
                      onChange={() => handleChange(tipo.id)}
                      value={tipo.id}
                    />
                    <span className="checkmark"></span>
                  </label>
                ))}
              </div>
            </div>
            <br />
            <div>
              <label>Nombre de tu negocio</label>
              <div className="fieldError">{nombre_negocioError}</div>
              <input
                type="text"
                class="input registerInput"
                placeholder="Nombre de tu negocio"
                onChange={(e) => setNombreNegocio(e.target.value)}
              />
            </div>
            <div>
              <button onClick={handleSubmit} className="button" style={{ display: "block" }}>
                Solicitar acceso
       </button>
            </div>
          </div>
        </Element>

        <div className="customLand-footer">
          <ul>
            <li>Okki © 2020</li>
            <li>
              <a target="_blank" href="https://www.instagram.com/okkico/">
                Instagram
       </a>
            </li>
            <li>
              <a target="_blank" href="https://www.twitter.com/okkico/">
                Updates
       </a>
            </li>
          </ul>
        </div>
      </div>
      <div id="myNav" className="overlayCustomLand">
        <a className="button button-alpha closebtn customLand-button" onClick={closeNav}>
          Cerrar
    </a>

        <div id="overlayContent" className="overlayCustomLand-content">
          <div id="myBox" className="overlayCustomLand-content-box">
            <Fade duration={1000} delay={1500}>
              <h2>En Okki hicimos las cosas más ligeras y simples, como eran antes en los viejos tiempos, y además</h2>
              <ul>
                <li>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 9.55556L8 14L17 6"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
        Hacer tu tienda en linea tan fácil como sonreir.
       </li>
                <li>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 9.55556L8 14L17 6"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
        Nunca pagarás comisiones por ventas. Promesa boyscout.
       </li>
                <li>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 9.55556L8 14L17 6"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
        Quitas a los intermediarios y pones tus propias reglas
       </li>
                <li>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 9.55556L8 14L17 6"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
        Das un servicio más personal a tus clientes
       </li>
                <li>
                  ¡De nada!
       </li>
              </ul>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Julio;
