import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import profileThumb from "../images/profileThumb.png";
import Navbar from "./Navbar";
import { getNegocios, putNegocios } from "./API Functions";

function Settings3() {
    const [Loading, setLoading] = useState(false);

    const [negocio, setNegocio] = useState([]);
    const [nuevoNegocio, setNewNegocio] = useState(false);
    const [ciudades, setCiudades] = useState([]);

    const [web, setWeb] = useState("");
    const [facebook, setFacebook] = useState("");
    const [instagram, setInstagram] = useState("");

    const [webError, setWebError] = useState("");
    const [facebookError, setFacebookError] = useState("");
    const [instagramError, setInstagramError] = useState("");

    useEffect(() => {
        getNegocios(1).then(res => {
            if (res) {
                const negocio = res;
                res.facebook == "" ? setNewNegocio(true) : setNewNegocio(false);
                setNegocio(negocio);
                setWeb(negocio.website);
                setFacebook(negocio.facebook);
                setInstagram(negocio.instagram);
            } else {
                window.location.replace("/login");
            }
        })
    }, [])

    function validateFields() {
        let webError = "";
        let instagramError = "";
        let facebookError = "";

        if (web == "") {
            webError = "El campo no puede ser vacio";
        }
        if (instagram == "" || instagram == null) {
            instagramError = "El campo no puede ser vacio";
        }
        if (facebook == "") {
            facebookError = "El campo no puede ser vacio";
        }


        if (
            webError ||
            instagramError ||
            facebookError
        ) {
            setWebError(webError);
            setInstagramError(instagramError);
            setFacebookError(facebookError);
            return false;
        } else {
            setWebError(webError);
            setInstagramError(instagramError);
            setFacebookError(facebookError);
            return true;
        }
    }

    function handleSubmit() {
        setLoading(true)
        const data = {
            id: negocio.id,
            tags_ids: negocio.tags_ids,
            nombre: negocio.nombre,
            codigo_postal: negocio.codigo_postal,
            direccion: negocio.direccion,
            whatsap: negocio.whatsap,
            telefono: negocio.whatsap,
            imagen_url: "provicional",
            categoria_id: negocio.categoria_id,
            descripcion: negocio.descripcion,
            dominio_okki: negocio.dominio_okki,
            rango_id: negocio.rango_id,
            ciudad_id: negocio.ciudad_id,
            zona: negocio.zona,
            precio_envio: negocio.precio_envio,
            persona_contacto: negocio.persona_contacto,
            envios: [negocio.envios[0].id],
            tipos: [negocio.tipos[0].id],
            //EDITED FIELDS ON VIEW
            instagram: instagram,
            facebook: facebook,
            website: web
        }
        console.log(data);
        const isValid = validateFields()
        if (isValid) {
            putNegocios(data).then(res => {
                if (res) {
                    nuevoNegocio ?
                        window.location.replace("/settings4")
                        :
                        window.location.reload()
                }
            })
        }
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }


    return (

        <div className="settings-form">
            <div className="row">
                <div
                    className={nuevoNegocio ? "disabledDiv" : ""}
                >
                    <div className="four columns settings-form__sidebar">
                        <div>
                            <div className="settings-header__breadcrumb">
                                <h3 className="settings-header__title">Configuración</h3>
                                <hr className="settingsDivider" />
                            </div>
                        </div>
                        <ul className="settings-form__menu">
                            <li>
                                <NavLink to="/settings" activeClassName="active">
                                    Perfil
        </NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings2" activeClassName="active">
                                    Dirección y envíos
        </NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings3" activeClassName="active">
                                    Redes Sociales
        </NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings4" activeClassName="active">
                                    Cuenta
        </NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings5" activeClassName="active">
                                    PRO
        </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="eight columns settings-form__container">
                    <div className="settings-form__row">
                        <div className="settings-form__column">
                            <label>Página Web</label>
                        </div>
                        <div className="settings-form__column">
                            <input
                                className="settings-form__input"
                                type="text"
                                placeholder="https://website.com/"
                                value={web}
                                onChange={e => setWeb(e.target.value)}
                            />
                            <div className="fieldError">{webError}</div>
                        </div>
                    </div>
                    <div className="settings-form__row">
                        <div className="settings-form__column">
                            <label>Facebook</label>
                        </div>
                        <div className="settings-form__column">
                            <p className="facebookurlInput-placeholder">facebook.com/</p>
                            <input
                                className="settings-form__input facebookurlInput"
                                type="text"
                                placeholder="okkiurl"
                                value={facebook}
                                onChange={e => setFacebook(e.target.value)}
                            />
                            <div className="fieldError">{facebookError}</div>
                        </div>
                    </div>
                    <div className="settings-form__row">
                        <div className="settings-form__column">
                            <label>Instagram</label>
                        </div>
                        <div className="settings-form__column">
                            <p className="instagramurlInput-placeholder">instagram.com/</p>
                            <input
                                className="settings-form__input instagramurlInput"
                                type="text"
                                placeholder="okkiurl"
                                value={instagram}
                                onChange={e => setInstagram(e.target.value)}
                            />
                            <div className="fieldError">{instagramError}</div>
                        </div>
                    </div>
                    <div className="settings-form__rowLast">
                        <a className="button" onClick={handleSubmit}>
                            {Loading && (
                                <i className="" style={{ marginRight: "5px" }} />
                            )}
                            {Loading && (
                                'Guardando...'
                            )}
                            {!Loading && (
                                nuevoNegocio ?
                                    'Continuar'
                                    :
                                    'Guardar'
                            )}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Settings3;
