import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import GlobalFonts from "./fonts/Fonts.js";
import "./App.scss";
import Home from "./components/Home.js";
import Perfil from "./components/Perfil.js";
import Vender from "./components/Vender.js";
import Vender2 from "./components/Vender-2.js";
import Settings from "./components/Settings.js";
import Settings2 from "./components/Settings-2.js";
import Settings3 from "./components/Settings-3.js";
import Settings4 from "./components/Settings-4.js";
import Settings5 from "./components/Settings-5.js";
import NewSettings from "./components/NewSettings.js";
import Catalogo from "./components/Catalogo.js";
import NuevoProducto from "./components/NuevoProducto.js";
import Login from "./components/Login.js";
import Backoffice from "./components/Backoffice.js";
import Backoffice2 from "./components/Backoffice2.js";
import Backoffice3 from "./components/Backoffice3.js";
import Category from "./components/Category.js";
import CategoryProfiles from "./components/CategoryProfiles.js";
import Recover from "./components/Recover.js";
import NewPassword from "./components/NewPassword.js";
import Search from "./components/Search.js";
import ErrorSearch from "./components/ErrorSearch.js";
import Page404 from "./components/Page404.js";
import About from "./components/About.js";
import PerfilServicios from "./components/PerfilServicios.js";
import Vender3 from "./components/Vender-3.js";
import CreatePassword from "./components/CreatePassword.js";
import RangoPrecios from "./components/Backoffice/RangoPrecios.js";
import Ciudades from "./components/Backoffice/Ciudades.js";
import Julio from "./components/Julio.js";
import WaitingList from "./components/Backoffice/Waiting List.js";
import Gracias from "./components/Gracias.js";
import ThankYou from "./components/ThankYou.js";
function App() {
  return (
    <div>
      <GlobalFonts />
      <Router>
        <Switch>
          <Route path="/home" exact>
            <Home />
          </Route>
          <Route path="/vender" component={Vender} />
          <Route path="/vender2" component={Vender2} />
          <Route path="/vender3" component={Vender3} />
          <Route path="/backoffice" component={Backoffice} />
          <Route path="/waiting-list" component={WaitingList} />
          <Route path="/login" component={Login} />
          <Route path="/backoffice2" component={Backoffice2} />
          <Route path="/backoffice3" component={Backoffice3} />
          <Route path="/new" component={CreatePassword} />
          <Route path="/create" component={CreatePassword} />
          <Route path="/perfil-servicios" component={PerfilServicios} />
          <Route path="/okker/:nombre_negocio" component={Perfil} />
          <Route path="/settings" component={Settings} />
          <Route path="/settings2" component={Settings2} />
          <Route path="/settings3" component={Settings3} />
          <Route path="/settings4" component={Settings4} />
          <Route path="/settings5" component={Settings5} />
          <Route path="/newsettings" component={NewSettings} />
          <Route path="/recover" component={Recover} />
          <Route path="/rango-precios" component={RangoPrecios} />
          <Route path="/ciudades" component={Ciudades} />
          <Route path="/nuevoproducto" component={NuevoProducto} />
          <Route path="/" component={Julio} exact/>
          <Route path="/thanks" component={ThankYou} />
          <Route path="/perfil-servicios" component={PerfilServicios} />
          <Route path="/catalogo" component={Catalogo} />
          {/*
     {/*
     
     <Route path="/category" component={Category} />
     <Route path="/category-profiles" component={CategoryProfiles} />
     
     <Route path="/search" component={Search} />
     <Route path="/error" component={ErrorSearch} />
     <Route path="/404" component={Page404} />
     <Route path="/about" component={About} />*/}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
