import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import cover from "../images/home-cover.svg";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import Navbar from "./Navbar";
import ScrollTop from "./ScrollTop";
import Select, { components } from "react-select";
import Fade from "react-reveal/Fade";
import Footer from "./Footer";
import ReactTooltip from "react-tooltip";

import { useModal, Modal } from "react-morphing-modal";

import "react-morphing-modal/dist/ReactMorphingModal.css";
import { loggedIn } from "./API Functions";

const options = [
  { value: "monterrey", label: "Monterrey" },
  { value: "guadalajara", label: "Guadalajara" },
  { value: "cdmx", label: "CDMX" },
];

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 8L10 12L14 8"
          stroke="#081027"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

function Home() {
  useEffect(() => {
  }, [])
  function closeCookieModal() {
    var modal = document.getElementById("myModal");

    modal.style.display = "none";
  }

  const { modalProps, getTriggerProps, activeModal, close } = useModal({
    onOpen() {
      var navbarMain = document.getElementById("navbarMain");
      navbarMain.style.zIndex = 0;
    },
    onClose() {
      var navbarMain = document.getElementById("navbarMain");
      navbarMain.style.zIndex = 9999;
    },
  });

  return (
    <div>
      <Navbar />

      <div className="home-cover container">
        <div className="row rowCenter">
          <div className="six columns">
            <Fade delay={200}>
              <h1 className="title home-cover__title">
                Explore colors, ideas & inspiration from topics our community loves.
       </h1>
              <p className="subtitle home-cover__subtitle">
                We create unique, high-quality editable illustrations that will help
                tell your story in the most beautiful way.
       </p>
              <div className="home-cover__profiles">
                <div className="profile-card">
                  <Link to="/perfil">
                    <div
                      className="profile-card__icon"
                      data-tip="Lagom Studio"
                      style={{ background: "#12145F" }}
                    ></div>
                    <ReactTooltip place="top" type="dark" effect="solid" />
                  </Link>
                </div>
                <div className="profile-card">
                  <div
                    className="profile-card__icon"
                    data-tip="Ambar Coffee"
                    style={{ background: "#FACB3A" }}
                  ></div>
                  <ReactTooltip place="top" type="dark" effect="solid" />
                </div>
                <div className="profile-card">
                  <div
                    className="profile-card__icon"
                    data-tip="Nintendo"
                    style={{ background: "#E70012" }}
                  ></div>
                  <ReactTooltip place="top" type="dark" effect="solid" />
                </div>
                <div className="profile-card">
                  <div
                    className="profile-card__icon"
                    data-tip="Luigi"
                    style={{ background: "green" }}
                  ></div>
                  <ReactTooltip place="top" type="dark" effect="solid" />
                </div>
                <div className="profile-card">
                  <div
                    className="profile-card__icon"
                    data-tip="Rayados"
                    style={{ background: "#3059C8" }}
                  ></div>
                  <ReactTooltip place="top" type="dark" effect="solid" />
                </div>
              </div>
            </Fade>
          </div>
          <div className="six columns">
            <Fade delay={500}>
              <img className="home-cover__img" src={cover} />
            </Fade>
          </div>
        </div>
      </div>
      <Fade delay={200}>
        <hr />
      </Fade>
      <Fade>
        <div className="home-gallery container">
          <div className="home-gallery__header row">
            <div className="four columns home-gallery__header-div">
              <h3 className="titleMin">Explorar</h3>
            </div>
            <div className="u-center four columns home-gallery__header-div">
              <a className="itemTag">Comprar</a>
              <a className="itemTag">Comer</a>
              <a className="itemTag">Contratar</a>
            </div>
            <div className="four columns home-gallery__header-div">
              <Select
                classNamePrefix="inputSelect"
                className="inputSelect u-pull-right"
                options={options}
                placeholder="Ciudad"
                components={{ DropdownIndicator }}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 6,
                  colors: {
                    ...theme.colors,
                    primary50: "#ccc",
                    primary25: "#F6F6F6",
                    primary: "black",
                  },
                })}
              />
              {/* <select className="u-pull-right">
       <option>Monterrey</option>
       <option>CDMX</option>
      </select> */}
            </div>
          </div>
          <div className="homeGrid">
            <div className="homeGrid-item">
              <div className="homeGrid-item__top">
                <img className="homeGrid-item__cover" src={itemThumb} />
                <div className="homeGrid-item__coverOverlay">
                  <a {...getTriggerProps()}>
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="21" cy="21" r="21" fill="white" />
                      <path
                        d="M28 19H14L16 28H26L28 19Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M24 19V17C24 15.3 22.7 14 21 14C19.3 14 18 15.3 18 17V19"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="homeGrid-item__info">
                <img className="homeGrid-item__icon" src={itemProfile} />
                <div>
                  <p>Antigua and Barbuda</p>
                  <p>$9,999</p>
                </div>
              </div>
              <Modal {...modalProps} className="modalItem" padding={false} closeButton={false}>
                <svg onClick={close} className="modalCloseButton" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="42" height="42" rx="21" fill="#3059C8" fill-opacity="0.08" />
                  <path d="M25.2422 16.7574L16.7569 25.2427" stroke="#3059C8" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M25.2431 25.2427L16.7578 16.7574" stroke="#3059C8" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                <div className="row rowLightbox">
                  <div className="seven columns">
                    <div className="modalItem-cover">
                      <img src={itemThumb} />
                    </div>
                  </div>
                  <div className="five columns">
                    <div className="modalItem-info">
                      <h5>Antigua and Barbuda</h5>
                      <p className="modalItem-info__price">$9,999</p>
                      <p className="modalItem-info__about">
                        Usa tu tarjeta de crédito Mastercard para tus compras diarias en
                        tiendas, en línea e incluso en lugares donde no aceptan efectivo ni
                        cheques como en los hoteles.
           </p>
                      <button className="button modalItem-info__button">
                        Comprar
            <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13 9V5C13 3.3 11.7 2 10 2C8.3 2 7 3.3 7 5V9"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14 18H6C4.3 18 3 16.7 3 15V6.5H17V15C17 16.7 15.7 18 14 18Z"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                      <div className="modalItem-info__tags">
                        <a className="itemTagBadgeAlt">Antigua</a>
                        <a className="itemTagBadgeAlt">Barbuda</a>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal>
            </div>
            <div className="homeGrid-item">
              <div className="homeGrid-item__top">
                <img className="homeGrid-item__cover" src={itemThumb} />
                <div className="homeGrid-item__coverOverlay">
                  <a href="#">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="21" cy="21" r="21" fill="white" />
                      <path
                        d="M28 19H14L16 28H26L28 19Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M24 19V17C24 15.3 22.7 14 21 14C19.3 14 18 15.3 18 17V19"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="homeGrid-item__info">
                <img className="homeGrid-item__icon" src={itemProfile} />
                <div>
                  <p>Antigua and Barbuda</p>
                  <p>$9,999</p>
                </div>
              </div>
            </div>
            <div className="homeGrid-item">
              <div className="homeGrid-item__top">
                <img className="homeGrid-item__cover" src={itemThumb} />
                <div className="homeGrid-item__coverOverlay">
                  <a href="#">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="21" cy="21" r="21" fill="white" />
                      <path
                        d="M28 19H14L16 28H26L28 19Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M24 19V17C24 15.3 22.7 14 21 14C19.3 14 18 15.3 18 17V19"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="homeGrid-item__info">
                <img className="homeGrid-item__icon" src={itemProfile} />
                <div>
                  <p>Antigua and Barbuda</p>
                  <p>$9,999</p>
                </div>
              </div>
            </div>
            <div className="homeGrid-item">
              <div className="homeGrid-item__top">
                <img className="homeGrid-item__cover" src={itemThumb} />
                <div className="homeGrid-item__coverOverlay">
                  <a href="#">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="21" cy="21" r="21" fill="white" />
                      <path
                        d="M28 19H14L16 28H26L28 19Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M24 19V17C24 15.3 22.7 14 21 14C19.3 14 18 15.3 18 17V19"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="homeGrid-item__info">
                <img className="homeGrid-item__icon" src={itemProfile} />
                <div>
                  <p>Antigua and Barbuda</p>
                  <p>$9,999</p>
                </div>
              </div>
            </div>
            <div className="homeGrid-item">
              <div className="homeGrid-item__top">
                <img className="homeGrid-item__cover" src={itemThumb} />
                <div className="homeGrid-item__coverOverlay">
                  <a href="#">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="21" cy="21" r="21" fill="white" />
                      <path
                        d="M28 19H14L16 28H26L28 19Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M24 19V17C24 15.3 22.7 14 21 14C19.3 14 18 15.3 18 17V19"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="homeGrid-item__info">
                <img className="homeGrid-item__icon" src={itemProfile} />
                <div>
                  <p>Antigua and Barbuda</p>
                  <p>$9,999</p>
                </div>
              </div>
            </div>
            <div className="homeGrid-item">
              <div className="homeGrid-item__top">
                <img className="homeGrid-item__cover" src={itemThumb} />
                <div className="homeGrid-item__coverOverlay">
                  <a href="#">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="21" cy="21" r="21" fill="white" />
                      <path
                        d="M28 19H14L16 28H26L28 19Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M24 19V17C24 15.3 22.7 14 21 14C19.3 14 18 15.3 18 17V19"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="homeGrid-item__info">
                <img className="homeGrid-item__icon" src={itemProfile} />
                <div>
                  <p>Antigua and Barbuda</p>
                  <p>$9,999</p>
                </div>
              </div>
            </div>
            <div className="homeGrid-item">
              <div className="homeGrid-item__top">
                <img className="homeGrid-item__cover" src={itemThumb} />
                <div className="homeGrid-item__coverOverlay">
                  <a href="#">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="21" cy="21" r="21" fill="white" />
                      <path
                        d="M28 19H14L16 28H26L28 19Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M24 19V17C24 15.3 22.7 14 21 14C19.3 14 18 15.3 18 17V19"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="homeGrid-item__info">
                <img className="homeGrid-item__icon" src={itemProfile} />
                <div>
                  <p>Antigua and Barbuda</p>
                  <p>$9,999</p>
                </div>
              </div>
            </div>
            <div className="homeGrid-item">
              <div className="homeGrid-item__top">
                <img className="homeGrid-item__cover" src={itemThumb} />
                <div className="homeGrid-item__coverOverlay">
                  <a href="#">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 42 42"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="21" cy="21" r="21" fill="white" />
                      <path
                        d="M28 19H14L16 28H26L28 19Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M24 19V17C24 15.3 22.7 14 21 14C19.3 14 18 15.3 18 17V19"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="homeGrid-item__info">
                <img className="homeGrid-item__icon" src={itemProfile} />
                <div>
                  <p>Antigua and Barbuda</p>
                  <p>$9,999</p>
                </div>
              </div>
            </div>
          </div>
          <div className="homeGrid-button centerFlex">
            <button className="button-loadMore">Cargar más productos</button>
            {/* <button className="button-loading">Cargando...</button> */}
          </div>
        </div>
      </Fade>
      <Footer />
      <ScrollTop />
      <div id="myModal" className="modal modalCookies">
        <div className="modal-cookies">
          <div className="modal-textCookies">
            <svg onClick={closeCookieModal} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.2427 5.75732L5.75739 14.2426" stroke="#A1A4AE" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M14.2426 14.2426L5.75732 5.75732" stroke="#A1A4AE" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <p>
              By using this website you accept our <a>cookie policy</a>.
            </p>
          </div>
        </div>
      </div>
    </div >
  );
}

export default Home;
