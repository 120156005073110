import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, NavLink } from "react-router-dom";
import profileThumb from "../images/profileThumb.png";
import welcomeCover from "../images/welcomeCover.svg";
import Navbar from "./NavbarProfile";

import Select from "react-select";
import { getNegocios, getCategorias, putNegocios, getRangoPrecios, CheckOkkiDomain } from "./API Functions";
import Tags from "@yaireo/tagify/dist/react.tagify";
import { func } from "prop-types";


const options = [
    { value: 1, label: "$1 - $99" },
    { value: 2, label: "$100 - $199" },
    { value: 3, label: "$200 - $399" },
];

export const serverDelay = (func) => (duration) =>
    new Promise((resolve, reject) =>
        setTimeout(() => {
            resolve(func());
        }, duration || 1000)
    );

export const getWhitelistFromServer = serverDelay(() => [
    "aaa",
    "aaa1",
    "aaa2",
    "aaa3",
    "bbb1",
    "bbb2",
    "bbb3",
    "bbb4",
]);

export const getValue = serverDelay(() => ["foo", "bar", "baz"]);

const baseTagifySettings = {
    blacklist: ["xxx", "sex", "porno", "pene", "p3n3", "pito", "puto", "pendejo"],
    maxTags: 5,
    //backspace: "edit",
    placeholder: "Etiquetas (máx 5)",
    dropdown: {
        enabled: 0, // a;ways show suggestions dropdown
    },
};

// callbacks props (for this demo, the same callback reference is assigned to every event type)
const tagifyCallbacks = {
    // add: callback,
    // remove: callback,
    // input: callback,
    // edit: callback,
    // invalid: callback,
    // click: callback,
    // keydown: callback,
    // focus: callback,
    // blur: callback,
    // "edit:input": callback,
    // "edit:updated": callback,
    // "edit:start": callback,
    // "edit:keydown": callback,
    // "dropdown:show": callback,
    // "dropdown:hide": callback,
    // "dropdown:select": callback
};

function Settings() {
    const tagifyRef = useRef();
    // just a name I made up for allowing dynamic changes for tagify settings on this component
    const [tagifySettings, setTagifySettings] = useState([]);
    const [tagifyProps, setTagifyProps] = useState({});

    const [nuevoNegocio, setNuevoNegocio] = useState(false);

    const [negocio, setNegocio] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [rangos, setRangos] = useState([]);


    const [Loading, setLoading] = useState(false);
    const [nombre, setNombre] = useState("");
    const [email, setEmail] = useState("");
    //const [nombre_negocio, setNombreNegocio] = useState("");
    const [url_negocio, setUrlNegocio] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [categoria_id, setCategoria] = useState("");
    const [tags, setTags] = useState([]);
    const [rango, setRango] = useState("");

    useEffect(() => {
        getNegocios(1).then(res => {
            if (!res) {
                window.location.replace("/login");
            } else {
                const negocio = res;
                setNegocio(negocio);
                setNombre(negocio.nombre);
                setEmail(negocio.correo);
                setWhatsapp(negocio.whatsap);
                setTags(JSON.parse(negocio.tags_ids));
                setDescripcion(negocio.descripcion);

                newNegocio(negocio);

                getCategorias().then(res => {
                    const categorias = [];
                    res.map((categoria, index) => {
                        if (categoria.tipo_id == negocio.tipos[0].id) {
                            categoria = {
                                ...categoria,
                                value: categoria.id,
                                label: categoria.detalle
                            }
                            categorias.push(categoria);
                            if (categoria.id == negocio.categoria_id) {
                                setCategoria(categoria);
                            }
                        }
                    })
                    setCategorias(categorias);

                })
                getRangoPrecios().then(res => {
                    const rangos = [];
                    res.map((rango, index) => {
                        rango = {
                            ...rango,
                            value: rango.id,
                            label: rango.detalle
                        }
                        rangos.push(rango);
                        if (rango.id == negocio.rango_id) {
                            setRango(rango);
                        }
                    });
                    setRangos(rangos);
                })
            }
        })
        setTagifyProps({ loading: true });

        getWhitelistFromServer(2000).then((response) => {
            setTagifySettings((lastState) => ({
                ...lastState,
                whitelist: response,
            }));

            setTagifyProps({ showDropdown: "a", loading: false });
        });
    }, [])

    // merged tagify settings (static & dynamic)
    const settings = {
        ...baseTagifySettings,
        ...tagifySettings,
        callbacks: tagifyCallbacks,
    };

    const onChange = useCallback((e) => {
        e.persist();
        console.log("CHANGED:", e.target.value);
        const tags = tagifyRef.current.getTagElms()
        console.log(tags)
    }, []);

    // access Tagify internal methods example:
    const clearAll = () => {
        tagifyRef.current && tagifyRef.current.removeAllTags();
    };

    function newNegocio(negocio) {
        if (negocio.dominio_okki) {
            setNuevoNegocio(false);
            setUrlNegocio(negocio.dominio_okki);
        } else {
            // openModal();
            setNuevoNegocio(true);
            setUrlNegocio("")
        }
    }



    const [nombreError, setNombreError] = useState("");
    const [postEmailError, setPostEmailError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [nombre_negocioError, setNombre_negocioError] = useState("");
    const [url_negocioError, setUrl_negocioError] = useState("");
    const [whatsappError, setWhatsappError] = useState("");
    const [descripcionError, setDescripcionError] = useState("");
    const [tagsError, setTagsError] = useState("");
    const [categoriaError, setCategoriaError] = useState("");
    const [rangoError, setRangoError] = useState("");

    const [dominio_okkiError, setDominio_okkiError] = useState("");

    function validateFields() {
        let nombreError = ""
        let emailError = ""
        let nombre_negocioError = ""
        let url_negocioError = ""
        let envio_idError = ""
        let whatsappError = ""
        let descripcionError = ""
        let tagsError = ""
        let categoriaError = ""
        let rangoError = ""

        if (nombre == "") {
            nombreError = "El campo no puede ser vacio"
        }
        if (email == "") {
            emailError = "El campo no puede ser vacio"
        }
        /*if(nombre_negocio == ""){
            nombre_negocioError = "El campo no puede ser vacio"
        }*/
        if (url_negocio == "") {
            url_negocioError = "El campo no puede ser vacio"
        }
        if (whatsapp == "") {
            whatsappError = "El campo no puede ser vacio"
        }
        if (descripcion == "") {
            descripcionError = "El campo no puede ser vacio"
        }
        if (tags == "") {
            tagsError = "El campo no puede ser vacio"
        }
        if (categoria_id == "") {
            categoriaError = "Selecciona una opcion"
        }
        if (rango == "") {
            rangoError = "Selecciona una opcion"
        }

        if (nombreError || emailError || nombre_negocioError || url_negocioError || envio_idError || whatsappError || descripcionError || rangoError) {
            setNombreError(nombreError)
            setEmailError(emailError)
            setNombre_negocioError(nombre_negocioError)
            setUrl_negocioError(url_negocioError)
            setWhatsappError(whatsappError)
            setDescripcionError(descripcionError)
            setTagsError(tagsError)
            setCategoriaError(categoriaError)
            setRangoError(rangoError)
            return false
        } else {
            setNombreError(nombreError)
            setEmailError(emailError)
            setNombre_negocioError(nombre_negocioError)
            setUrl_negocioError(url_negocioError)
            setWhatsappError(whatsappError)
            setDescripcionError(descripcionError)
            setTagsError(tagsError)
            setCategoriaError(categoriaError)
            setRangoError(rangoError)
            return true
        }
    }

    function handleCategoria(selectedOption) {
        setCategoria(selectedOption);
    }

    function handleRango(selectedOption) {
        setRango(selectedOption);
    }

    function updateDomain(e) {
        const value = e.target.value
        setUrlNegocio(value);

        const data = {
            dominio_okki: value
        }

        CheckOkkiDomain(data).then(res => {
            if (res) {
                setUrl_negocioError("El dominio esta disponible")
                setDominio_okkiError(false)
            } else {
                setUrl_negocioError("El dominio ya existe")
                setDominio_okkiError(true)
            }
        })
    }

    function handleSubmit() {
        setLoading(true)
        const data = {
            id: negocio.id,
            tags_ids: JSON.stringify(tags),
            nombre: nombre,
            codigo_postal: "0000",
            direccion: "provicional",
            website: negocio.website,
            whatsap: negocio.whatsap,
            facebook: "provicional",
            instagram: "provicional",
            telefono: negocio.whatsap,
            imagen_url: "provicional",
            categoria_id: categoria_id.id,
            descripcion: descripcion,
            dominio_okki: url_negocio,
            rango_id: rango.id,
            zona: negocio.zona,
            persona_contacto: negocio.persona_contacto,
            precio_envio: negocio.precio_envio,
            tipos: [negocio.tipos[0].id],
            envios: [negocio.envios[0].id],
            ciudad_id: 0
        }
        console.log(data);
        const isValid = validateFields()
        if (isValid) {
            putNegocios(data).then(res => {
                if (res) {
                    nuevoNegocio ?
                        window.location.replace("/settings2")
                        :
                        window.location.reload()
                }
            })
        }
        setTimeout(() => {
            setLoading(false);
            setDominio_okkiError("");
        }, 2000);
    }
    return (
        <>
            <div className="settings-form settings-form__modal">
                <div className="row">
                    <div
                        className={nuevoNegocio ? "disabledDiv" : ""}
                    >
                        <div className="four columns settings-form__sidebar">
                            <div className="settings-header settings-headerModal">
                                <div className="settings-header__breadcrumb">
                                    <h3 className="settings-header__title">Configuración</h3>
                                    <hr className="settingsDivider" />
                                </div>
                            </div>
                            <ul className="settings-form__menu">
                                <li>
                                    <NavLink to="/newsettings" activeClassName="active">
                                        Perfil
        </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/settings2" activeClassName="active">
                                        Dirección y envíos
        </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/settings3" activeClassName="active">
                                        Redes Sociales
        </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/settings4" activeClassName="active">
                                        Cuenta
        </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/settings5" activeClassName="active">
                                        PRO
        </NavLink>
                                </li>
                                <li>
                                    <NavLink to={{ pathname: `/okker/${negocio.dominio_okki}` }} activeClassName="active">
                                        Mi perfil
        </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="eight columns settings-form__container">
                        <div className="settings-header settings-form__row settings-headerModal">
                            <div className="settings-form__column">
                                <label>
                                    Logotipo
         <br />
                                    <span style={{ color: "#ACACB5", fontSize: 12 }}>
                                        Mín. 360 x 360 JPG, PNG, GIF
         </span>
                                </label>
                            </div>
                            <div className="settings-form__column columnRow">
                                <img className="settings-form__thumb" src={profileThumb} />
                                <a href="#" className="itemTagAlt colorBlack">
                                    Choose file
        </a>
                            </div>
                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column">
                                <label>Nombre del negocio</label>
                            </div>
                            <div className="settings-form__column">
                                <input
                                    className="settings-form__input"
                                    type="text"
                                    placeholder="Negocio"
                                    value={nombre ? nombre : null}
                                    onChange={e => setNombre(e.target.value)}
                                />
                                <div className="fieldError">
                                    {nombreError}
                                </div>
                            </div>
                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column">
                                <label>Okki URL</label>
                            </div>
                            <div className="settings-form__column">
                                <p className="okkiurlInput-placeholder">holaokki.com/</p>
                                <input
                                    className="settings-form__input okkiurlInput"
                                    type="text"
                                    placeholder="okkiurl"
                                    value={url_negocio ? url_negocio : null}
                                    onChange={(e) => { updateDomain(e) }}
                                />
                                <div className={dominio_okkiError ? "fieldError" : "fieldSuccess"}>
                                    {url_negocioError}
                                </div>
                            </div>
                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column">
                                <label>Correo electrónico</label>
                            </div>
                            <div className="settings-form__column">
                                <input
                                    className="settings-form__input"
                                    type="email"
                                    placeholder="ejemplo@correo.com"
                                    value={email ? email : null}
                                    disabled
                                />
                                <div className="fieldError">
                                    {emailError}
                                </div>
                            </div>
                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column">
                                <label>Whatsapp</label>
                            </div>
                            <div className="settings-form__column">
                                <p className="phoneInput-placeholder">+52</p>
                                <input
                                    className="settings-form__input phoneInput"
                                    type="tel"
                                    placeholder="81 1234 5678"
                                    value={whatsapp ? whatsapp : null}
                                    disabled
                                    onChange={e => setWhatsapp(e.target.value)}
                                />
                                <div className="fieldError">
                                    {whatsappError}
                                </div>
                            </div>
                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column">
                                <label>Tipo de negocio</label>
                            </div>
                            <div className="settings-form__column">
                                <input
                                    className="settings-form__input"
                                    type="text"
                                    placeholder="Comida"
                                    disabled
                                    value={negocio.tipos ? negocio.tipos[0].detalle : null}
                                />
                            </div>
                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column">
                                <label>Categoría</label>
                            </div>
                            <div className="settings-form__column">
                                <div className="fieldError">
                                    {categoriaError}
                                </div>
                                <Select
                                    classNamePrefix="inputSelect"
                                    className="inputSelect settings-form__input"
                                    options={categorias}
                                    value={categoria_id}
                                    onChange={handleCategoria}
                                    placeholder="Mexicana"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 6,
                                        colors: {
                                            ...theme.colors,
                                            primary50: "#ccc",
                                            primary25: "#F6F6F6",
                                            primary: "black",
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column">
                                <label>Etiquetas</label>
                            </div>
                            <div className="settings-form__column">
                                <Tags
                                    tagifyRef={tagifyRef}
                                    settings={settings}
                                    value={tags}
                                    {...tagifyProps}
                                    onChange={e => setTags(e.target.value)}
                                />
                                <div className="fieldError">
                                    {tagsError}
                                </div>
                            </div>
                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column">
                                <label>Rango de Precios</label>
                            </div>
                            <div className="settings-form__column">
                                <Select
                                    classNamePrefix="inputSelect"
                                    className="inputSelect settings-form__input"
                                    options={rangos}
                                    value={rango}
                                    onChange={handleRango}
                                    placeholder="De $1 - $99"
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 6,
                                        colors: {
                                            ...theme.colors,
                                            primary50: "#ccc",
                                            primary25: "#F6F6F6",
                                            primary: "black",
                                        },
                                    })}
                                />
                            </div>
                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column">
                                <label>Descripción</label>
                            </div>
                            <div className="settings-form__column">
                                <textarea className="settings-form__input" value={descripcion} onChange={e => setDescripcion(e.target.value)} />
                                <div className="fieldError">
                                    {descripcionError}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
}

export default Settings;
