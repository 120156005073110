import React from "react";
import { Link, NavLink } from "react-router-dom";
import { logOut } from "./API Functions";

function openNav() {
  const navigationWindow = document.getElementById("myNav");
  navigationWindow.style.width = "400px";
}

function closeNav() {
  const navigationWindow = document.getElementById("myNav");
  navigationWindow.style.width = "0%";
}

export default class Sidebar extends React.Component {
  render() {
    return (
      <div>
        <div className="sidebar">
          <svg
            width="49"
            height="22"
            viewBox="0 0 49 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.0937 14.9952L27.1444 10.252C27.4842 9.8208 27.2054 9.2928 26.6827 9.2928H23.2505C22.8846 9.2928 22.6668 9.4776 22.4839 9.6624L18.7729 14.0008V0.5896C18.7729 0.2816 18.4941 0 18.1892 0H14.7831C14.4782 0 14.1994 0.2816 14.1994 0.5896V21.1024C14.1994 21.4104 14.4782 21.692 14.7831 21.692H18.1892C18.4941 21.692 18.7729 21.4104 18.7729 21.1024V16.0512L22.9456 21.4456C23.0414 21.5688 23.2853 21.692 23.4073 21.692H27.5451C28.0678 21.692 28.3466 21.164 28.0068 20.7592L23.0937 14.9952ZM6.37667 8.9848C2.88344 8.9848 0 11.9592 0 15.4616C0 19.0256 2.88344 22 6.37667 22C9.86991 22 12.7533 19.0256 12.7533 15.4616C12.7621 11.9592 9.87862 8.9848 6.37667 8.9848ZM6.37667 18.0048C5.02642 18.0048 3.95493 16.8608 3.95493 15.4616C3.95493 14.0976 5.02642 12.98 6.37667 12.98C7.72693 12.98 8.79842 14.0976 8.79842 15.4616C8.80713 16.852 7.72693 18.0048 6.37667 18.0048ZM45.9521 5.544C47.3024 5.544 48.4087 4.4616 48.4087 3.0976C48.4087 1.7336 47.3024 0.616 45.9521 0.616C44.6019 0.616 43.5304 1.7336 43.5304 3.0976C43.5217 4.4616 44.6019 5.544 45.9521 5.544ZM47.6683 9.2928H44.297C43.9921 9.2928 43.7133 9.5392 43.7133 9.8824V21.1024C43.7133 21.4104 43.9921 21.692 44.297 21.692H47.6683C47.9731 21.692 48.2519 21.4104 48.2519 21.1024V9.8824C48.2519 9.548 47.9731 9.2928 47.6683 9.2928ZM37.8506 14.9952L41.9014 10.252C42.2411 9.8208 41.9623 9.2928 41.4397 9.2928H38.0074C37.6415 9.2928 37.4238 9.4776 37.2408 9.6624L33.5298 14.0008V0.5896C33.5298 0.2816 33.251 0 32.9461 0H29.54C29.2351 0 28.9564 0.2816 28.9564 0.5896V21.1024C28.9564 21.4104 29.2351 21.692 29.54 21.692H32.9461C33.251 21.692 33.5298 21.4104 33.5298 21.1024V16.0512L37.7025 21.4456C37.7983 21.5688 38.0423 21.692 38.1642 21.692H42.3021C42.8248 21.692 43.1035 21.164 42.7638 20.7592L37.8506 14.9952Z"
              fill="white"
            />
          </svg>

          <ul className="sidebar-menu">
          <li>
              <NavLink to="/waiting-list" activeClassName="active">
                Waiting List
       </NavLink>
            </li>
            <li>
              <NavLink to="/backoffice" activeClassName="active">
                Solicitudes
       </NavLink>
            </li>
            <li>
              <NavLink to="/backoffice2" activeClassName="active">
                Vendedores
       </NavLink>
            </li>
            <li>
              <NavLink to="/backoffice3" activeClassName="active">
                Categorías
       </NavLink>
            </li>
            <li>
              <NavLink to="/ciudades" activeClassName="active">
                Ciudades
       </NavLink>
            </li>
            <li>
              <NavLink to="/rango-precios" activeClassName="active">
                Rangos de Precio
       </NavLink>
            </li>
          </ul>
          <button className="button-flat sidebar-logout" onClick={() => { logOut(true) }}>
            Cerrar sesión
     </button>
        </div>
        <div className="sidebarResponsive">
          <svg
            width="49"
            height="22"
            viewBox="0 0 49 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.0937 14.9952L27.1444 10.252C27.4842 9.8208 27.2054 9.2928 26.6827 9.2928H23.2505C22.8846 9.2928 22.6668 9.4776 22.4839 9.6624L18.7729 14.0008V0.5896C18.7729 0.2816 18.4941 0 18.1892 0H14.7831C14.4782 0 14.1994 0.2816 14.1994 0.5896V21.1024C14.1994 21.4104 14.4782 21.692 14.7831 21.692H18.1892C18.4941 21.692 18.7729 21.4104 18.7729 21.1024V16.0512L22.9456 21.4456C23.0414 21.5688 23.2853 21.692 23.4073 21.692H27.5451C28.0678 21.692 28.3466 21.164 28.0068 20.7592L23.0937 14.9952ZM6.37667 8.9848C2.88344 8.9848 0 11.9592 0 15.4616C0 19.0256 2.88344 22 6.37667 22C9.86991 22 12.7533 19.0256 12.7533 15.4616C12.7621 11.9592 9.87862 8.9848 6.37667 8.9848ZM6.37667 18.0048C5.02642 18.0048 3.95493 16.8608 3.95493 15.4616C3.95493 14.0976 5.02642 12.98 6.37667 12.98C7.72693 12.98 8.79842 14.0976 8.79842 15.4616C8.80713 16.852 7.72693 18.0048 6.37667 18.0048ZM45.9521 5.544C47.3024 5.544 48.4087 4.4616 48.4087 3.0976C48.4087 1.7336 47.3024 0.616 45.9521 0.616C44.6019 0.616 43.5304 1.7336 43.5304 3.0976C43.5217 4.4616 44.6019 5.544 45.9521 5.544ZM47.6683 9.2928H44.297C43.9921 9.2928 43.7133 9.5392 43.7133 9.8824V21.1024C43.7133 21.4104 43.9921 21.692 44.297 21.692H47.6683C47.9731 21.692 48.2519 21.4104 48.2519 21.1024V9.8824C48.2519 9.548 47.9731 9.2928 47.6683 9.2928ZM37.8506 14.9952L41.9014 10.252C42.2411 9.8208 41.9623 9.2928 41.4397 9.2928H38.0074C37.6415 9.2928 37.4238 9.4776 37.2408 9.6624L33.5298 14.0008V0.5896C33.5298 0.2816 33.251 0 32.9461 0H29.54C29.2351 0 28.9564 0.2816 28.9564 0.5896V21.1024C28.9564 21.4104 29.2351 21.692 29.54 21.692H32.9461C33.251 21.692 33.5298 21.4104 33.5298 21.1024V16.0512L37.7025 21.4456C37.7983 21.5688 38.0423 21.692 38.1642 21.692H42.3021C42.8248 21.692 43.1035 21.164 42.7638 20.7592L37.8506 14.9952Z"
              fill="white"
            />
          </svg>
          <div className="navbar-iconWrapper">
            <a href="#" className="navbar-iconMenu" onClick={openNav}>
              <div></div>
              <div></div>
            </a>
          </div>
        </div>
        <div id="myNav" className="overlay overlayBackoffice">
          <a className="closebtn" onClick={closeNav}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L15 15" stroke="#9AA1B9" stroke-width="2" />
              <path d="M1 15L15 0.999999" stroke="#9AA1B9" stroke-width="2" />
            </svg>
          </a>

          <div
            id="overlayContent"
            className="overlay-content overlayBackoffice-content"
          >
            <div>
              <ul className="sidebar-menu">
                <li>
                  <NavLink to="/backoffice" activeClassName="active">
                    Socitudes
         </NavLink>
                </li>
                <li>
                  <NavLink to="/backoffice2" activeClassName="active">
                    Vendedores
         </NavLink>
                </li>
                <li>
                  <NavLink to="/backoffice3" activeClassName="active">
                    Categorías
         </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
