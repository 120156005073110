import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import selectArrow from "../images/selectArrow.svg";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import Navbar from "./Navbar";

import { loggedIn, getSubcategorias, getNegocios, postSubcategoria, getProductos, deleteSubcategoria } from "./API Functions";

import { ToggleLayer } from "react-laag";

import Modal from "react-modal";
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        padding: "40px",
        border: "none",
        borderRadius: 6,
    },
};

const PopoverMenu = React.forwardRef(function PopoverMenu(props, ref) {
    return (
        <ToggleLayer
            renderLayer={({ isOpen, layerProps }) =>
                isOpen && (
                    <div
                        ref={layerProps.ref}
                        className="layer dropdownMenu"
                        style={{
                            ...layerProps.style,
                            width: 120,
                            backgroundColor: "#ffffff",
                            borderWidth: 1,
                            borderColor: "#ced6db",
                            borderRadius: 6,
                            boxShadow: "0px 5px 10px rgba(8, 16, 39, 0.08)",
                        }}
                    >
                        <a className="dropdownItem">Editar</a>
                        <a className="dropdownItem">Desactivar</a>
                        <a className="dropdownItem">Eliminar</a>
                    </div>
                )
            }
            placement={{
                anchor: "BOTTOM_RIGHT",

                autoAdjust: true,

                triggerOffset: 10,
            }}
        >
            {({ triggerRef, toggle }) => (
                <div ref={triggerRef} className="toggle-btn" onClick={toggle}>
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle
                            cx="16"
                            cy="10"
                            r="2"
                            transform="rotate(90 16 10)"
                            fill="#081027"
                        />
                        <circle
                            cx="10"
                            cy="10"
                            r="2"
                            transform="rotate(90 10 10)"
                            fill="#081027"
                        />
                        <circle cx="4" cy="10" r="2" transform="rotate(90 4 10)" fill="#081027" />
                    </svg>
                </div>
            )}
        </ToggleLayer>
    );
});

function Catalogo() {
    var subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [subcategorias, setSubcategorias] = useState([]);
    const [subcategorias_productos, setSubcategoriasProductos] = useState([]);
    const [productos, setProductos] = useState([]);

    const [negocio, setNegocio] = useState([]);
    const [negocio_id, setNegocioId] = useState("");
    const [filter_subcategoria_id, setFilterSubcategoriaId] = useState("");
    const [detalle, setDetalle] = useState("");

    const [detalleError, setDetalleError] = useState("");


    useEffect(() => {
        loggedIn();
        loadSubcategorias();
        getNegocios(1).then(res => {
            if (res) {
                const negocio = res;
                setNegocio(negocio);
                setNegocioId(negocio.id);
            }
        })


    }, [])

    function loadSubcategorias() {
        getSubcategorias().then(res => {
            if (res) {
                setSubcategorias(res);
                loadProductos(res);
            } else {
                setSubcategorias([])
            }
        })
    }

    function loadProductos(subcategorias) {
        getProductos().then(res => {
            if (res) {
                const productos = res.data
                const subcategorias_productos = []
                const length = subcategorias.length
                //console.log(subcategorias)
                subcategorias.map((subcategoria, index_p) => {
                    const subcategoria_productos = []
                    productos.map((producto, index) => {
                        if (producto.subcategoria_id == subcategoria.id) {
                            subcategoria_productos.push(producto)
                        }
                    })
                    subcategoria = {
                        ...subcategoria,
                        productos: subcategoria_productos
                    }
                    subcategorias_productos[length - index_p + 1] = subcategoria
                })
                setProductos(res.data)
                setSubcategoriasProductos(subcategorias_productos)
            } else {
                setProductos([])
            }
        })
    }

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setIsOpen(false);
    }
    function validateFields() {
        let detalleError = "";

        if (detalle = "") {
            detalleError = "El campo no puede ser vacio"
        }

        if (detalleError) {
            setDetalleError(detalleError)
            return false
        } else {
            setDetalleError(detalleError)
            return true
        }
    }

    function handleCreate() {
        const data = {
            negocio_id: negocio_id,
            detalle: detalle
        }
        postSubcategoria(data).then(res => {
            if (res) {
                loadSubcategorias();
            }
        })
    }

    function handleDeleteSubcategoria(id) {
        const data = {
            subcategoria_id: id,
            subcategoria_default_id: subcategorias[0].id
        }
        deleteSubcategoria(id).then(res => {
            if (res) {
                loadSubcategorias();
            }
        })
    }

    const refs = subcategorias.reduce((acc, value) => {
        acc[value.id] = React.createRef();
        return acc;
    }, {});

    const ScrollToSubcategoria = id =>
        refs[id].current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });

    return (
        <div>
            <Navbar />
            <div className="settings-headerAlt container">
                <div className="settings-header__breadcrumbCatalog">
                    <h3 className="settings-header__title">Catálogo</h3>
                    <h3 className="settings-header__title resultsNumber">{productos.length}</h3>
                    <Link to="/nuevoproducto" className="button">
                        Nuevo producto
     </Link>
                </div>
            </div>
            <div className="home-gallery__filters container">
                <div className="home-gallery__filters-corner"></div>
                <ul className="home-gallery__filters-list">
                    {subcategorias.map((subcategoria, index) => (
                        <li onClick={() => { ScrollToSubcategoria(subcategoria.id) }}>
                            <a>
                                {subcategoria.detalle}
                            </a>
                        </li>
                    ))

                    }
                </ul>
                <div className="home-gallery__filters-corner">
                    <button className="button-secondary button-square" onClick={openModal}>
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M10 5V15"
                                stroke="#3059C8"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M15 10H5"
                                stroke="#3059C8"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </button>
                </div>
            </div>
            <div className="home-gallery container">
                {subcategorias_productos.map((subcategoria, index) => (
                    <div key={index} ref={refs[subcategoria.id]}>
                        <h3 className="titleMin">{subcategoria.detalle}</h3>
                        <br />
                        <div className="homeGrid">
                            {subcategoria.productos.map((producto, index) => (
                                <div className="homeGrid-item" key={index} >
                                    <div className="homeGrid-item__top">
                                        <img className="homeGrid-item__cover" src={itemThumb} />
                                        <div className="homeGrid-item__coverOverlay">
                                            <a>
                                                <svg
                                                    width="42"
                                                    height="42"
                                                    viewBox="0 0 42 42"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <circle cx="21" cy="21" r="21" fill="white" />
                                                    <path
                                                        d="M28 19H14L16 28H26L28 19Z"
                                                        stroke="black"
                                                        stroke-width="1.5"
                                                        stroke-miterlimit="10"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M24 19V17C24 15.3 22.7 14 21 14C19.3 14 18 15.3 18 17V19"
                                                        stroke="black"
                                                        stroke-width="1.5"
                                                        stroke-miterlimit="10"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="homeGrid-item__info homeGrid-item__dropdown">
                                        <div>
                                            <p>{producto.nombre}</p>
                                            <p>${producto.precio}</p>
                                        </div>
                                        <div>
                                            <PopoverMenu />
                                        </div>
                                    </div>
                                </div>
                            ))
                            }
                        </div>
                    </div>
                ))
                }
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Agregar Subcategoria Modal"
            >
                <p
                    ref={(_subtitle) => (subtitle = _subtitle)}
                    className="titleMin modalTitle"
                >
                    Agregar Sub-categoría
    </p>
                <br />
                <div className="modalInput">
                    <input type="text"
                        className="input inputSubcategory"
                        value={detalle}
                        onChange={e => setDetalle(e.target.value)}
                    />
                    <a onClick={handleCreate}>
                        Crear
     </a>
                    <div className="fieldError">
                        {detalleError}
                    </div>
                </div>
                {subcategorias.map((subcategoria, index) => (
                    <div className="" key={subcategoria.id}>
                        {index > 0 ?
                            <div className="modalInput-subcategory">
                                <div className="modalInput-subcategoryName">
                                    <a>
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <circle
                                                cx="10"
                                                cy="16"
                                                r="2"
                                                transform="rotate(-180 10 16)"
                                                fill="#E8E8EE"
                                            />
                                            <circle
                                                cx="10"
                                                cy="10"
                                                r="2"
                                                transform="rotate(-180 10 10)"
                                                fill="#E8E8EE"
                                            />
                                            <circle
                                                cx="10"
                                                cy="4"
                                                r="2"
                                                transform="rotate(-180 10 4)"
                                                fill="#E8E8EE"
                                            />
                                        </svg>
                                    </a>
                                    <p>{subcategoria.detalle}</p>
                                </div>

                                <a onClick={() => { handleDeleteSubcategoria(subcategoria.id) }}>
                                    <svg
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M12.8184 5.18176L5.18161 12.8185"
                                            stroke="#ACACB5"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M12.8184 12.8185L5.18164 5.18176"
                                            stroke="#ACACB5"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </a>


                            </div>
                            :
                            null
                        }
                    </div>
                ))
                }
            </Modal>
        </div>
    );
}

export default Catalogo;
