import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import aboutCover from "../images/about.svg";
import Accordion from "./elements/accordion";

function About() {
 return (
  <div>
   <Navbar />
   <div className="about-container">
    <div className="row">
     <div className="six columns about-container__cover">
      <img src={aboutCover} />
     </div>
     <div className="six columns about-container__info">
      <div className="about-container__infoBox">
       <Accordion>
        <div label="Okki, ¿Qué es?">
         <p className="panel">
          <Fade>
           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ex
           ante, consequat in leo eget, consequat sollicitudin arcu. Nam finibus
           tortor at eleifend ornare.
          </Fade>
         </p>
        </div>
        <div label="¿Cómo puedo vender?">
         <p className="panel">
          <Fade>
           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ex
           ante, consequat in leo eget, consequat sollicitudin arcu. Nam finibus
           tortor at eleifend ornare.
          </Fade>
         </p>
        </div>
        <div label="¿Cuál es el truco? Sin trucos.">
         <p className="panel">
          <Fade>
           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ex
           ante, consequat in leo eget, consequat sollicitudin arcu. Nam finibus
           tortor at eleifend ornare.
          </Fade>
         </p>
        </div>
        <div label="¿Quiénes están detras?">
         <p className="panel">
          <Fade>
           Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc ex
           ante, consequat in leo eget, consequat sollicitudin arcu. Nam finibus
           tortor at eleifend ornare.
          </Fade>
         </p>
        </div>
       </Accordion>
      </div>
     </div>
    </div>
   </div>
  </div>
 );
}

export default About;
