import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

function Footer() {
 return (
  <footer className="footer container">
   <div className="row">
    <div className="offset-by-two two columns footer-column">
     <p className="footer-subtitle">Explorar</p>
     <ul className="footer-menu">
      <li>
       <Link to="/">Comprar</Link>
      </li>
      <li>
       <Link to="/">Comer</Link>
      </li>
      <li>
       <Link to="/">Contratar</Link>
      </li>
     </ul>
    </div>
    <div className="two columns footer-column">
     <p className="footer-subtitle">Nosotros</p>
     <ul className="footer-menu">
      <li>
       <Link to="/about">Acerca de</Link>
      </li>
      <li>
       <Link to="/vender">Vender</Link>
      </li>
      <li>
       <Link to="/">Comprar</Link>
      </li>
     </ul>
    </div>
    <div className="two columns footer-column">
     <p className="footer-subtitle">Legal</p>
     <ul className="footer-menu">
      <li>Terminos</li>
      <li>Ayuda</li>
     </ul>
    </div>
    <div className="two columns footer-column">
     <p className="footer-subtitle">Social</p>
     <ul className="footer-menu">
      <li>
       <a href="https://www.facebook.com/" target="_blank">
        Facebook
       </a>
      </li>
      <li>
       <a href="https://twitter.com/" target="_blank">
        Twitter
       </a>
      </li>
      <li>
       <a href="https://instagram.com/" target="_blank">
        Instagram
       </a>
      </li>
     </ul>
    </div>
   </div>
   <div className="footer-bottom">
    <p className="footer-signature">
     © 2020 Okki, Todos los derechos reservados.
    </p>
   </div>
  </footer>
 );
}

export default Footer;
