import axios from "axios";
import NewSettings from "./NewSettings";

// const route = "https://back-okki-test.lagom.agency"
 const route = "https://back.okki.co"
// const route = "http://127.0.0.1:8000"
// const route = "http://127.0.0.1:8000"

//USER REQUESTS
export const registerUser = (newUser) => {
 return axios
  .post(`${route}/api/register/`, newUser, {
   headers: { "Content-Type": "application/json" },
  })
  .then((res) => {
   console.log(res);
   localStorage.setItem("user_token", res.data.data.token);
   return res;
  })
  .catch((err) => {
   console.log(err);
  });
};

export const loggedIn = () => {
    return axios
     .get(`${route}/api/logged_in`, {
      headers: { Authorization: `Bearer ${localStorage.user_token}` },
     })
     .then((res) => {
      console.log(res);
      return res.data.data;
     })
     .catch((err) => {
      console.log(err);
      window.location.replace("/login")
     });
   };

export const logOut = (newUser) => {
 if (true) {
  localStorage.clear();
  window.location.replace("/login");
 }
};

export const updateUser = (newUser) => {
 return axios
  .post(`${route}/api/update_user/`, newUser, {
   headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.user_token}`,
   },
  })
  .then((res) => {
   console.log(res);
   return res;
  })
  .catch((err) => {
   console.log(err);
  });
};

export const loginUser = (newUser) => {
 return axios
  .post(`${route}/api/login/`, newUser, {
   headers: { "Content-Type": "application/json" },
  })
  .then((res) => {
   console.log(res.data.data.token);
   localStorage.setItem("user_token", res.data.data.token);
   return res.data.data;
  })
  .catch((err) => {
   console.log(err);
  });
};

export const postRecoverPassword = data => {
    return axios
        .post(`${route}/api/password/create`, data, {
            headers: { Authorization: `Bearer ${localStorage.user_token}` }
        })
        .then(res => {
            console.log(res);
            return res;
        })
        .catch(err => {
            console.log(err)
        });
};

export const getMailFromToken = token => {
    return axios
        .get(`${route}/api/password/find/${token}`, {
            headers: { "Content-Type": "application/json" }
        })
        .then(res => {
            console.log(res);
            return res.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const newPassword = data => {
    return axios
        .post(`${route}/api/password/reset`, data, {
            headers: { "Content-Type": "application/json" }
        })
        .then(res => {
            console.log(res);
            return res
        })
        .catch(err => {
            console.log(err);
        });
};

export const deleteAccount = () => {
    return axios
     .get(`${route}/api/delete_account`, {
      headers: { Authorization: `Bearer ${localStorage.user_token}` },
     })
     .then((res) => {
      console.log(res);
      return res.data.data;
     })
     .catch((err) => {
      console.log(err);
     });
   };

//BACKOFFICE ROUTES

export const getBackofficeUsers = () => {
 return axios
  .get(`${route}/api/backoffice/list`, {
   headers: { Authorization: `Bearer ${localStorage.user_token}` },
  })
  .then((res) => {
   console.log(res);
   return res.data.data;
  })
  .catch((err) => {
   console.log(err);
  });
};

export const postChangeSuscriptionStatus = (data) => {
 return axios
  .post(`${route}/api/change_suscription_status/`, data, {
   headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.user_token}`,
   },
  })
  .then((res) => {
   console.log(res.data.token);
   return res;
  })
  .catch((err) => {
   console.log(err);
  });
};

export const getTipos = () => {
 return axios
  .get(`${route}/api/tipos`, {
   headers: { Authorization: `Bearer ${localStorage.user_token}` },
  })
  .then((res) => {
   console.log(res);
   return res.data.data;
  })
  .catch((err) => {
   console.log(err);
  });
};

//NEGOCIOS REQUESTS
export const putNegocios = (data) => {
    return axios
    .put(`${route}/api/negocios/${data.id}`, data, {
        headers: { "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.user_token}` }
    })
    .then(res => {
        console.log(res);
        return res.data.data
    })
    .catch(err => {
        console.log(err);
    });
}

export const getNegocios = id => {
    return axios
        .get(`${route}/api/negocios/${id}`, {
            headers: { 'Authorization' : `Bearer ${localStorage.user_token}` }
        })
        .then(res => {
            console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const CheckOkkiDomain = data => {
    return axios
        .post(`${route}/api/check_okki_domain/`, data, {
            headers: { "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.user_token}` }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

//CATEGORIAS REQUESTS

export const getCategoriasTipo = tipo_id => {
    return axios
        .get(`${route}/api/categorias_tipo/${tipo_id}`, {
            headers: { 'Authorization' : `Bearer ${localStorage.user_token}` }
        })
        .then(res => {
            console.log(res);
            return res.data.data;
        })
        .catch(err => {
            console.log(err);
        });
};

export const getCategorias = (id) => {
 return axios
  .get(`${route}/api/categorias/`, {
   headers: { Authorization: `Bearer ${localStorage.user_token}` },
  })
  .then((res) => {
   console.log(res);
   return res.data.data;
  }) 
  .catch((err) => {
   console.log(err);
  });
};

export const postCategoria = data => {
    return axios
        .post(`${route}/api/categorias/`, data, {
            headers: { "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.user_token}` }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const putCategoria = (data) => {
 return axios
  .put(`${route}/api/categorias/${data.id}`, data, {
   headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.user_token}`,
   },
  })
  .then((res) => {
   console.log(res.data.data);
   return res;
  })
  .catch((err) => {
   console.log(err);
  });
};

export const deleteCategoria = (data) => {
 return axios
  .delete(`${route}/api/categorias/${data}`, {
   headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.user_token}`,
   },
  })
  .then((res) => {
   console.log(res.data.data);
   return res;
  })
  .catch((err) => {
   console.log(err);
  });
};

// CIUDAD REQUESTS

export const getCiudades = () => {
    return axios
     .get(`${route}/api/ciudades/`, {
      headers: { Authorization: `Bearer ${localStorage.user_token}` },
     })
     .then((res) => {
      console.log(res);
      return res.data.data;
     }) 
     .catch((err) => {
      console.log(err);
     });
   };

export const postCiudad = data => {
    return axios
        .post(`${route}/api/ciudades/`, data, {
            headers: { "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.user_token}` }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const putCiudad = (data) => {
 return axios
  .put(`${route}/api/ciudades/${data.id}`, data, {
   headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.user_token}`,
   },
  })
  .then((res) => {
   console.log(res.data.data);
   return res;
  })
  .catch((err) => {
   console.log(err);
  });
};

export const deleteCiudad = (data) => {
 return axios
  .delete(`${route}/api/ciudades/${data}`, {
   headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.user_token}`,
   },
  })
  .then((res) => {
   console.log(res.data.data);
   return res;
  })
  .catch((err) => {
   console.log(err);
  });
};

// RANGO PRECIOS REQUESTS
export const getRangoPrecios = () => {
    return axios
     .get(`${route}/api/rango_precios/`, {
      headers: { Authorization: `Bearer ${localStorage.user_token}` },
     })
     .then((res) => {
      console.log(res);
      return res.data.data;
     }) 
     .catch((err) => {
      console.log(err);
     });
   };

export const postRangoPrecio = data => {
    return axios
        .post(`${route}/api/rango_precios/`, data, {
            headers: { "Content-Type": "application/json",
            "Authorization": `Bearer ${localStorage.user_token}` }
        })
        .then(res => {
            console.log(res);
            return res.data.data
        })
        .catch(err => {
            console.log(err);
        });
};

export const putRangoPrecio = (data) => {
 return axios
  .put(`${route}/api/rango_precios/${data.id}`, data, {
   headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.user_token}`,
   },
  })
  .then((res) => {
   console.log(res.data.data);
   return res;
  })
  .catch((err) => {
   console.log(err);
  });
};

export const deleteRangoPrecio = (data) => {
 return axios
  .delete(`${route}/api/rango_precios/${data}`, {
   headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.user_token}`,
   },
  })
  .then((res) => {
   console.log(res.data.data);
   return res;
  })
  .catch((err) => {
   console.log(err);
  });
};

// PRODUCTOS REQUEST
export const getProductos = (id) => {
    return axios
     .get(`${route}/api/productos/`, {
      headers: { Authorization: `Bearer ${localStorage.user_token}` },
     })
     .then((res) => {
      console.log(res);
      return res.data.data;
     }) 
     .catch((err) => {
      console.log(err);
     });
   };
   
   export const postProducto = data => {
       return axios
           .post(`${route}/api/productos/`, data, {
               headers: { "Content-Type": "application/json",
               "Authorization": `Bearer ${localStorage.user_token}` }
           })
           .then(res => {
               console.log(res);
               return res.data.data
           })
           .catch(err => {
               console.log(err);
           });
   };
   
   export const putProducto = (data) => {
    return axios
     .put(`${route}/api/productos/${data.id}`, data, {
      headers: {
       "Content-Type": "application/json",
       Authorization: `Bearer ${localStorage.user_token}`,
      },
     })
     .then((res) => {
      console.log(res.data.data);
      return res;
     })
     .catch((err) => {
      console.log(err);
     });
   };
   
   export const deleteProducto = (data) => {
    return axios
     .delete(`${route}/api/productos/${data}`, {
      headers: {
       "Content-Type": "application/json",
       Authorization: `Bearer ${localStorage.user_token}`,
      },
     })
     .then((res) => {
      console.log(res.data.data);
      return res;
     })
     .catch((err) => {
      console.log(err);
     });
   };

// SUBCATEGORIAS
export const getSubcategorias = (id) => {
    return axios
     .get(`${route}/api/subcategorias/`, {
      headers: { Authorization: `Bearer ${localStorage.user_token}` },
     })
     .then((res) => {
      console.log(res);
      return res.data.data;
     }) 
     .catch((err) => {
      console.log(err);
     });
   };
   
   export const postSubcategoria = data => {
       return axios
           .post(`${route}/api/subcategorias/`, data, {
               headers: { "Content-Type": "application/json",
               "Authorization": `Bearer ${localStorage.user_token}` }
           })
           .then(res => {
               console.log(res);
               return res.data.data
           })
           .catch(err => {
               console.log(err);
           });
   };
   
   export const putSubcategorias = (data) => {
    return axios
     .put(`${route}/api/subcategorias/${data.id}`, data, {
      headers: {
       "Content-Type": "application/json",
       Authorization: `Bearer ${localStorage.user_token}`,
      },
     })
     .then((res) => {
      console.log(res.data.data);
      return res;
     })
     .catch((err) => {
      console.log(err);
     });
   };
   
   export const deleteSubcategoria = (data) => {
    return axios
     .delete(`${route}/api/subcategorias/${data}`, {
      headers: {
       "Content-Type": "application/json",
       Authorization: `Bearer ${localStorage.user_token}`,
      },
     })
     .then((res) => {
      console.log(res.data.data);
      return res;
     })
     .catch((err) => {
      console.log(err);
     });
   };
