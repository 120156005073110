import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import searchIcon from "../images/search_icon.svg";
import faceIcon1 from "../images/okkiFace1.svg";
import faceIcon2 from "../images/okkiFace2.svg";
import faceIcon3 from "../images/okkiFace3.svg";
import faceIcon4 from "../images/okkiFace4.svg";
import faceIcon5 from "../images/okkiFace5.svg";
import faceIcon6 from "../images/okkiFace6.svg";
import faceIcon7 from "../images/okkiFace7.svg";
import Select, { components } from "react-select";
import { logOut } from "./API Functions";
import NewSettings from "./NewSettings";
import itemProfile from "../images/itemProfile.svg";


const options = [
  { value: "monterrey", label: "Monterrey" },
  { value: "guadalajara", label: "Guadalajara" },
  { value: "cdmx", label: "CDMX" },
];

const okkiFaces = [
  faceIcon1,
  faceIcon2,
  faceIcon3,
  faceIcon4,
  faceIcon5,
  faceIcon6,
  faceIcon7,
];

function openNav() {
  const navigationWindow = document.getElementById("myNav");
  navigationWindow.style.width = "580px";
}

function closeNav() {
  const navigationWindow = document.getElementById("myNav");
  navigationWindow.style.width = "0%";
}

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 8L10 12L14 8"
          stroke="#081027"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

export default class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      prevScrollpos: typeof window !== "undefined" && window.pageYOffset,
      visible: true,
      activeLogo: false,
      visibleLogo: false,
      hoveredBuy: false,
      hoveredEat: false,
      hoveredHire: false,
      isOpen : false
    };
  }
  state = {
    activeMenu: true,
    isFocused: false,
  };

  onMouseEnterBuy = (e) => {
    this.setState({ hoveredBuy: true });
  };
  onMouseEnterEat = (e) => {
    this.setState({ hoveredEat: true });
  };
  onMouseEnterHire = (e) => {
    this.setState({ hoveredHire: true });
  };

  onMouseLeaveBuy = (e) => {
    this.setState({ hoveredBuy: false });
  };
  onMouseLeaveEat = (e) => {
    this.setState({ hoveredEat: false });
  };
  onMouseLeaveHire = (e) => {
    this.setState({ hoveredHire: false });
  };

  onFocusChange = () => {
    this.setState({ isFocused: !this.state.isFocused });
  };

  toggleMenu = () => {
    this.setState({
      activeMenu: !this.state.activeMenu,
    });
  };
  escFunction(event) {
    if (event.keyCode === 27) {
      const navigationWindow = document.getElementById("myNav");
      navigationWindow.style.width = "0%";
    }
  }
  componentDidMount() {
    if (typeof window === "undefined") {
      global.window = {};
    }
    window.addEventListener("scroll", this.handleScroll);

    document.addEventListener("keydown", this.escFunction, false);

    document.addEventListener("mousedown", this.handleClickOutside);

    this.changeImage();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    document.removeEventListener("keydown", this.escFunction, false);
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const currentScrollPosLogo = window.pageYOffset >= 100;
    const visible = prevScrollpos > currentScrollPos || "100" > currentScrollPos;
    const visibleLogo =
      prevScrollpos > currentScrollPos || "400" > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    });
    this.setState({
      activeLogo: currentScrollPosLogo,
      visibleLogo,
    });
  };

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      const navigationWindow = document.getElementById("myNav");
      navigationWindow.style.width = "0%";
    }
  }

  changeImage = () => {
    const randomNumber = Math.floor(Math.random() * okkiFaces.length);
    this.setState({
      currentImageIndex: randomNumber,
    });
  };

  showSettings = () => {
    closeNav()
    this.setState({
      isOpen : true
    })
  }


  render() {
    const { hoveredEat } = this.state;
    const { hoveredBuy } = this.state;
    const { hoveredHire } = this.state;
    const styleBuy = hoveredBuy ? { backgroundColor: "#95983A" } : {};
    const styleEat = hoveredEat ? { backgroundColor: "#ED3325" } : {};
    const styleHire = hoveredHire ? { backgroundColor: "#FF9C38" } : {};

    

    return (
      <>
      
        <NewSettings isOpen={this.state.isOpen} />
        
        <Link to="/" className="okkiLogo">
          <div>
            <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M35.0937 39.9952L39.1444 35.252C39.4842 34.8208 39.2054 34.2928 38.6827 34.2928H35.2505C34.8846 34.2928 34.6668 34.4776 34.4839 34.6624L30.7729 39.0008V25.5896C30.7729 25.2816 30.4941 25 30.1892 25H26.7831C26.4782 25 26.1994 25.2816 26.1994 25.5896V46.1024C26.1994 46.4104 26.4782 46.692 26.7831 46.692H30.1892C30.4941 46.692 30.7729 46.4104 30.7729 46.1024V41.0512L34.9456 46.4456C35.0414 46.5688 35.2853 46.692 35.4073 46.692H39.5451C40.0678 46.692 40.3466 46.164 40.0068 45.7592L35.0937 39.9952ZM18.3767 33.9848C14.8834 33.9848 12 36.9592 12 40.4616C12 44.0256 14.8834 47 18.3767 47C21.8699 47 24.7533 44.0256 24.7533 40.4616C24.7621 36.9592 21.8786 33.9848 18.3767 33.9848ZM18.3767 43.0048C17.0264 43.0048 15.9549 41.8608 15.9549 40.4616C15.9549 39.0976 17.0264 37.98 18.3767 37.98C19.7269 37.98 20.7984 39.0976 20.7984 40.4616C20.8071 41.852 19.7269 43.0048 18.3767 43.0048ZM57.9521 30.544C59.3024 30.544 60.4087 29.4616 60.4087 28.0976C60.4087 26.7336 59.3024 25.616 57.9521 25.616C56.6019 25.616 55.5304 26.7336 55.5304 28.0976C55.5217 29.4616 56.6019 30.544 57.9521 30.544ZM59.6683 34.2928H56.297C55.9921 34.2928 55.7133 34.5392 55.7133 34.8824V46.1024C55.7133 46.4104 55.9921 46.692 56.297 46.692H59.6683C59.9731 46.692 60.2519 46.4104 60.2519 46.1024V34.8824C60.2519 34.548 59.9731 34.2928 59.6683 34.2928ZM49.8506 39.9952L53.9014 35.252C54.2411 34.8208 53.9623 34.2928 53.4397 34.2928H50.0074C49.6415 34.2928 49.4238 34.4776 49.2408 34.6624L45.5298 39.0008V25.5896C45.5298 25.2816 45.251 25 44.9461 25H41.54C41.2351 25 40.9564 25.2816 40.9564 25.5896V46.1024C40.9564 46.4104 41.2351 46.692 41.54 46.692H44.9461C45.251 46.692 45.5298 46.4104 45.5298 46.1024V41.0512L49.7025 46.4456C49.7983 46.5688 50.0423 46.692 50.1642 46.692H54.3021C54.8248 46.692 55.1035 46.164 54.7638 45.7592L49.8506 39.9952Z" fill="#081027" />
            </svg>
            {/* {!this.state.activeLogo ? (
              <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M35.0937 39.9952L39.1444 35.252C39.4842 34.8208 39.2054 34.2928 38.6827 34.2928H35.2505C34.8846 34.2928 34.6668 34.4776 34.4839 34.6624L30.7729 39.0008V25.5896C30.7729 25.2816 30.4941 25 30.1892 25H26.7831C26.4782 25 26.1994 25.2816 26.1994 25.5896V46.1024C26.1994 46.4104 26.4782 46.692 26.7831 46.692H30.1892C30.4941 46.692 30.7729 46.4104 30.7729 46.1024V41.0512L34.9456 46.4456C35.0414 46.5688 35.2853 46.692 35.4073 46.692H39.5451C40.0678 46.692 40.3466 46.164 40.0068 45.7592L35.0937 39.9952ZM18.3767 33.9848C14.8834 33.9848 12 36.9592 12 40.4616C12 44.0256 14.8834 47 18.3767 47C21.8699 47 24.7533 44.0256 24.7533 40.4616C24.7621 36.9592 21.8786 33.9848 18.3767 33.9848ZM18.3767 43.0048C17.0264 43.0048 15.9549 41.8608 15.9549 40.4616C15.9549 39.0976 17.0264 37.98 18.3767 37.98C19.7269 37.98 20.7984 39.0976 20.7984 40.4616C20.8071 41.852 19.7269 43.0048 18.3767 43.0048ZM57.9521 30.544C59.3024 30.544 60.4087 29.4616 60.4087 28.0976C60.4087 26.7336 59.3024 25.616 57.9521 25.616C56.6019 25.616 55.5304 26.7336 55.5304 28.0976C55.5217 29.4616 56.6019 30.544 57.9521 30.544ZM59.6683 34.2928H56.297C55.9921 34.2928 55.7133 34.5392 55.7133 34.8824V46.1024C55.7133 46.4104 55.9921 46.692 56.297 46.692H59.6683C59.9731 46.692 60.2519 46.4104 60.2519 46.1024V34.8824C60.2519 34.548 59.9731 34.2928 59.6683 34.2928ZM49.8506 39.9952L53.9014 35.252C54.2411 34.8208 53.9623 34.2928 53.4397 34.2928H50.0074C49.6415 34.2928 49.4238 34.4776 49.2408 34.6624L45.5298 39.0008V25.5896C45.5298 25.2816 45.251 25 44.9461 25H41.54C41.2351 25 40.9564 25.2816 40.9564 25.5896V46.1024C40.9564 46.4104 41.2351 46.692 41.54 46.692H44.9461C45.251 46.692 45.5298 46.4104 45.5298 46.1024V41.0512L49.7025 46.4456C49.7983 46.5688 50.0423 46.692 50.1642 46.692H54.3021C54.8248 46.692 55.1035 46.164 54.7638 45.7592L49.8506 39.9952Z" fill="#081027" />
              </svg>

            ) : (
                <img src={okkiFaces[this.state.currentImageIndex]} />
              )} */}
          </div>
        </Link>
        <div
          id="navbarMain"
          className={classnames("navbarHideShow", {
            "navbarHideShow-hidden": !this.state.visible,
          })}
        >
          <div
            className={this.state.isFocused ? "navbarOverlay navbarOverlay-active" : "navbarOverlay"}
          ></div>
          <header
            className="navbar-home"
            className={
              this.state.isFocused ? "navbar-home navbar-homeFocused" : "navbar-home"
            }
          >
            <div
              className={
                this.state.isFocused
                  ? "navbar-home__section navbar-home__sectionReduced"
                  : "navbar-home__section"
              }
            >

            </div>
            <div
              className="navbar-home__section navbar-home__section2"
              className={
                this.state.isFocused
                  ? "navbar-home__section navbar-home__sectionExp navbar-home__section2"
                  : "navbar-home__section navbar-home__section2"
              }
            >
              <input
                type="text"
                placeholder="Buscar..."
                style={{ backgroundImage: `url(${searchIcon})` }}
                onFocus={this.onFocusChange}
                onBlur={this.onFocusChange}
                className="navbar-home__search"
              />
              {/* <div className="navbar-home__searchTags">
              <a className="itemTag">Comprar</a>
              <a className="itemTag">Comer</a>
              <a className="itemTag">Contratar</a>
            </div> */}
              <div className="navbar-home__sectionDropdown"
                className={
                  this.state.isFocused
                    ? "navbar-home__sectionDropdown navbar-home__sectionDropdownActive"
                    : "navbar-home__sectionDropdown"
                }>
                <div className="navbar-home__sectionDropdown-searched">
                  <p className="navbar-home__sectionDropdown-title">Lo más buscado</p>
                  <ul>
                    <li className="itemTag">Café</li>
                    <li className="itemTag">Pizza</li>
                    <li className="itemTag">Hamburguesa</li>
                    <li className="itemTag">Mexicana</li>
                    <li className="itemTag">Sushi</li>
                  </ul>
                </div>
                <div className="navbar-home__sectionDropdown-new">
                  <p className="navbar-home__sectionDropdown-title">Nuevo en Okki</p>
                  <ul>
                    <li>
                      <div className="navbar-home__sectionDropdown-profile">
                        <img className="homeGrid-item__icon" src={itemProfile} />
                        <p className="navbar-home__sectionDropdown-profileName">Lagom Startup Studio</p>
                      </div>
                      <div>
                        <p className="itemTagAlt">$$$</p>
                      </div>
                    </li>
                    <li>
                      <div className="navbar-home__sectionDropdown-profile">
                        <img className="homeGrid-item__icon" src={itemProfile} />
                        <p className="navbar-home__sectionDropdown-profileName">Lagom Startup Studio</p>
                      </div>
                      <div>
                        <p className="itemTagAlt">$$$</p>
                      </div>
                    </li>
                  </ul>
                </div>

                {/* <div className="navbar-home__sectionDropdown-suggest">
                <p className="navbar-home__sectionDropdown-title">Sugerido</p>
                <ul>
                  <li>
                    <p>Maestro de salsa</p>
                  </li>
                  <li>
                    <p>Maestro de fitness</p>
                  </li>
                  <li>
                    <p>Maestro de café</p>
                  </li>
                  <li>
                    <p>Maestro tequilero</p>
                  </li>
                </ul>
              </div> */}
              </div>
            </div>
            <div
              className={
                this.state.isFocused
                  ? "navbar-home__section navbar-home__sectionReduced navbar-home__actions"
                  : "navbar-home__section navbar-home__actions"
              }
            >
              <Select
                classNamePrefix="inputSelect"
                className="inputSelect"
                options={options}
                placeholder="Ciudad"
                components={{ DropdownIndicator }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 6,
                  colors: {
                    ...theme.colors,
                    primary50: "#ccc",
                    primary25: "#F6F6F6",
                    primary: "black",
                  },
                })}
              />

              <Link className="button" to="/vender">
                Vender
      </Link>
            </div>
            <div className="navbar-iconWrapper">
              <a href="#" className="navbar-iconMenu" onClick={openNav}>
                <div></div>
                <div></div>
              </a>
            </div>
          </header>
          <div id="myNav" className="overlay" ref={this.setWrapperRef}>
            <a className="closebtn" onClick={closeNav}>


              <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="42" height="42" rx="21" fill="black" fill-opacity="0.1" />
                <path d="M25.2427 16.7573L16.7574 25.2426" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M25.2426 25.2426L16.7573 16.7573" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </svg>

            </a>
            {localStorage.token ?
              <div
                id="overlayContent"
                className="overlay-content"
                style={{ ...styleBuy, ...styleHire, ...styleEat }}
              >
                <div>
                  <ul>
                    <li>
                      <a
                        onMouseEnter={this.onMouseEnterBuy}
                        onMouseLeave={this.onMouseLeaveBuy}
                        id="buyBtn"
                        className="overlay-content-buy"
                      >
                        Comprar
         </a>
                    </li>
                    <li>
                      <a
                        onMouseEnter={this.onMouseEnterEat}
                        onMouseLeave={this.onMouseLeaveEat}
                        className="overlay-content-eat"
                      >
                        Comer
         </a>
                    </li>
                    <li>
                      <a
                        onMouseEnter={this.onMouseEnterHire}
                        onMouseLeave={this.onMouseLeaveHire}
                        className="overlay-content-hire"
                      >
                        Contratar
         </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="overlay-content__menuMin">
                    <li className="overlay-content-title">
                      <Link to="/vender" className="overlay-content-title">
                        Vender
         </Link>
                    </li>
                    <li className="overlay-content-title">
                      <Link to="/login" className="enter overlay-content-title">
                        Entrar
         </Link>
                    </li>
                  </ul>
                </div>
              </div>
              :
              <div
                id="overlayContent"
                className="overlay-contentAlt2"
                style={{ ...styleBuy, ...styleHire, ...styleEat }}
              >
                <div>
                  <ul>
                    <li>
                      <a
                        className="overlay-contentAlt2-buy"
                      >
                        Catálogo
         </a>
                    </li>
                    <li>
                      <a
                        className="overlay-contentAlt2-eat"
                        onClick={()=>{ this.showSettings() }}
                      >
                        Configuración
         </a>
                    </li>
                    <li>
                      <a
                        className="overlay-contentAlt2-hire"
                        onClick={logOut}
                      >
                        Salir
         </a>
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="overlay-contentAlt2__menuMin">
                    <li className="overlay-contentAlt2-title">
                      <a className="overlay-contentAlt2-title">
                        Comprar
         </a>
                    </li>
                    <li className="overlay-contentAlt2-title">
                      <a className="overlay-contentAlt2-title">
                        Comer
                   </a>
                    </li>
                    <li className="overlay-contentAlt2-title">
                      <a className="overlay-contentAlt2-title">
                        Contratar
                   </a>
                    </li>
                  </ul>
                </div>
              </div>}
          </div>
        </div>
      </>

    );
  }
}
