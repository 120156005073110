import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import venderThumb from "../images/vender-2.svg";
import Fade from "react-reveal/Fade";
import profileThumb from "../images/profileThumb.png";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import NavbarMin from "./NavbarMin";

import Select, { components } from "react-select";
import { registerUser, loggedIn } from "./API Functions";

const options = [
  { value: null, label: "-" },
  { value: true, label: "Si" },
  { value: false, label: "No" },
];

const DropdownIndicator = (
  props: ElementConfig<typeof components.DropdownIndicator>
) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 8L10 12L14 8"
          stroke="#081027"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
};

const Vender2 = (props) => {
  const [Loading, setLoading] = useState(false);
  const [tipo_id, setTipoID] = useState("");
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [nombre_negocio, setNombreNegocio] = useState("");
  const [url_negocio, setUrlNegocio] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [envio_id, setEnvioID] = useState(options[0]);
  const [whatsapp_option, setWhatsappOption] = useState(options[0]);

  const [nombreError, setNombreError] = useState("");
  const [postEmailError, setPostEmailError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [nombre_negocioError, setNombre_negocioError] = useState("");
  const [url_negocioError, setUrl_negocioError] = useState("");
  const [envio_idError, setEnvio_idError] = useState("");
  const [whatsappError, setWhatsappError] = useState("");

  const [Regresar, setRegresar] = useState(false);

  useEffect(() => {
    const tipo_id =
      props.location.state == undefined
        ? ""
        : props.location.state.tipo_id
          ? props.location.state.tipo_id
          : "";
    tipo_id ? setTipoID(tipo_id) : setRegresar(true);
    console.log(tipo_id);
  }, []);

  function validateFields() {
    let nombreError = "";
    let emailError = "";
    let nombre_negocioError = "";
    let url_negocioError = "";
    let envio_idError = "";
    let whatsappError = "";

    if (nombre == "") {
      nombreError = "El campo no puede ser vacio";
    }
    if (email == "") {
      emailError = "El campo no puede ser vacio";
    }
    if (nombre_negocio == "") {
      nombre_negocioError = "El campo no puede ser vacio";
    }
    if (url_negocio == "") {
      url_negocioError = "El campo no puede ser vacio";
    }
    /*if(whatsapp == ""){
              whatsappError = "El campo no puede ser vacio"
          }*/
    if (whatsapp_option.value == null) {
      whatsappError = "Selecciona una opcion";
    }
    if (envio_id.value == null) {
      envio_idError = "Selecciona una opcion";
    }

    if (
      nombreError ||
      emailError ||
      nombre_negocioError ||
      url_negocioError ||
      envio_idError ||
      whatsappError
    ) {
      setNombreError(nombreError);
      setEmailError(emailError);
      setNombre_negocioError(nombre_negocioError);
      setUrl_negocioError(url_negocioError);
      setEnvio_idError(envio_idError);
      setWhatsappError(whatsappError);
      return false;
    } else {
      setNombreError(nombreError);
      setEmailError(emailError);
      setNombre_negocioError(nombre_negocioError);
      setUrl_negocioError(url_negocioError);
      setEnvio_idError(envio_idError);
      setWhatsappError(whatsappError);
      return true;
    }
  }

  function handleWhatsappOption(selectedOption) {
    setWhatsappOption(selectedOption);
  }

  function handleEnvioOption(selectedOption) {
    setEnvioID(selectedOption);
  }
  function handleSubmit() {
    setLoading(true);
    const isValid = validateFields();
    const data = {
      nombre: nombre,
      correo: email,
      apellidos: nombre,
      nombre_negocio: nombre_negocio,
      url_negocio: url_negocio,
      whatsapp: whatsapp,
      envio_id: envio_id.value ? 1 : 2,
      tipo_id: tipo_id,
      password: "",
      role_name: "user",
      landing: false
    };
    if (isValid) {
      registerUser(data).then((res) => {
        if (res) {
          window.location.replace("/vender3");
        } else {
          setPostEmailError("Este correo ya existe");
        }
      });
    }
    setTimeout(() => {
      setLoading(false);
      setPostEmailError("");
    }, 2000);
  }

  return (
    <div className="signupContainer">
      {Regresar ? (
        <Redirect
          to={{
            pathname: "/vender",
          }}
        />
      ) : null}
      <NavbarMin />
      <div className="planContainer">
        <div className="planContainer-cover">
          <Fade>
            <img src={venderThumb} />
          </Fade>
        </div>
        <div className="planContainer-form">
          <div className="planContainer-form__optionTotal planContainer-form__optionTotalShadow">
            <Link to="/vender" className="button buttonAlt aButton">
              Cancelar
      </Link>
            <button className="button u-pull-right aButton" onClick={handleSubmit}>
              {Loading && <i className="" style={{ marginRight: "5px" }} />}
              {Loading && "Enviando..."}
              {!Loading && (
                <span>
                  Enviar solicitud{" "}
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 14L15 10L11 6"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M15 10L5 10"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              )}
            </button>
          </div>
          <div className="container">
            <div className="row">
              <div className="twelve columns">
                <Fade right delay={100}>
                  <div className="planContainer-form__container planContainer-form__containerLarge">
                    <p className="planContainer-form__title">Más detalles</p>
                    <p className="planContainer-form__subtitleAlt">
                      Cuéntanos sobre ti y tu negocio.
          </p>
                    <hr className="hrMin" />
                    <div className="registerForm">
                      <div>
                        <label>Nombre completo</label>
                        <div className="fieldError">{nombreError}</div>
                        <input
                          type="text"
                          class="input registerInput"
                          value={nombre}
                          placeholder="Nombre completo"
                          onChange={(e) => setNombre(e.target.value)}
                        />
                      </div>
                      <div>
                        <label>Correo electrónico</label>
                        <div className="fieldError">{emailError}</div>
                        <div className="fieldError">{postEmailError}</div>
                        <input
                          type="email"
                          class="input registerInput"
                          value={email}
                          placeholder="Correo electrónico"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div>
                        <label>Nombre de tu negocio</label>
                        <div className="fieldError">{nombre_negocioError}</div>
                        <input
                          type="text"
                          class="input registerInput"
                          value={nombre_negocio}
                          placeholder="Nombre de tu negocio"
                          onChange={(e) => setNombreNegocio(e.target.value)}
                        />
                      </div>
                      <div>
                        <label>URL de tu negocio</label>
                        <div className="fieldError">{url_negocioError}</div>
                        <input
                          type="text"
                          class="input registerInput"
                          value={url_negocio}
                          placeholder="URL (website, Facebook, etc)"
                          onChange={(e) => setUrlNegocio(e.target.value)}
                        />
                      </div>
                      <div className="fieldError">{whatsappError}</div>
                      <div className="registerForm-row">
                        <label>¿Tu negocio tiene Whatsapp?</label>
                        <Select
                          classNamePrefix="inputSelect"
                          className="inputSelect settings-form__input inputSelectMini"
                          options={options}
                          value={whatsapp_option}
                          onChange={handleWhatsappOption}
                          components={{ DropdownIndicator }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 6,
                            colors: {
                              ...theme.colors,
                              primary50: "#ccc",
                              primary25: "#F6F6F6",
                              primary: "black",
                            },
                          })}
                        />
                      </div>
                      {whatsapp_option.value ? (
                        <div style={{ position: "relative" }}>
                          <label>Whatsapp</label>
                          <div>
                            <p className="phoneInput-placeholder">+52</p>
                            <input
                              className="settings-form__input phoneInput"
                              type="tel"
                              style={{ marginBottom: 0 }}
                              placeholder="81 1234 5678"
                              onChange={(e) => setWhatsapp(e.target.value)}
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="fieldError">{envio_idError}</div>

                      <div className="registerForm-row">
                        <label>¿Entregas a domicilio?</label>
                        <Select
                          classNamePrefix="inputSelect"
                          className="inputSelect settings-form__input inputSelectMini"
                          value={envio_id}
                          options={options}
                          onChange={handleEnvioOption}
                          components={{ DropdownIndicator }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 6,
                            colors: {
                              ...theme.colors,
                              primary50: "#ccc",
                              primary25: "#F6F6F6",
                              primary: "black",
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vender2;
