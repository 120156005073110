import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import venderThumb from "../images/vender-1.svg";

import profileThumb from "../images/profileThumb.png";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import { postRecoverPassword, loggedIn } from "./API Functions";

function Recover() {
    const [email,setEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const [emailError,setEmailError] = useState("");

    useEffect(()=>{
    },[])

    function validateFields(){
        let emailError = ""

        if(email == ""){
            emailError = "El correo no puede ser vacio."
        }
        
        if(emailError){
            setEmailError(emailError);
            return false
        }else{
            setEmailError(emailError);
            return true
        }
     }
 function Submit(){
    setLoading(true);
    const data = {
        email: email,
    };
    
    const isValid = validateFields()
    if(isValid){
        postRecoverPassword(data).then(res=>{
            if(!res){
                setEmailError("No pudimos encontrar una cuenta con este correo.");
                setTimeout(() => {
                    setLoading(false);
                    setEmailError("");
                }, 2000);
            }else{
                window.location.replace('/login')
            }
        })
    }
 }
 return (
  <div>
   <div className="loginContainer">
    <div className="loginContainer-form">
     <svg
      width="60"
      height="18"
      viewBox="0 0 60 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="loginContainer-form__logo"
     >
      <path
       d="M8.6743 18C6.887 18 5.33604 17.685 4.02142 17.0549C2.71418 16.4176 1.71713 15.5531 1.03028 14.4615C0.343427 13.37 0 12.1502 0 10.8022C0 9.44689 0.343427 8.24176 1.03028 7.18681C1.71713 6.13187 2.71418 5.30403 4.02142 4.7033C5.32866 4.10256 6.87962 3.8022 8.6743 3.8022C10.469 3.8022 12.0162 4.10256 13.3161 4.7033C14.616 5.2967 15.6056 6.12088 16.2851 7.17582C16.9719 8.23077 17.3154 9.43956 17.3154 10.8022C17.3154 12.1575 16.9719 13.381 16.2851 14.4725C15.6056 15.5641 14.6123 16.4249 13.305 17.0549C12.0052 17.685 10.4616 18 8.6743 18ZM8.6743 13.8791C9.30945 13.8791 9.85598 13.7546 10.3139 13.5055C10.7792 13.2564 11.1337 12.9084 11.3774 12.4615C11.6285 12.0073 11.7541 11.4835 11.7541 10.8901C11.7541 10.2967 11.6285 9.78022 11.3774 9.34066C11.1337 8.89377 10.7792 8.54945 10.3139 8.30769C9.85598 8.06593 9.30945 7.94506 8.6743 7.94506C8.03176 7.94506 7.47415 8.06593 7.00148 8.30769C6.53619 8.54945 6.17799 8.89377 5.92688 9.34066C5.68316 9.78022 5.5613 10.2967 5.5613 10.8901C5.5613 11.4835 5.68316 12.0073 5.92688 12.4615C6.17799 12.9084 6.53619 13.2564 7.00148 13.5055C7.47415 13.7546 8.03176 13.8791 8.6743 13.8791Z"
       fill="white"
      />
      <path
       d="M29.4793 17.5495L24.76 10.6374V17.5495H19.2097V1.34066H24.76V9.2967L29.1691 4.38462H35.4284L30.2548 9.91209L35.8383 17.5495H29.4793Z"
       fill="white"
      />
      <path
       d="M46.983 17.5495L42.2637 10.6374V17.5495H36.7134V1.34066H42.2637V9.2967L46.6728 4.38462H52.9321L47.7585 9.91209L53.342 17.5495H46.983Z"
       fill="white"
      />
      <path
       d="M57.031 4.45055C56.4476 4.45055 55.9232 4.35531 55.4579 4.16484C55 3.97436 54.6381 3.71062 54.3722 3.37363C54.1137 3.0293 53.9845 2.63736 53.9845 2.1978C53.9845 1.78022 54.1137 1.40659 54.3722 1.07692C54.6381 0.739927 55 0.476191 55.4579 0.285714C55.9232 0.0952381 56.4476 0 57.031 0C57.5997 0 58.1093 0.0952381 58.5598 0.285714C59.0177 0.468865 59.3722 0.728938 59.6233 1.06593C59.8744 1.40293 60 1.78022 60 2.1978C60 2.63736 59.8744 3.0293 59.6233 3.37363C59.3722 3.71062 59.0214 3.97436 58.5709 4.16484C58.1204 4.35531 57.6071 4.45055 57.031 4.45055ZM54.2171 5.28571H59.7674V17.5495H54.2171V5.28571Z"
       fill="white"
      />
     </svg>
     <div className="loginContainer-card">
      <h3 className="loginContainer-card__title">
       Recuperar
       <br /> Contraseña
      </h3>
      <div className="loginContainer-card__input">
      <div className="fieldError">{emailError}</div>
       <input type="email" className="input" placeholder="Correo" value={email} onChange={ e => setEmail(e.target.value) } />
      </div>
      <div className="loginContainer-card__row">
       <div className="loginContainer-card__row"></div>
       <a className="button u-pull-right aButton" onClick={Submit}>
        Enviar instrucciones →
       </a>
      </div>
     </div>
     <Link className="loginContainer-form__link" to="/login">
      Iniciar sesión
     </Link>
    </div>
   </div>
  </div>
 );
}

export default Recover;
