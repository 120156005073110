import React, { useEffect } from "react";
import profileThumb2 from "../images/profileThumb2.svg";
import itemThumb2 from "../images/itemThumb2.png";
import itemProfile from "../images/itemProfile.svg";
import Navbar from "./Navbar";
import { loggedIn } from "./API Functions";

function PerfilServicios() {
    useEffect(() => {
        loggedIn();
    }, [])
    return (
        <div>
            <Navbar />
            <div className="settings-headerAlt profile-cover container">
                <div className="row rowProfile">
                    <div className="rowProfile-top">
                        <div className="rowProfile-logo">
                            <div className="rowProfile-thumbPro">
                                <img className="rowProfile-thumb" src={profileThumb2} />
                            </div>

                            <p className="rowProfile-title">Master Card</p>
                            <a href="#" className="itemTagBtn colorBlack rowProfile-share">
                                Editar
       </a>
                        </div>
                        <div className="rowProfile-actions">
                            <a>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M5.7069 1.76386C5.01564 1.0763 3.9469 1.07872 3.2583 1.77114L2.56215 2.47114C1.72741 3.3105 1.25 4.50017 1.25 5.6V6C1.25 6.00753 1.25011 6.01506 1.25034 6.02259C1.46096 13.0116 6.94362 18.5372 13.8113 18.7496C13.819 18.7499 13.8268 18.75 13.8345 18.75H14.3318C15.5658 18.75 16.7427 18.1393 17.5487 17.3289L18.0893 16.7853C19.0084 16.1523 18.8775 14.9073 18.2448 14.2711L16.1564 12.1711C15.4654 11.4763 14.3915 11.4763 13.7005 12.1711L12.6643 13.2131C11.8705 12.8653 11.1073 12.4483 10.418 11.9092C10.3942 11.8906 10.3693 11.8734 10.3434 11.8578C9.45651 11.3227 8.63459 10.5034 7.98582 9.5715C7.97039 9.54934 7.95378 9.52803 7.93606 9.50767C7.4033 8.89543 6.99394 8.21215 6.65367 7.38418L7.80262 6.22886C8.49137 5.53631 8.49137 4.46369 7.80262 3.77114L7.78957 3.75832L5.7069 1.76386ZM4.48624 2.75C4.44415 2.75 4.38496 2.76544 4.32189 2.82886L3.62574 3.52886C3.06819 4.0895 2.75 4.89983 2.75 5.6V5.98784C2.94252 12.1896 7.79446 17.0568 13.8463 17.25H14.3318C15.0867 17.25 15.8988 16.8607 16.4851 16.2711L17.0818 15.6711C17.1251 15.6276 17.1736 15.5895 17.2262 15.5578C17.2368 15.5514 17.2387 15.5476 17.2401 15.5452C17.2426 15.5406 17.2486 15.5271 17.2498 15.502C17.2525 15.4454 17.2287 15.3765 17.1813 15.3289L15.0928 13.2289C15.0297 13.1654 14.9705 13.15 14.9285 13.15C14.8864 13.15 14.8272 13.1654 14.7641 13.2289L13.3718 14.6289C13.1593 14.8425 12.8397 14.9083 12.5601 14.7958C11.5404 14.3857 10.4898 13.8622 9.5295 13.1185C8.45492 12.461 7.50942 11.5042 6.77784 10.4615C6.02384 9.5867 5.49174 8.61079 5.07379 7.45506C4.97511 7.18219 5.04268 6.87688 5.2473 6.67113L6.73904 5.17114C6.8022 5.10763 6.81918 5.04618 6.81918 5C6.81918 4.95499 6.80305 4.89547 6.74374 4.83367L4.66365 2.84168L4.6506 2.82886C4.58753 2.76544 4.52834 2.75 4.48624 2.75Z"
                                        fill="#66666A"
                                    />
                                </svg>
                            </a>
                            <a>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M10 2.75C9.33904 2.75 8.53236 3.31232 7.84582 4.68541C7.26248 5.85209 6.85741 7.4479 6.76842 9.25H13.2316C13.1426 7.4479 12.7375 5.85209 12.1542 4.68541C11.4676 3.31232 10.661 2.75 10 2.75ZM13.2316 10.75H6.76842C6.85741 12.5521 7.26248 14.1479 7.84582 15.3146C8.53236 16.6877 9.33904 17.25 10 17.25C10.661 17.25 11.4676 16.6877 12.1542 15.3146C12.7375 14.1479 13.1426 12.5521 13.2316 10.75ZM5.26678 9.25C5.35672 7.24793 5.80386 5.41523 6.50418 4.01459C6.59209 3.83877 6.6857 3.66679 6.78487 3.50009C4.60632 4.57976 3.04809 6.72244 2.78832 9.25H5.26678ZM2.78832 10.75H5.26678C5.35672 12.7521 5.80386 14.5848 6.50418 15.9854C6.59209 16.1612 6.6857 16.3332 6.78488 16.4999C4.60632 15.4202 3.04809 13.2776 2.78832 10.75ZM14.7332 10.75H17.2117C16.9519 13.2776 15.3937 15.4202 13.2151 16.4999C13.3143 16.3332 13.4079 16.1612 13.4958 15.9854C14.1961 14.5848 14.6433 12.7521 14.7332 10.75ZM17.2117 9.25H14.7332C14.6433 7.24793 14.1961 5.41523 13.4958 4.01459C13.4079 3.83877 13.3143 3.66679 13.2151 3.50009C15.3937 4.57976 16.9519 6.72244 17.2117 9.25ZM18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75C14.8325 18.75 18.75 14.8325 18.75 10Z"
                                        fill="#66666A"
                                    />
                                </svg>
                            </a>
                            <a>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M15.4161 3.37798C12.4307 0.539108 7.48729 0.540675 4.50407 3.38268C1.49864 6.24584 1.49864 11.0201 4.50407 13.8833L9.50066 18.5482C9.79097 18.8193 10.2422 18.817 10.5298 18.543L15.4211 13.8833C18.5189 10.9321 18.5354 6.24845 15.4161 3.37798ZM14.3864 4.46873C11.9809 2.17709 7.94422 2.17709 5.53871 4.46873C3.15465 6.73994 3.15376 10.5224 5.53605 12.7947L10.0073 16.9691L14.3864 12.7972C16.877 10.4246 16.8621 6.74318 14.3964 4.47803L14.3864 4.46873Z"
                                        fill="#66666A"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M10 6.75C8.75736 6.75 7.75 7.75736 7.75 9C7.75 10.2426 8.75736 11.25 10 11.25C11.2426 11.25 12.25 10.2426 12.25 9C12.25 7.75736 11.2426 6.75 10 6.75ZM6.25 9C6.25 6.92893 7.92893 5.25 10 5.25C12.0711 5.25 13.75 6.92893 13.75 9C13.75 11.0711 12.0711 12.75 10 12.75C7.92893 12.75 6.25 11.0711 6.25 9Z"
                                        fill="#66666A"
                                    />
                                </svg>
                            </a>
                            <a>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M6 2.75C4.21421 2.75 2.75 4.21421 2.75 6V14C2.75 15.7858 4.21421 17.25 6 17.25H14C15.7858 17.25 17.25 15.7858 17.25 14V6C17.25 4.21421 15.7858 2.75 14 2.75H6ZM1.25 6C1.25 3.38579 3.38579 1.25 6 1.25H14C16.6142 1.25 18.75 3.38579 18.75 6V14C18.75 16.6142 16.6142 18.75 14 18.75H6C3.38579 18.75 1.25 16.6142 1.25 14V6Z"
                                        fill="#66666A"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M10.25 8C10.25 6.48579 11.4858 5.25 13 5.25H14C14.4142 5.25 14.75 5.58579 14.75 6C14.75 6.41421 14.4142 6.75 14 6.75H13C12.3142 6.75 11.75 7.31421 11.75 8V18C11.75 18.4142 11.4142 18.75 11 18.75C10.5858 18.75 10.25 18.4142 10.25 18V8Z"
                                        fill="#66666A"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M8.25 10C8.25 9.58579 8.58579 9.25 9 9.25H13C13.4142 9.25 13.75 9.58579 13.75 10C13.75 10.4142 13.4142 10.75 13 10.75H9C8.58579 10.75 8.25 10.4142 8.25 10Z"
                                        fill="#66666A"
                                    />
                                </svg>
                            </a>
                            <a>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M1.25 6C1.25 3.38579 3.38579 1.25 6 1.25H14C16.6142 1.25 18.75 3.38579 18.75 6V14C18.75 16.6142 16.6142 18.75 14 18.75H6C3.38579 18.75 1.25 16.6142 1.25 14V6ZM6 2.75C4.21421 2.75 2.75 4.21421 2.75 6V14C2.75 15.7858 4.21421 17.25 6 17.25H14C15.7858 17.25 17.25 15.7858 17.25 14V6C17.25 4.21421 15.7858 2.75 14 2.75H6Z"
                                        fill="#66666A"
                                    />
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M10 7.75C8.75736 7.75 7.75 8.75736 7.75 10C7.75 11.2426 8.75736 12.25 10 12.25C11.2426 12.25 12.25 11.2426 12.25 10C12.25 8.75736 11.2426 7.75 10 7.75ZM6.25 10C6.25 7.92893 7.92893 6.25 10 6.25C12.0711 6.25 13.75 7.92893 13.75 10C13.75 12.0711 12.0711 13.75 10 13.75C7.92893 13.75 6.25 12.0711 6.25 10Z"
                                        fill="#66666A"
                                    />
                                    <path
                                        d="M14 7C14.5523 7 15 6.55228 15 6C15 5.44772 14.5523 5 14 5C13.4477 5 13 5.44772 13 6C13 6.55228 13.4477 7 14 7Z"
                                        fill="#66666A"
                                    />
                                </svg>
                            </a>
                        </div>
                    </div>
                    <p className="rowProfile-about">
                        Usa tu tarjeta de crédito Mastercard para tus compras diarias en tiendas,
                        en línea e incluso en lugares donde no aceptan efectivo ni cheques como en
                        los hoteles.
     </p>
                    <div className="rowProfile-top rowProfile-bottom">
                        <div style={{ display: "flex" }}>
                            <p className="itemTagAlt" style={{ marginLeft: 0 }}>
                                Mexicana
       </p>
                            <p className="itemTagAlt">Tecnología</p>
                        </div>
                        <div style={{ display: "flex" }}>
                            <p className="itemTagAlt">$$$</p>
                            <p className="itemTagAlt">Pick n Go</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home-gallery__filters home-gallery__filtersProfile container">
                <ul className="home-gallery__filters-list">
                    <li>Filtro 1</li>
                    <li>Filtro 2</li>
                    <li>Filtro 3</li>
                </ul>
            </div>
            <div className="home-gallery container">
                <div className="homeGrid2">
                    <div className="homeGrid2-item">
                        <img src={itemThumb2} />
                        <div className="homeGrid2-item__info">
                            <div>
                                <p>Antigua and Barbuda</p>
                                <p>$9,999</p>
                            </div>
                            <div className="rowProfile-actions">
                                <a>
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M10 8.75C8.20507 8.75 6.75 10.2051 6.75 12C6.75 13.7949 8.20507 15.25 10 15.25C11.7949 15.25 13.25 13.7949 13.25 12C13.25 10.2051 11.7949 8.75 10 8.75ZM5.25 12C5.25 9.37665 7.37665 7.25 10 7.25C12.6234 7.25 14.75 9.37665 14.75 12C14.75 14.6234 12.6234 16.75 10 16.75C7.37665 16.75 5.25 14.6234 5.25 12Z"
                                            fill="#999999"
                                        />
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M10 4.75C6.01421 4.75 2.75 8.01421 2.75 12C2.75 12.4142 2.41421 12.75 2 12.75C1.58579 12.75 1.25 12.4142 1.25 12C1.25 7.18579 5.18579 3.25 10 3.25C14.8142 3.25 18.75 7.18579 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75C17.5858 12.75 17.25 12.4142 17.25 12C17.25 8.01421 13.9858 4.75 10 4.75Z"
                                            fill="#999999"
                                        />
                                    </svg>
                                </a>
                                <a>
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M10 2.75C9.33904 2.75 8.53236 3.31232 7.84582 4.68541C7.26248 5.85209 6.85741 7.4479 6.76842 9.25H13.2316C13.1426 7.4479 12.7375 5.85209 12.1542 4.68541C11.4676 3.31232 10.661 2.75 10 2.75ZM13.2316 10.75H6.76842C6.85741 12.5521 7.26248 14.1479 7.84582 15.3146C8.53236 16.6877 9.33904 17.25 10 17.25C10.661 17.25 11.4676 16.6877 12.1542 15.3146C12.7375 14.1479 13.1426 12.5521 13.2316 10.75ZM5.26678 9.25C5.35672 7.24793 5.80386 5.41523 6.50418 4.01459C6.59209 3.83877 6.6857 3.66679 6.78487 3.50009C4.60632 4.57976 3.04809 6.72244 2.78832 9.25H5.26678ZM2.78832 10.75H5.26678C5.35672 12.7521 5.80386 14.5848 6.50418 15.9854C6.59209 16.1612 6.6857 16.3332 6.78488 16.4999C4.60632 15.4202 3.04809 13.2776 2.78832 10.75ZM14.7332 10.75H17.2117C16.9519 13.2776 15.3937 15.4202 13.2151 16.4999C13.3143 16.3332 13.4079 16.1612 13.4958 15.9854C14.1961 14.5848 14.6433 12.7521 14.7332 10.75ZM17.2117 9.25H14.7332C14.6433 7.24793 14.1961 5.41523 13.4958 4.01459C13.4079 3.83877 13.3143 3.66679 13.2151 3.50009C15.3937 4.57976 16.9519 6.72244 17.2117 9.25ZM18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75C14.8325 18.75 18.75 14.8325 18.75 10Z"
                                            fill="#BBBBBB"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="homeGrid2-item">
                        <img src={itemThumb2} />
                        <div className="homeGrid2-item__info">
                            <div>
                                <p>Antigua and Barbuda</p>
                                <p>$9,999</p>
                            </div>
                            <div className="rowProfile-actions">
                                <a>
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M10 8.75C8.20507 8.75 6.75 10.2051 6.75 12C6.75 13.7949 8.20507 15.25 10 15.25C11.7949 15.25 13.25 13.7949 13.25 12C13.25 10.2051 11.7949 8.75 10 8.75ZM5.25 12C5.25 9.37665 7.37665 7.25 10 7.25C12.6234 7.25 14.75 9.37665 14.75 12C14.75 14.6234 12.6234 16.75 10 16.75C7.37665 16.75 5.25 14.6234 5.25 12Z"
                                            fill="#999999"
                                        />
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M10 4.75C6.01421 4.75 2.75 8.01421 2.75 12C2.75 12.4142 2.41421 12.75 2 12.75C1.58579 12.75 1.25 12.4142 1.25 12C1.25 7.18579 5.18579 3.25 10 3.25C14.8142 3.25 18.75 7.18579 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75C17.5858 12.75 17.25 12.4142 17.25 12C17.25 8.01421 13.9858 4.75 10 4.75Z"
                                            fill="#999999"
                                        />
                                    </svg>
                                </a>
                                <a>
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M10 2.75C9.33904 2.75 8.53236 3.31232 7.84582 4.68541C7.26248 5.85209 6.85741 7.4479 6.76842 9.25H13.2316C13.1426 7.4479 12.7375 5.85209 12.1542 4.68541C11.4676 3.31232 10.661 2.75 10 2.75ZM13.2316 10.75H6.76842C6.85741 12.5521 7.26248 14.1479 7.84582 15.3146C8.53236 16.6877 9.33904 17.25 10 17.25C10.661 17.25 11.4676 16.6877 12.1542 15.3146C12.7375 14.1479 13.1426 12.5521 13.2316 10.75ZM5.26678 9.25C5.35672 7.24793 5.80386 5.41523 6.50418 4.01459C6.59209 3.83877 6.6857 3.66679 6.78487 3.50009C4.60632 4.57976 3.04809 6.72244 2.78832 9.25H5.26678ZM2.78832 10.75H5.26678C5.35672 12.7521 5.80386 14.5848 6.50418 15.9854C6.59209 16.1612 6.6857 16.3332 6.78488 16.4999C4.60632 15.4202 3.04809 13.2776 2.78832 10.75ZM14.7332 10.75H17.2117C16.9519 13.2776 15.3937 15.4202 13.2151 16.4999C13.3143 16.3332 13.4079 16.1612 13.4958 15.9854C14.1961 14.5848 14.6433 12.7521 14.7332 10.75ZM17.2117 9.25H14.7332C14.6433 7.24793 14.1961 5.41523 13.4958 4.01459C13.4079 3.83877 13.3143 3.66679 13.2151 3.50009C15.3937 4.57976 16.9519 6.72244 17.2117 9.25ZM18.75 10C18.75 5.16751 14.8325 1.25 10 1.25C5.16751 1.25 1.25 5.16751 1.25 10C1.25 14.8325 5.16751 18.75 10 18.75C14.8325 18.75 18.75 14.8325 18.75 10Z"
                                            fill="#BBBBBB"
                                        />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PerfilServicios;
