import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import venderThumb from "../images/vender-1.svg";
import { useForm } from "react-hook-form";
import profileThumb from "../images/profileThumb.png";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import { getMailFromToken, newPassword, loggedIn } from "./API Functions";
import queryString from "query-string";
import { setAppElement } from "react-modal";

function CreatePassword() {
    const { register, handleSubmit } = useForm();
    const [passwordShown, setPasswordShown] = useState(false);
    const [toggleEye, setToggleEye] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
        toggle();
    };
    function toggle() {
        setToggleEye(toggleEye ? false : true);
    }

    const [passwordShown2, setPasswordShown2] = useState(false);
    const [toggleEye2, setToggleEye2] = useState(false);
    const togglePasswordVisiblity2 = () => {
        setPasswordShown2(passwordShown2 ? false : true);
        toggle2();
    };
    function toggle2() {
        setToggleEye2(toggleEye2 ? false : true);
    }

    const [token, setToken] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [password_confirm, setPasswordConfirm] = useState("");
    const [loading, setLoading] = useState(false);

    const [passwordError, setPasswordError] = useState("");
    const [password_confirmError, setPasswordConfirmError] = useState("");
    const [resetError, setResetError] = useState("");

    useEffect(() => {
        const token = queryString.parse(window.location.search);
        getMailFromToken(token.token).then(res => {
            if (res) {
                setToken(res.token);
                setEmail(res.email);
                setName(res.nombre);
            }
        });
    }, [])

    function validateFields() {
        let passwordError = ""
        let password_confirmError = ""
        if (password == "") {
            passwordError = "Selecciona una opción"
        }
        if (password_confirm == "") {
            password_confirmError = "El campo no puede ser vacio"
        }
        if (password_confirmError || passwordError) {
            setPasswordError(passwordError);
            setPasswordConfirmError(password_confirmError);
            return false
        } else {
            setPasswordError(passwordError);
            setPasswordConfirmError(password_confirmError);
            return true
        }
    }

    function submit() {
        setLoading(true);
        const isValid = validateFields();
        const data = {
            email: email,
            password: password,
            password_confirmation: password_confirm,
            token: token
        };
        if (isValid) {
            newPassword(data).then(res => {
                if (res) {
                    window.location.replace("/login")
                } else {
                    setResetError("Error al ingresar nuevo password")
                    setTimeout(() => {
                        setResetError("")
                    }, 2000);
                }
            });
        }
        setTimeout(() => {
            setLoading(false);
        }, 1200);
    }

    return (
        <div>
            <div className="loginContainer">
                <div className="loginContainer-form">
                    <svg
                        width="44"
                        height="20"
                        viewBox="0 0 44 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <g clip-path="url(#clip0)">
                            <path
                                d="M20.9942 13.632L24.6767 9.32C24.9856 8.928 24.7322 8.448 24.257 8.448H21.1368C20.8042 8.448 20.6062 8.616 20.4399 8.784L17.0662 12.728V0.536C17.0662 0.256 16.8128 0 16.5356 0H13.4392C13.162 0 12.9086 0.256 12.9086 0.536V19.184C12.9086 19.464 13.162 19.72 13.4392 19.72H16.5356C16.8128 19.72 17.0662 19.464 17.0662 19.184V14.592L20.8596 19.496C20.9467 19.608 21.1685 19.72 21.2793 19.72H25.041C25.5162 19.72 25.7696 19.24 25.4608 18.872L20.9942 13.632ZM5.79698 8.168C2.62131 8.168 0 10.872 0 14.056C0 17.296 2.62131 20 5.79698 20C8.97264 20 11.594 17.296 11.594 14.056C11.6019 10.872 8.98056 8.168 5.79698 8.168ZM5.79698 16.368C4.56947 16.368 3.59539 15.328 3.59539 14.056C3.59539 12.816 4.56947 11.8 5.79698 11.8C7.02448 11.8 7.99856 12.816 7.99856 14.056C8.00648 15.32 7.02448 16.368 5.79698 16.368ZM41.7747 5.04C43.0022 5.04 44.0079 4.056 44.0079 2.816C44.0079 1.576 43.0022 0.56 41.7747 0.56C40.5472 0.56 39.5731 1.576 39.5731 2.816C39.5652 4.056 40.5472 5.04 41.7747 5.04ZM43.3348 8.448H40.27C39.9928 8.448 39.7394 8.672 39.7394 8.984V19.184C39.7394 19.464 39.9928 19.72 40.27 19.72H43.3348C43.6119 19.72 43.8654 19.464 43.8654 19.184V8.984C43.8654 8.68 43.6119 8.448 43.3348 8.448ZM34.4096 13.632L38.0922 9.32C38.401 8.928 38.1476 8.448 37.6724 8.448H34.5522C34.2196 8.448 34.0216 8.616 33.8553 8.784L30.4816 12.728V0.536C30.4816 0.256 30.2282 0 29.951 0H26.8546C26.5774 0 26.324 0.256 26.324 0.536V19.184C26.324 19.464 26.5774 19.72 26.8546 19.72H29.951C30.2282 19.72 30.4816 19.464 30.4816 19.184V14.592L34.275 19.496C34.3621 19.608 34.5839 19.72 34.6947 19.72H38.4564C38.9316 19.72 39.185 19.24 38.8762 18.872L34.4096 13.632Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="44" height="20" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    <div className="loginContainer-card">
                        <h3 className="loginContainer-card__title">
                            Hola,
       <br />
                            {name}
                        </h3>
                        <div className="fieldError">{resetError}</div>

                        <div className="loginContainer-card__input">
                            <div className="fieldError">{passwordError}</div>
                            <div className="pass-wrapper">
                                <input
                                    placeholder="Password"
                                    name="password"
                                    placeholder="Contraseña"
                                    type={passwordShown ? "text" : "password"}
                                    ref={register({ required: "This is required." })}
                                    onChange={e => setPassword(e.target.value)}
                                />
                                {toggleEye ? (
                                    <svg onClick={togglePasswordVisiblity && toggle} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14 12C14 14.2 12.2 16 10 16C7.8 16 6 14.2 6 12" stroke="#ACACB5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M2 12H18" stroke="#ACACB5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>

                                ) : (
                                        <svg
                                            onClick={togglePasswordVisiblity && toggle}
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http:º//www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10 16C12.2091 16 14 14.2091 14 12C14 9.79086 12.2091 8 10 8C7.79086 8 6 9.79086 6 12C6 14.2091 7.79086 16 10 16Z"
                                                stroke="#3059C8"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M2 12C2 7.6 5.6 4 10 4C14.4 4 18 7.6 18 12"
                                                stroke="#3059C8"
                                                stroke-width="1.5"
                                                stroke-miterlimit="10"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    )}
                            </div>
                        </div>
                        <div className="loginContainer-card__input"></div>
                        <div className="fieldError">{password_confirmError}</div>
                        <div className="pass-wrapper">
                            <input
                                placeholder="Password"
                                name="password"
                                placeholder="Confirmar contraseña"
                                type={passwordShown2 ? "text" : "password"}
                                ref={register({ required: "This is required." })}
                                onChange={e => setPasswordConfirm(e.target.value)}
                            />
                            {toggleEye2 ? (
                                <svg onClick={togglePasswordVisiblity2 && toggle2} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 12C14 14.2 12.2 16 10 16C7.8 16 6 14.2 6 12" stroke="#ACACB5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M2 12H18" stroke="#ACACB5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                            ) : (
                                    <svg
                                        onClick={togglePasswordVisiblity2 && toggle2}
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M10 16C12.2091 16 14 14.2091 14 12C14 9.79086 12.2091 8 10 8C7.79086 8 6 9.79086 6 12C6 14.2091 7.79086 16 10 16Z"
                                            stroke="#3059C8"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M2 12C2 7.6 5.6 4 10 4C14.4 4 18 7.6 18 12"
                                            stroke="#3059C8"
                                            stroke-width="1.5"
                                            stroke-miterlimit="10"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                )}
                        </div>
                        <div className="loginContainer-card__row">
                            <div className="loginContainer-card__row"></div>
                            <a className="button u-pull-right aButton" onClick={submit}>
                                {loading && (
                                    <i className="" style={{ marginRight: "5px" }} />
                                )}
                                {loading && (
                                    'Guardando...'
                                )}
                                {!loading && (
                                    'Crear cuenta'
                                )}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreatePassword;
