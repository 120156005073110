import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import profileThumb from "../images/profileThumb.png";
import Navbar from "./Navbar";
import { getNegocios, putNegocios, deleteAccount } from "./API Functions";
import Modal from "react-modal";
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        padding: "50px 40px",
        border: "none",
        borderRadius: 6,
    },
};

function Settings4() {
    var subtitle;

    const [Loading, setLoading] = useState(false);
    const [modalIsOpen, setIsOpen] = React.useState(false);

    const [negocio, setNegocio] = useState([]);
    const [nuevoNegocio, setNewNegocio] = useState(false);

    const [presona_contacto, setPersonaContacto] = useState("");
    const [email, setEmail] = useState("");

    const [presona_contactoError, setPersonaContactoError] = useState("");

    useEffect(() => {
        getNegocios(1).then(res => {
            if (res) {
                const negocio = res;
                res.presona_contacto == "" ? setNewNegocio(true) : setNewNegocio(false);

                setNegocio(negocio);
                setEmail(negocio.user.correo);

            } else {
                window.location.replace("/login");
            }
        })
    }, [])

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function validateFields() {
        let presona_contactoError = "";
        /*let instagramError = "";
        let facebookError = "";
      
        if (web == "") {
         webError = "El campo no puede ser vacio";
        }
        if (instagram == "" || instagram == null) {
         instagramError = "El campo no puede ser vacio";
        } */

        if ([presona_contacto] == "") {
            presona_contactoError = "El campo no puede ser vacio";
        }


        if (
            presona_contactoError
        ) {
            setPersonaContactoError(presona_contactoError);
            //setInstagramError(instagramError);
            //setFacebookError(facebookError);
            return false;
        } else {
            setPersonaContactoError(presona_contactoError);
            //setInstagramError(instagramError);
            //setFacebookError(facebookError);
            return true;
        }
    }

    function handleSubmit() {
        setLoading(true)
        const data = {
            id: negocio.id,
            tags_ids: negocio.tags_ids,
            nombre: negocio.nombre,
            codigo_postal: negocio.codigo_postal,
            direccion: negocio.direccion,
            whatsap: negocio.whatsap,
            telefono: negocio.whatsap,
            imagen_url: "provicional",
            categoria_id: negocio.categoria_id,
            descripcion: negocio.descripcion,
            dominio_okki: negocio.dominio_okki,
            rango_id: negocio.rango_id,
            ciudad_id: negocio.ciudad_id,
            zona: negocio.zona,
            precio_envio: negocio.precio_envio,
            envios: [negocio.envios[0].id],
            tipos: [negocio.tipos[0].id],
            instagram: negocio.instagram,
            facebook: negocio.facebook,
            website: negocio.website,
            //EDITED FIELDS ON VIEW
            persona_contacto: presona_contacto
        }
        console.log(data);
        const isValid = validateFields()
        if (isValid) {
            putNegocios(data).then(res => {
                if (res) {
                    nuevoNegocio ?
                        window.location.replace("/settings4")
                        :
                        window.location.reload()
                }
            })
        }
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    function handleDelete() {
        deleteAccount().then(res => {
            if (res) {
                window.location.replace("/login")
            }
        })
    }
    return (
        <div>
            <div className="settings-form container">
                <div className="row">

                    <div className="four columns settings-form__sidebar">
                        <div className="">
                            <div className="settings-header__breadcrumb">
                                <h3 className="settings-header__title">Configuración</h3>
                                <hr className="settingsDivider" />
                            </div>
                        </div>
                        <ul className="settings-form__menu ">
                            <li>
                                <NavLink to="/settings" activeClassName="active">
                                    Perfil
        </NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings2" activeClassName="active">
                                    Dirección y envíos
        </NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings3" activeClassName="active">
                                    Redes Sociales
        </NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings4" activeClassName="active">
                                    Cuenta
        </NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings5" activeClassName="active">
                                    PRO
        </NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="eight columns settings-form__container">
                        <div className="settings-form__row">
                            <div className="settings-form__column">
                                <label>Persona contacto</label>
                            </div>
                            <div className="settings-form__column">
                                <input
                                    className="settings-form__input"
                                    type="text"
                                    placeholder="Persona contacto"
                                    value={presona_contacto}
                                    onChange={e => setPersonaContacto(e.target.value)}
                                />
                                <div className="fieldError">{presona_contactoError}</div>
                            </div>
                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column">
                                <label>Correo Electrónico</label>
                            </div>
                            <div className="settings-form__column">
                                <input
                                    className="settings-form__input"
                                    type="email"
                                    placeholder="correo@ejemplo.com"
                                    value={email}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column">
                                <label>Nueva contraseña</label>
                            </div>
                            <div className="settings-form__column">
                                <input
                                    className="settings-form__input"
                                    type="password"
                                    placeholder="Nueva contraseña"
                                />
                            </div>
                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column">
                                <label>Confirmar contraseña</label>
                            </div>
                            <div className="settings-form__column">
                                <input
                                    className="settings-form__input"
                                    type="password"
                                    placeholder="Confirmar contraseña"
                                />
                            </div>
                        </div>
                        <div className="settings-form__row">
                            <div className="settings-form__column">
                                <label>Eliminar cuenta 👋🏼</label>
                            </div>
                            <div className="settings-form__column">
                                <a className="button btnDelete" onClick={openModal}>Eliminar Cuenta</a>
                            </div>
                        </div>
                        <div className="settings-form__rowLast">
                            <a className="button" onClick={handleSubmit}>
                                {Loading && (
                                    <i className="" style={{ marginRight: "5px" }} />
                                )}
                                {Loading && (
                                    'Guardando...'
                                )}
                                {!Loading && (
                                    nuevoNegocio ?
                                        'Continuar'
                                        :
                                        'Guardar'
                                )}
                            </a>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <p
                        ref={(_subtitle) => (subtitle = _subtitle)}
                        className="titleMin modalTitle"
                    >
                        Deseas eliminar tu cuenta?
                    </p>
                    <br />

                    <div className="backofficeContainer__optionTotal modalRow">
                        <p className="button buttonAlt" onClick={closeModal}>
                            Cancelar
     </p>
                        <button className="button u-pull-right aButton" onClick={handleDelete}>
                            Eliminar
     </button>
                    </div>
                </Modal>
            </div>
        </div>
    );
}

export default Settings4;
