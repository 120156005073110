import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import venderThumb from "../images/vender-2.svg";
import Fade from "react-reveal/Fade";
import profileThumb from "../images/profileThumb.png";
import itemThumb from "../images/itemThumb.svg";
import itemProfile from "../images/itemProfile.svg";
import NavbarMin from "./NavbarMin";
import { loggedIn } from "./API Functions";

function Vender3() {
  useEffect(()=>{
  },[])
  return (
    <div>
      <NavbarMin />
      {/* <div className="navbarFixed">
    <Link to="/">
     <svg
      width="44"
      height="20"
      viewBox="0 0 44 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
     >
      <path
       d="M20.9942 13.632L24.6767 9.32C24.9856 8.928 24.7322 8.448 24.257 8.448H21.1368C20.8042 8.448 20.6062 8.616 20.4399 8.784L17.0662 12.728V0.536C17.0662 0.256 16.8128 0 16.5356 0H13.4392C13.162 0 12.9086 0.256 12.9086 0.536V19.184C12.9086 19.464 13.162 19.72 13.4392 19.72H16.5356C16.8128 19.72 17.0662 19.464 17.0662 19.184V14.592L20.8596 19.496C20.9467 19.608 21.1685 19.72 21.2793 19.72H25.041C25.5162 19.72 25.7696 19.24 25.4608 18.872L20.9942 13.632ZM5.79698 8.168C2.62131 8.168 0 10.872 0 14.056C0 17.296 2.62131 20 5.79698 20C8.97264 20 11.594 17.296 11.594 14.056C11.6019 10.872 8.98056 8.168 5.79698 8.168ZM5.79698 16.368C4.56947 16.368 3.59539 15.328 3.59539 14.056C3.59539 12.816 4.56947 11.8 5.79698 11.8C7.02448 11.8 7.99856 12.816 7.99856 14.056C8.00648 15.32 7.02448 16.368 5.79698 16.368ZM41.7747 5.04C43.0022 5.04 44.0079 4.056 44.0079 2.816C44.0079 1.576 43.0022 0.56 41.7747 0.56C40.5472 0.56 39.5731 1.576 39.5731 2.816C39.5652 4.056 40.5472 5.04 41.7747 5.04ZM43.3348 8.448H40.27C39.9928 8.448 39.7394 8.672 39.7394 8.984V19.184C39.7394 19.464 39.9928 19.72 40.27 19.72H43.3348C43.6119 19.72 43.8654 19.464 43.8654 19.184V8.984C43.8654 8.68 43.6119 8.448 43.3348 8.448ZM34.4096 13.632L38.0922 9.32C38.401 8.928 38.1476 8.448 37.6724 8.448H34.5522C34.2196 8.448 34.0216 8.616 33.8553 8.784L30.4816 12.728V0.536C30.4816 0.256 30.2282 0 29.951 0H26.8546C26.5774 0 26.324 0.256 26.324 0.536V19.184C26.324 19.464 26.5774 19.72 26.8546 19.72H29.951C30.2282 19.72 30.4816 19.464 30.4816 19.184V14.592L34.275 19.496C34.3621 19.608 34.5839 19.72 34.6947 19.72H38.4564C38.9316 19.72 39.185 19.24 38.8762 18.872L34.4096 13.632Z"
       fill="white"
      />
     </svg>
    </Link>
   </div> */}
      <div className="planContainer">
        <div className="planContainer-cover">
          <Fade delay={300}>
            <img src={venderThumb} />
          </Fade>
        </div>
        <div className="planContainer-form">
          <Fade delay={300}>
            <div className="container">
              <div className="row">
                <div className="twelve columns">
                  <div className="planContainer-form__container planContainer-form__thanks">
                    <h1 className="subtitle">
                      Tu solicitud ha sido enviada correctamente.
          </h1>
                    <br />
                    <p>En un lapso máximo de 48 horas recibirás noticias de nosotros</p>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default Vender3;
