import React from "react";
import { Link, NavLink } from "react-router-dom";
import profileThumb from "../images/profileThumb.png";
import Navbar from "./Navbar";

function Settings5() {
  return (
    <div className="settings-form">
      <div className="row">
        <div className="four columns settings-form__sidebar">
          <div>
            <div className="settings-header__breadcrumb">
              <h3 className="settings-header__title">Configuración</h3>
              <hr className="settingsDivider" />
            </div>
          </div>
          <ul className="settings-form__menu">
            <li>
              <NavLink to="/settings" activeClassName="active">
                Perfil
        </NavLink>
            </li>
            <li>
              <NavLink to="/settings2" activeClassName="active">
                Dirección y envíos
        </NavLink>
            </li>
            <li>
              <NavLink to="/settings3" activeClassName="active">
                Redes Sociales
        </NavLink>
            </li>
            <li>
              <NavLink to="/settings4" activeClassName="active">
                Cuenta
        </NavLink>
            </li>
            <li>
              <NavLink to="/settings5" activeClassName="active">
                PRO
        </NavLink>
            </li>
          </ul>
        </div>
        <div className="eight columns settings-form__container">
          <div className="settings-form__row">
            <div className="settings-form__column">
              <label>Persona contacto</label>
            </div>
            <div className="settings-form__column">
              <input
                className="settings-form__input"
                type="text"
                placeholder="Persona contacto"
              />
            </div>
          </div>
          <div className="settings-form__row">
            <div className="settings-form__column">
              <label>Correo Electrónico</label>
            </div>
            <div className="settings-form__column">
              <input
                className="settings-form__input"
                type="email"
                placeholder="correo@ejemplo.com"
              />
            </div>
          </div>
          <div className="settings-form__row">
            <div className="settings-form__column">
              <label>Nueva contraseña</label>
            </div>
            <div className="settings-form__column">
              <input
                className="settings-form__input"
                type="password"
                placeholder="Nueva contraseña"
              />
            </div>
          </div>
          <div className="settings-form__row">
            <div className="settings-form__column">
              <label>Confirmar contraseña</label>
            </div>
            <div className="settings-form__column">
              <input
                className="settings-form__input"
                type="password"
                placeholder="Confirmar contraseña"
              />
            </div>
          </div>
          <div className="settings-form__row">
            <div className="settings-form__column">
              <label>Eliminar cuenta 👋🏼</label>
            </div>
            <div className="settings-form__column">
              <a className="button btnDelete">Eliminar Cuenta</a>
            </div>
          </div>
          <div className="settings-form__rowLast">
            <a className="button">Guardar</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings5;
