import React, { useState } from "react";
import "../App.scss";

const ScrollArrow = () => {
 const [showScroll, setShowScroll] = useState(false);

 const checkScrollTop = () => {
  if (!showScroll && window.pageYOffset > 400) {
   setShowScroll(true);
  } else if (showScroll && window.pageYOffset <= 400) {
   setShowScroll(false);
  }
 };

 const scrollTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
 };

 window.addEventListener("scroll", checkScrollTop);

 return (
  <div
   onClick={scrollTop}
   style={{ display: showScroll ? "flex" : "none" }}
   className="scrollTop"
  >
   <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
   >
    <rect
     x="0.5"
     y="0.5"
     width="41"
     height="41"
     rx="5.5"
     fill="white"
     stroke="#EEEEEE"
    />
    <path
     d="M26 20L21 15L16 20"
     stroke="#999999"
     stroke-width="1.5"
     stroke-miterlimit="10"
     stroke-linecap="round"
     stroke-linejoin="round"
    />
    <path
     d="M21 15L21 27"
     stroke="#999999"
     stroke-width="1.5"
     stroke-miterlimit="10"
     stroke-linecap="round"
     stroke-linejoin="round"
    />
   </svg>
  </div>
 );
};

export default ScrollArrow;
