import React from "react";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";

function Page404() {
 return (
  <div>
   <div className="home-gallery container">
    <p className="page404-message">
     Usted ha alcanzado el límite visible del Universo. Y se encuentra a 46.500
     millones de años luz de la Tierra.
    </p>
    <br />
    <Link to="/" className="page404-link">
     ← Regresar a la tierra
    </Link>
   </div>
  </div>
 );
}

export default Page404;
