import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import searchIcon from "../images/search_icon.svg";
import navProfile from "../images/navbar-profile.png";

import Select, { components } from "react-select";

const options = [
 { value: "monterrey", label: "Monterrey" },
 { value: "guadalajara", label: "Guadalajara" },
 { value: "cdmx", label: "CDMX" },
];

function openNav() {
 const navigationWindow = document.getElementById("myNav");
 navigationWindow.style.width = "400px";
}

function closeNav() {
 const navigationWindow = document.getElementById("myNav");
 navigationWindow.style.width = "0%";
}

const DropdownIndicator = (
 props: ElementConfig<typeof components.DropdownIndicator>
) => {
 return (
  <components.DropdownIndicator {...props}>
   <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
   >
    <path
     d="M6 8L10 12L14 8"
     stroke="#081027"
     stroke-width="1.5"
     stroke-miterlimit="10"
     stroke-linecap="round"
     stroke-linejoin="round"
    />
   </svg>
  </components.DropdownIndicator>
 );
};

export default class NavbarProfile extends React.Component {
 constructor(props) {
  super(props);

  this.setWrapperRef = this.setWrapperRef.bind(this);
  this.handleClickOutside = this.handleClickOutside.bind(this);

  this.state = {
   prevScrollpos: typeof window !== "undefined" && window.pageYOffset,
   visible: true,
   hoveredBuy: false,
   hoveredEat: false,
   hoveredHire: false,
  };
 }
 state = {
  activeMenu: true,
  isFocused: false,
 };

 onMouseEnterBuy = (e) => {
  this.setState({ hoveredBuy: true });
 };
 onMouseEnterEat = (e) => {
  this.setState({ hoveredEat: true });
 };
 onMouseEnterHire = (e) => {
  this.setState({ hoveredHire: true });
 };

 onMouseLeaveBuy = (e) => {
  this.setState({ hoveredBuy: false });
 };
 onMouseLeaveEat = (e) => {
  this.setState({ hoveredEat: false });
 };
 onMouseLeaveHire = (e) => {
  this.setState({ hoveredHire: false });
 };

 onFocusChange = () => {
  this.setState({ isFocused: !this.state.isFocused });
 };

 toggleMenu = () => {
  this.setState({
   activeMenu: !this.state.activeMenu,
  });
 };
 escFunction(event) {
  if (event.keyCode === 27) {
   const navigationWindow = document.getElementById("myNav");
   navigationWindow.style.width = "0%";
  }
 }
 componentDidMount() {
  if (typeof window === "undefined") {
   global.window = {};
  }
  window.addEventListener("scroll", this.handleScroll);

  document.addEventListener("keydown", this.escFunction, false);

  document.addEventListener("mousedown", this.handleClickOutside);
 }

 componentWillUnmount() {
  window.removeEventListener("scroll", this.handleScroll);
  document.removeEventListener("keydown", this.escFunction, false);
  document.removeEventListener("mousedown", this.handleClickOutside);
 }

 setWrapperRef(node) {
  this.wrapperRef = node;
 }

 handleScroll = () => {
  const { prevScrollpos } = this.state;

  const currentScrollPos = window.pageYOffset;
  const visible = prevScrollpos > currentScrollPos || "100" > currentScrollPos;

  this.setState({
   prevScrollpos: currentScrollPos,
   visible,
  });
 };

 handleClickOutside(event) {
  if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
   const navigationWindow = document.getElementById("myNav");
   navigationWindow.style.width = "0%";
  }
 }

 render() {
  const { hoveredEat } = this.state;
  const { hoveredBuy } = this.state;
  const { hoveredHire } = this.state;
  const styleBuy = hoveredBuy ? { backgroundColor: "#95983A" } : {};
  const styleEat = hoveredEat ? { backgroundColor: "#ED3325" } : {};
  const styleHire = hoveredHire ? { backgroundColor: "#FF9C38" } : {};
  return (
   <div
    id="navbarMain"
    className={classnames("navbarHideShow", {
     "navbarHideShow-hidden": !this.state.visible,
    })}
   >
    <header className="navbar-home">
     <div
      className={
       this.state.isFocused
        ? "navbar-home__section navbar-home__sectionReduced"
        : "navbar-home__section"
      }
     >
      <Link to="/">
       <svg
        width="44"
        height="20"
        viewBox="0 0 44 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
       >
        <g clip-path="url(#clip0)">
         <path
          d="M20.9942 13.632L24.6767 9.32C24.9856 8.928 24.7322 8.448 24.257 8.448H21.1368C20.8042 8.448 20.6062 8.616 20.4399 8.784L17.0662 12.728V0.536C17.0662 0.256 16.8128 0 16.5356 0H13.4392C13.162 0 12.9086 0.256 12.9086 0.536V19.184C12.9086 19.464 13.162 19.72 13.4392 19.72H16.5356C16.8128 19.72 17.0662 19.464 17.0662 19.184V14.592L20.8596 19.496C20.9467 19.608 21.1685 19.72 21.2793 19.72H25.041C25.5162 19.72 25.7696 19.24 25.4608 18.872L20.9942 13.632ZM5.79698 8.168C2.62131 8.168 0 10.872 0 14.056C0 17.296 2.62131 20 5.79698 20C8.97264 20 11.594 17.296 11.594 14.056C11.6019 10.872 8.98056 8.168 5.79698 8.168ZM5.79698 16.368C4.56947 16.368 3.59539 15.328 3.59539 14.056C3.59539 12.816 4.56947 11.8 5.79698 11.8C7.02448 11.8 7.99856 12.816 7.99856 14.056C8.00648 15.32 7.02448 16.368 5.79698 16.368ZM41.7747 5.04C43.0022 5.04 44.0079 4.056 44.0079 2.816C44.0079 1.576 43.0022 0.56 41.7747 0.56C40.5472 0.56 39.5731 1.576 39.5731 2.816C39.5652 4.056 40.5472 5.04 41.7747 5.04ZM43.3348 8.448H40.27C39.9928 8.448 39.7394 8.672 39.7394 8.984V19.184C39.7394 19.464 39.9928 19.72 40.27 19.72H43.3348C43.6119 19.72 43.8654 19.464 43.8654 19.184V8.984C43.8654 8.68 43.6119 8.448 43.3348 8.448ZM34.4096 13.632L38.0922 9.32C38.401 8.928 38.1476 8.448 37.6724 8.448H34.5522C34.2196 8.448 34.0216 8.616 33.8553 8.784L30.4816 12.728V0.536C30.4816 0.256 30.2282 0 29.951 0H26.8546C26.5774 0 26.324 0.256 26.324 0.536V19.184C26.324 19.464 26.5774 19.72 26.8546 19.72H29.951C30.2282 19.72 30.4816 19.464 30.4816 19.184V14.592L34.275 19.496C34.3621 19.608 34.5839 19.72 34.6947 19.72H38.4564C38.9316 19.72 39.185 19.24 38.8762 18.872L34.4096 13.632Z"
          fill="#081027"
         />
        </g>
        <defs>
         <clipPath id="clip0">
          <rect width="44" height="20" fill="white" />
         </clipPath>
        </defs>
       </svg>
      </Link>
     </div>
     <div
      className="navbar-home__section navbar-home__section2"
      className={
       this.state.isFocused
        ? "navbar-home__section navbar-home__sectionExp navbar-home__section2"
        : "navbar-home__section navbar-home__section2"
      }
     >
      <input
       type="text"
       placeholder="Buscar..."
       style={{ backgroundImage: `url(${searchIcon})` }}
       onFocus={this.onFocusChange}
       onBlur={this.onFocusChange}
       className="navbar-home__search"
      />
      <div className="navbar-home__searchTags">
       <a className="itemTag">Comprar</a>
       <a className="itemTag">Comer</a>
       <a className="itemTag">Contratar</a>
      </div>
     </div>
     <div
      className={
       this.state.isFocused
        ? "navbar-home__section navbar-home__sectionReduced navbar-home__actions"
        : "navbar-home__section navbar-home__actions"
      }
     >
      <Select
       classNamePrefix="inputSelect"
       className="inputSelect"
       options={options}
       placeholder="Ciudad"
       components={{ DropdownIndicator }}
       theme={(theme) => ({
        ...theme,
        borderRadius: 6,
        colors: {
         ...theme.colors,
         primary50: "#ccc",
         primary25: "#F6F6F6",
         primary: "black",
        },
       })}
      />
      <img className="navbar-profile" src={navProfile} />
     </div>
     <div className="navbar-iconWrapper">
      <a href="#" className="navbar-iconMenu" onClick={openNav}>
       <div></div>
       <div></div>
      </a>
     </div>
    </header>
    <div id="myNav" className="overlay" ref={this.setWrapperRef}>
     <a className="closebtn" onClick={closeNav}>
      <svg
       width="16"
       height="16"
       viewBox="0 0 16 16"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
      >
       <path d="M1 1L15 15" stroke="#9AA1B9" stroke-width="2" />
       <path d="M1 15L15 0.999999" stroke="#9AA1B9" stroke-width="2" />
      </svg>
     </a>

     <div
      id="overlayContent"
      className="overlay-content"
      style={{ ...styleBuy, ...styleHire, ...styleEat }}
     >
      <div>
       <p className="overlay-content-title">Explorar</p>
       <ul>
        <li>
         <a
          onMouseEnter={this.onMouseEnterBuy}
          onMouseLeave={this.onMouseLeaveBuy}
          id="buyBtn"
          className="overlay-content-buy"
         >
          Comprar
         </a>
        </li>
        <li>
         <a
          onMouseEnter={this.onMouseEnterEat}
          onMouseLeave={this.onMouseLeaveEat}
          className="overlay-content-eat"
         >
          Comer
         </a>
        </li>
        <li>
         <a
          onMouseEnter={this.onMouseEnterHire}
          onMouseLeave={this.onMouseLeaveHire}
          className="overlay-content-hire"
         >
          Contratar
         </a>
        </li>
       </ul>
      </div>
      <div>
       <p className="overlay-content-title">Menu</p>
       <ul className="overlay-content__menuMin">
        <li>
         <a>Vender</a>
        </li>
        <li>
         <Link to="/login" className="enter">
          Entrar
         </Link>
        </li>
       </ul>
      </div>
     </div>
    </div>
   </div>
  );
 }
}
