import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import venderThumb from "../../images/vender-1.svg";
import selectArrow from "../../images/selectArrow.svg";
import profileTable from "../../images/profileTable.svg";
import profileThumb from "../../images/profileThumb.png";
import itemThumb from "../../images/itemThumb.svg";
import itemProfile from "../../images/itemProfile.svg";
import MaterialTable from "material-table";

import Modal from "react-modal";
import Sidebar from "../Sidebar";
import { getRangoPrecios, postRangoPrecio, deleteRangoPrecio, putRangoPrecio, loggedIn } from "../API Functions";
const customStyles = {
 content: {
  top: "50%",
  left: "50%",
  right: "auto",
  bottom: "auto",
  marginRight: "-50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  padding: "50px 40px",
  border: "none",
  borderRadius: 6,
 },
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

function RangoPrecios() {
 var subtitle;

 const [modalIsOpen, setIsOpen] = React.useState(false);
 const [modalIsOpenEdit, setIsOpenEdit] = React.useState(false);
 const [modalIsOpenDelete, setIsOpenDelete] = React.useState(false);

 const[rango_precios,setRangoPrecios] = useState([]);

 const[detalle,setDetalle] = useState("");
 const[detalle_edit,setDetalleEdit] = useState("");

 const[rango_delete,setRangoDelete] = useState("");
 const[rango_edit,setRangoEdit] = useState("");

 const [detalleError, setDetalleError] = useState("");

 const[loading,setLoading] = useState(false);

    useEffect(()=>{
        loggedIn();
        setLoading(true);
        loadRangos();
    },[])


 function openModal() {
  setIsOpen(true);
 }

 function closeModal() {
    setIsOpen(false);
 }

 function afterOpenModal() {
  // references are now sync'd and can be accessed.
 }

 function validateFields(){
    let detalleError = ""

    if(detalle == ""){
        detalleError = "El campo no puede ser vacio"
    }
    if(detalleError){
        setDetalleError(detalleError);
        return false
    }else{
        setDetalleError(detalleError);
        return true
    }
 }

 function handleCreate(){
     const data = {
         detalle : detalle
     }
     const isValid = validateFields()
     if(isValid){
        postRangoPrecio(data).then(res=>{
            setLoading(true);
            closeModal();
            loadRangos();
        })
     }
 }

 function openModalDelete(id) {
    setRangoDelete(id);
    setIsOpenDelete(true);
   }
  
   function closeModalDelete() {
      setRangoDelete("");
      setIsOpenDelete(false);
   }
 
   function handleDelete(){
       deleteRangoPrecio(rango_delete).then(res=>{
           setLoading(true);
           closeModalDelete();
           loadRangos();
       })
   }


 function openModalEdit(rango) {
    setRangoEdit(rango.id);
    setDetalleEdit(rango.detalle);
    setIsOpenEdit(true);
   }
  
   function closeModalEdit() {
      setRangoEdit("");
      setDetalleEdit("");
      setIsOpenEdit(false);
   }
 
   function handleEdit(){
       const data = {
           id : rango_edit,
           detalle : detalle_edit
       }
       putRangoPrecio(data).then(res=>{
           setLoading(true);
           loadRangos();
           closeModalEdit();
       })
   }

   function loadRangos(){
    setLoading(true);
    getRangoPrecios().then(res=>{
        if(!res){
           window.location.replace("/login")
        }else{
            setRangoPrecios(res);
        }
        setTimeout(() => {
            setLoading(false);
        }, 700);
    })
}

 return (
  <div className="backoffice">
   <Sidebar />
   <div className="backofficeContainer">
    <div className="backofficeContainer-header">
     <div className="backofficeContainer-header__about">
      <h3 className="backofficeContainer-header__title">Rangos de Precios</h3>
      <h3 className="backofficeContainer-header__titleAlt">{rango_precios? rango_precios.length : 0}</h3>
     </div>
     <input className="inputSearch" type="text" placeholder="Buscar..." />
    </div>
    <div className="backofficeContainer-headerMin">
     <div>
     </div>
     <div>
      <a className="button" onClick={openModal}>
       Agregar
      </a>
     </div>
    </div>
    <div className="backofficeTable">
     <hr />
     {/*<table>
      <thead>
       <tr>
        <th>Categoría</th>
        <th style={{ textAlign: "center" }}>Negocios</th>
        <th style={{ textAlign: "center" }}>Eliminar</th>
       </tr>
      </thead>
      <tbody>
       <tr>
        <td>Hamburguesa</td>
        <td style={{ textAlign: "center" }}>31</td>
        <td>
         <div className="approvedBtns">
          <a>
           <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             d="M14.2422 5.75735L5.75691 14.2426"
             stroke="#ACACB5"
             stroke-width="1.5"
             stroke-miterlimit="10"
             stroke-linecap="round"
             stroke-linejoin="round"
            />
            <path
             d="M14.2431 14.2426L5.75781 5.75735"
             stroke="#ACACB5"
             stroke-width="1.5"
             stroke-miterlimit="10"
             stroke-linecap="round"
             stroke-linejoin="round"
            />
           </svg>
          </a>
         </div>
        </td>
       </tr>
      </tbody>
     </table>*/}
     <MaterialTable
                        columns={[
                            { title: "Rango", field: "rango" },
                            { title: "Negocios", field: "negocios" },
                            { title: "Eliminar", field: "actions" }
                        ]}
                        title={"Solicitudes"}
                        data={rango_precios.map((rango, index) => ({
                            rango: rango.detalle,
                            negocios: rango.negocios,
                            actions: (
                                <div className="approvedBtns">
                                <a onClick={()=>{openModalDelete(rango.id)}}>
                                 <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                 >
                                  <path
                                   d="M14.2422 5.75735L5.75691 14.2426"
                                   stroke="#ACACB5"
                                   stroke-width="1.5"
                                   stroke-miterlimit="10"
                                   stroke-linecap="round"
                                   stroke-linejoin="round"
                                  />
                                  <path
                                   d="M14.2431 14.2426L5.75781 5.75735"
                                   stroke="#ACACB5"
                                   stroke-width="1.5"
                                   stroke-miterlimit="10"
                                   stroke-linecap="round"
                                   stroke-linejoin="round"
                                  />
                                 </svg>
                                </a>
                                <a onClick={()=>{openModalEdit(rango)}}>
                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                </a>
                               </div> 
                            )
                        }))}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [15, 20, 30, 50],
                            emptyRowsWhenPaging : false,
                            paging : rango_precios.length > 20 ? true : false,
                            showTitle : false,
                            search: false
                        }}
                        localization={{
                            body: {
                                emptyDataSourceMessage: 
                                
                                loading ? 
                                <>
                                    <i
                                        className="fa fa-refresh fa-spin"
                                        style={{
                                            marginRight:
                                                "5px"
                                        }}
                                    />
                                
                                    <span>Cargando...</span>
                                 </>
                                :
                                'No hay datos para mostrar.'
                                
                            }
                        }}
                    />
    </div>
   </div>
   <Modal
    isOpen={modalIsOpen}
    onAfterOpen={afterOpenModal}
    onRequestClose={closeModal}
    style={customStyles}
    contentLabel="Example Modal"
   >
    <p
     ref={(_subtitle) => (subtitle = _subtitle)}
     className="titleMin modalTitle"
    >
     Agregar Rango
    </p>
    <br />
    <div className="modalInput">
    <label>Detalle</label>
        <input
         type="text"
         class="input registerInput"
         placeholder="Mexicana"
         onChange={ e => setDetalle(e.target.value) }
        />
    </div>
    <div className="backofficeContainer__optionTotal modalRow">
     <p className="button buttonAlt" onClick={closeModal}>
      Cancelar
     </p>
     <button className="button u-pull-right aButton" onClick={handleCreate}>
      Agregar
     </button>
    </div>
   </Modal>

   <Modal
    isOpen={modalIsOpenDelete}
    onAfterOpen={afterOpenModal}
    onRequestClose={closeModalDelete}
    style={customStyles}
    contentLabel="Example Modal"
   >
    <p
     ref={(_subtitle) => (subtitle = _subtitle)}
     className="titleMin modalTitle"
    >
     Deseas eliminar este Rango?
    </p>
    <br />
    
    <div className="backofficeContainer__optionTotal modalRow">
     <p className="button buttonAlt" onClick={closeModalDelete}>
      Cancelar
     </p>
     <button className="button u-pull-right aButton" onClick={handleDelete}>
      Eliminar
     </button>
    </div>
   </Modal>

   <Modal
    isOpen={modalIsOpenEdit}
    onAfterOpen={afterOpenModal}
    onRequestClose={closeModalEdit}
    style={customStyles}
    contentLabel="Example Modal"
   >
    <p
     ref={(_subtitle) => (subtitle = _subtitle)}
     className="titleMin modalTitle"
    >
     Editar Rango
    </p>
    <br />
    <div className="modalInput">
    <label>Detalle</label>
        <input
         type="text"
         class="input registerInput"
         placeholder="Mexicana"
         value={detalle_edit}
         onChange={ e => setDetalleEdit(e.target.value) }
        />
    </div>
    <div className="backofficeContainer__optionTotal modalRow">
     <p className="button buttonAlt" onClick={closeModalEdit}>
      Cancelar
     </p>
     <button className="button u-pull-right aButton" onClick={handleEdit}>
      Editar
     </button>
    </div>
   </Modal>
  </div>
 );
}

export default RangoPrecios;
