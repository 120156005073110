import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import profileThumb from "../images/profileThumb.png";
import Navbar from "./Navbar";

import Select from "react-select";
import { getCiudades, getNegocios, putNegocios } from "./API Functions";

const options = [
    { value: null, label: "-" },
    { value: true, label: "Si" },
    { value: false, label: "No" },
];

function Settings2() {
    const [Loading, setLoading] = useState(false);

    const [ciudades, setCiudades] = useState([]);
    const [negocio, setNegocio] = useState([]);
    const [nuevoNegocio, setNewNegocio] = useState(false);

    const [direccion, setDireccion] = useState("");
    const [zona, setZona] = useState("");
    const [precio_envio, setPrecioEnvio] = useState("");
    const [ciudad, setCiudad] = useState([]);
    const [envio, setEnvio] = useState([]);
    const [codigo_postal, setCodigoPostal] = useState("");

    const [direccionError, setDireccionError] = useState("");
    const [zonaError, setZonaError] = useState("");
    const [precio_envioError, setPrecioEnvioError] = useState("");
    const [ciudadError, setCiudadError] = useState([]);
    const [envioError, setEnvioError] = useState([]);
    const [codigo_postalError, setCodigoPostalError] = useState([]);


    useEffect(() => {
        getNegocios(1).then(res => {
            const negocio = res;
            res.ciudad_id == 0 ? setNewNegocio(true) : setNewNegocio(false);
            negocio.envios ? negocio.envios[0].id == 1 ? setEnvio(options[1]) : setEnvio(options[2]) : setEnvio(options[0]);
            setNegocio(negocio);
            setDireccion(negocio.direccion);
            setZona(negocio.zona);
            setPrecioEnvio(negocio.precio_envio);
            setCodigoPostal(negocio.codigo_postal);
            getCiudades().then(res => {
                if (res) {
                    const ciudades = []
                    res.map((ciudad, index) => {
                        ciudad = {
                            ...ciudad,
                            value: ciudad.id,
                            label: ciudad.nombre
                        }
                        ciudades.push(ciudad)
                        if (ciudad.id == negocio.ciudad_id) {
                            setCiudad(ciudad);
                        }
                    })
                    setCiudades(ciudades);

                } else {
                    window.location.replace("/login")
                }
            })
        })
    }, [])

    function validateFields() {
        let direccionError = "";
        let zonaError = "";
        let precio_envioError = "";
        let ciudadError = "";
        let envioError = "";
        let codigo_postalError = "";

        if (direccion == "") {
            direccionError = "El campo no puede ser vacio";
        }
        if (zona == "") {
            zonaError = "El campo no puede ser vacio";
        }
        if (precio_envio == "") {
            precio_envioError = "El campo no puede ser vacio";
        }
        if (ciudad == "") {
            ciudadError = "Selecciona una opcion";
        }
        if (envio.value == null) {
            envioError = "Selecciona una opcion";
        }
        if (codigo_postal == "") {
            codigo_postalError = "El campo no puede ser vacio";
        }

        if (
            direccionError ||
            zonaError ||
            precio_envioError ||
            ciudadError ||
            envioError
        ) {
            setDireccionError(direccionError);
            setZonaError(zonaError);
            setPrecioEnvioError(precio_envioError);
            setCiudadError(ciudadError);
            setEnvioError(envioError);
            setCodigoPostalError(codigo_postalError);
            return false;
        } else {
            setDireccionError(direccionError);
            setZonaError(zonaError);
            setPrecioEnvioError(precio_envioError);
            setCiudadError(ciudadError);
            setEnvioError(envioError);
            setCodigoPostalError(codigo_postalError);
            return true;
        }
    }

    function handleEnvioOption(selectedOption) {
        setEnvio(selectedOption);
    }

    function handleCiudadOption(selectedOption) {
        setCiudad(selectedOption);
    }

    function handleSubmit() {
        setLoading(true)
        const data = {
            id: negocio.id,
            tags_ids: negocio.tags_ids,
            nombre: negocio.nombre,
            website: negocio.website,
            whatsap: negocio.whatsap,
            facebook: negocio.facebook,
            telefono: negocio.whatsap,
            imagen_url: negocio.imagen_url,
            categoria_id: negocio.categoria_id,
            descripcion: negocio.descripcion,
            dominio_okki: negocio.dominio_okki,
            rango_id: negocio.rango_id,
            instagram: negocio.instagram,
            precio_envio: precio_envio,
            persona_contacto: negocio.persona_contacto,
            tipos: [negocio.tipos[0].id],
            //EDITED FIELDS IN VIEW
            envios: envio.value ? [1] : [2],
            ciudad_id: ciudad.id,
            zona: zona,
            codigo_postal: codigo_postal,
            direccion: direccion
        }
        console.log(data);
        const isValid = validateFields()
        if (isValid) {
            putNegocios(data).then(res => {
                if (res) {
                    nuevoNegocio ?
                        window.location.replace("/settings3")
                        :
                        window.location.reload()
                }
            })
        }
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }


    return (
        <div className="settings-form">
            <div className="row">
                <div
                    className={nuevoNegocio ? "disabledDiv" : ""}
                >
                    <div className="four columns settings-form__sidebar">
                        <div className="">
                            <div className="settings-header__breadcrumb">
                                <h3 className="settings-header__title">Configuración</h3>
                                <hr className="settingsDivider" />
                            </div>
                        </div>
                        <ul className="settings-form__menu">
                            <li>
                                <NavLink to="/settings" activeClassName="active">
                                    Perfil
        </NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings2" activeClassName="active">
                                    Dirección y envíos
        </NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings3" activeClassName="active">
                                    Redes Sociales
        </NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings4" activeClassName="active">
                                    Cuenta
        </NavLink>
                            </li>
                            <li>
                                <NavLink to="/settings5" activeClassName="active">
                                    PRO
        </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="eight columns settings-form__container">
                    <div className="settings-form__row">
                        <div className="settings-form__column">
                            <label>
                                Ubicación{" "}
                                <span style={{ color: "#999" }}>
                                    — Tincidunt bibendum mauris non laoreet aliquam pharetra, urna quis
                                    consequat.
         </span>
                            </label>
                        </div>
                        <div className="settings-form__column">
                            <p className="mapsInput-placeholder">goo.gl/maps/</p>
                            <input
                                className="settings-form__input mapsInput"
                                type="text"
                                placeholder="okkiurl"
                                value={direccion}
                                onChange={e => setDireccion(e.target.value)}
                            />
                            <div className="fieldError">{direccionError}</div>
                        </div>
                    </div>
                    <div className="settings-form__row">
                        <div className="settings-form__column">
                            <label>Ciudad</label>
                        </div>
                        <div className="settings-form__column">

                            <Select
                                classNamePrefix="inputSelect"
                                className="inputSelect settings-form__input"
                                options={ciudades}
                                value={ciudad}
                                onChange={handleCiudadOption}
                                placeholder="Ciudad"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 6,
                                    colors: {
                                        ...theme.colors,
                                        primary50: "#ccc",
                                        primary25: "#F6F6F6",
                                        primary: "black",
                                    },
                                })}
                            />
                            <div className="fieldError">{ciudadError}</div>
                        </div>
                    </div>
                    <div className="settings-form__row">
                        <div className="settings-form__column">
                            <label>Zona</label>
                        </div>
                        <div className="settings-form__column">
                            <input
                                className="settings-form__input"
                                type="text"
                                placeholder="Zona"
                                value={zona}
                                onChange={e => setZona(e.target.value)}
                            />
                            <div className="fieldError">{zonaError}</div>
                        </div>
                    </div>
                    <div className="settings-form__row">
                        <div className="settings-form__column">
                            <label>Codigo Postal</label>
                        </div>
                        <div className="settings-form__column">
                            <input
                                className="settings-form__input"
                                type="number"
                                placeholder="00000"
                                value={codigo_postal}
                                onChange={e => setCodigoPostal(e.target.value)}
                            />
                            <div className="fieldError">{codigo_postalError}</div>
                        </div>
                    </div>
                    <div className="settings-form__row">
                        <div className="settings-form__column">
                            <label>Entregas a domicilio</label>
                        </div>
                        <div className="settings-form__column">
                            <Select
                                classNamePrefix="inputSelect"
                                className="inputSelect settings-form__input"
                                options={options}
                                value={envio}
                                onChange={handleEnvioOption}
                                placeholder="Si"
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 6,
                                    colors: {
                                        ...theme.colors,
                                        primary50: "#ccc",
                                        primary25: "#F6F6F6",
                                        primary: "black",
                                    },
                                })}
                            />
                            <div className="fieldError">{envioError}</div>
                        </div>
                    </div>
                    <div className="settings-form__row">
                        <div className="settings-form__column">
                            <label>Costo de Envío</label>
                        </div>
                        <div className="settings-form__column">
                            <input
                                className="settings-form__input"
                                type="text"
                                placeholder="$100"
                                value={precio_envio}
                                onChange={e => setPrecioEnvio(e.target.value)}
                            />
                            <div className="fieldError">{precio_envioError}</div>
                        </div>
                    </div>

                    {/* <div className="settings-form__rowLast">
                        <a className="button" onClick={handleSubmit}>
                            {Loading && (
                                <i className="" style={{ marginRight: "5px" }} />
                            )}
                            {Loading && (
                                'Guardando...'
                            )}
                            {!Loading && (
                                nuevoNegocio ?
                                    'Continuar'
                                    :
                                    'Guardar'
                            )}
                        </a>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Settings2;
